import React from 'react'
import WiseOwl from './WiseOwl.tsx'

function App() {
  return (
    <WiseOwl />
  );
}

export default App;
