import { useState } from "react";

export const useIdSync = () => {
    const [remoteStack, setRemoteStack] = useState<string[]>([]);
    const [localStack, setLocalStack] = useState<string[]>([]);
    const [syncDict, setSyncDict] = useState<{ [key: string]: string }>({});

    const pushToRemoteStack = (id: string) => {
        setRemoteStack((remote) => [...remote, id]);
    };

    const pushToLocalStack = (id: string) => {
        setLocalStack((local) => [...local, id]);
    };

    const findWaitingValueAndSubstitute = (
        waitingValue: string,
        newId: string
    ) => {
        function findKeyByValue(
            dict: {
                [key: string]: string;
            },
            value: string
        ) {
            return Object.keys(dict).find((key) => dict[key] === value);
        }
        const keyInDict = findKeyByValue(syncDict, waitingValue);
        if (keyInDict) {
            setSyncDict((prevSyncDict) => {
                return { ...prevSyncDict, [keyInDict]: newId };
            });
        } else {
            setRemoteStack((remote) => {
                return remote.map((id) => (id === waitingValue ? newId : id));
            });
        }
    };

    const getRemoteIDFromLocalID = (localId: string) => {
        if (syncDict[localId]) {
            return syncDict[localId];
        }
        const dictExtension: { [key: string]: string } = {};
        const copyLocalStack = [...localStack];
        const copyRemoteStack = [...remoteStack];
        while (copyLocalStack.length > 0 || copyRemoteStack.length > 0) {
            const localID = copyLocalStack.pop();
            const remoteID = copyRemoteStack.pop();
            if (!localID || !remoteID) {
                if (localID) {
                    copyLocalStack.push(localID);
                }
                if (remoteID) {
                    copyRemoteStack.push(remoteID);
                }
                break;
            }
            if (remoteID.indexOf("remote") !== -1) {
                copyLocalStack.push(localID);
                copyRemoteStack.push(remoteID);
                break;
            } else {
                dictExtension[localID] = remoteID;
            }
        }
        setLocalStack(copyLocalStack);
        setRemoteStack(copyRemoteStack);
        setSyncDict((prevSyncDict) => {
            return { ...prevSyncDict, ...dictExtension };
        });
        return dictExtension[localId];
    };

    return {
        pushToRemoteStack,
        findWaitingValueAndSubstitute,
        pushToLocalStack,
        getRemoteIDFromLocalID,
    };
};
