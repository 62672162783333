import styled from "styled-components";
import {
    TOP_LAYER_COLOR,
    FIRST_LAYER_COLOR,
    TEXT_COLOR,
    LIGHT_TEXT_COLOR,
} from "../utils";
import Flex from "./Flex";
import { DragEvent, useContext, useEffect, useRef, useState } from "react";
import Spacer from "./Spacer";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { FormattedMessage } from "react-intl";
import Clickable from "./Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import ArrowRight from "../images/arrowRight.svg";
import CustomInput from "./CustomInput";
import { InputState } from "../models/constants";
import Select from "react-select";
import { BoldText } from "./BoldText";
import { CustomCheckbox } from "./CustomCheckbox";
import {
    setIsMenuOpen,
    setSkipWeekends,
    shouldMenuBeOpened,
    shouldSkipWeekends,
} from "../models/persistanceAPI";
import { useString } from "../hooks/useString";
//import { isChrome } from "react-device-detect";

const FloatingMenuContainer = styled.div<{ bottom?: number; left?: number }>`
    position: sticky;
    bottom: ${(props) => (props.bottom ? `${props.bottom}px` : "48px")};
    left: ${(props) => (props.left ? `${props.left}px` : "20vw")};
    background: ${TOP_LAYER_COLOR};
    border-radius: 12px;
    max-width: 900px;
`;

const SmallFloatingMenuContainer = styled.div<{
    bottom?: number;
    left?: number;
}>`
    position: sticky;
    bottom: ${(props) => (props.bottom ? `${props.bottom}px` : "48px")};
    left: ${(props) => (props.left ? `${props.left}px` : "48px")};

    background: ${TOP_LAYER_COLOR};
    border-radius: 12px;
    width: 100px;
`;

const DragMenuButton = styled.div`
    height: 8px;
    width: 40px;
    border-radius: 4px;
    background: ${LIGHT_TEXT_COLOR};
    cursor: grab;
`;

const MenuButtonContainer = styled.div`
    background: ${FIRST_LAYER_COLOR};
    padding: 8px 16px;
    border-radius: 8px;
`;

const BigSpacer = () => {
    return <Spacer right={5} />;
};

export const FloatingMenu = ({
    currentDate,
    sortTasks,
    bulkAction,
    searchText,
}: {
    currentDate: Date;
    sortTasks: () => void;
    bulkAction: (targetedState: InputState, finalState: InputState, searchText: string | null) => void;
    searchText: string | null;
}) => {
    const selectDone = useString("done_select");
    const selectDiscarded = useString("discarded_select");
    const selectMovedToTomorrow = useString("moved_select");
    const selectEmptyState = useString("empty_select");

    const ref = useRef<HTMLInputElement | null>(null);
    const { height, isMedium } = useContext(ScreenSizeContext);
    const [bottom, setBottom] = useState<number | undefined>(undefined);
    const [left, setLeft] = useState<number | undefined>(undefined);
    const [opened, setOpened] = useState(shouldMenuBeOpened());
    const [skipWeekends, setSkeepWeekends] = useState(shouldSkipWeekends());

    const [changeAllOfType, setChangeAllOfType] = useState<{
        value: InputState;
        label: string;
    }>({
        value: InputState.EMPTY,
        label: selectEmptyState,
    });
    const [inputState, setInputState] = useState<InputState>(InputState.DONE);

    const isDraggable = false; // this is a nice feature but it was a mess with position: sticky... It worked nice with position: absolute tho

    const handleOnDrag = (e: DragEvent) => {
        e.preventDefault();
        if (!isDraggable) return;
        if (!ref.current) return;
        setLeft(e.pageX - ref.current.offsetWidth / 2);
        setBottom(height - e.pageY - ref.current.offsetHeight * 0.75);
    };

    const handleOnDragEnd = (e: DragEvent) => {
        e.preventDefault();
        if (!isDraggable) return;
        if (!ref.current) return;
        setLeft(e.pageX - ref.current.offsetWidth / 2);
        setBottom(height - e.pageY - ref.current.offsetHeight * 0.75);
    };

    const isFriday = currentDate.getDay() === 5;

    useEffect(() => {
        setIsMenuOpen(opened);
    }, [opened]);

    useEffect(() => {
        setSkipWeekends(skipWeekends);
    }, [skipWeekends]);

    if (!opened) {
        return (
            <SmallFloatingMenuContainer
                bottom={bottom}
                left={left}
                draggable={isDraggable}
                onDrag={handleOnDrag}
                onDragEnd={handleOnDragEnd}
                onDragOver={(e: DragEvent) => e.preventDefault()}
                ref={ref}
            >
                <Clickable onClick={() => setOpened(true)}>
                    <Spacer top={2} right={2} left={2} bottom={2}>
                        <Flex alignItems="center" justifyContent="center">
                            <BoldText>
                                <FormattedMessage
                                    id="openMenu"
                                    defaultMessage="Open"
                                />
                            </BoldText>
                            <Spacer right={1} />
                            <img
                                src={ArrowRight}
                                alt="Click here to open the menu"
                            />
                        </Flex>
                    </Spacer>
                </Clickable>
            </SmallFloatingMenuContainer>
        );
    }

    return (
        <FloatingMenuContainer
            bottom={bottom}
            left={left}
            draggable={isDraggable}
            onDrag={handleOnDrag}
            onDragEnd={handleOnDragEnd}
            onDragOver={(e: DragEvent) => e.preventDefault()}
            ref={ref}
        >
            <Spacer top={2} right={4} left={4} bottom={2}>
                <Flex direction="column" alignItems="center">
                    {isDraggable && <DragMenuButton />}
                    {isDraggable && <Spacer top={2} />}
                    <Flex
                        alignItems="center"
                        style={{
                            flexWrap: "wrap",
                            gap: isMedium ? "8px" : undefined,
                        }}
                    >
                        <Clickable onClick={() => setOpened(false)}>
                            <Flex>
                                <img
                                    src={ArrowLeft}
                                    alt="Click here to minimze the menu"
                                />
                                <Spacer right={1} />
                                <BoldText>
                                    <FormattedMessage
                                        id="minimizeMenu"
                                        defaultMessage="Minimize"
                                    />
                                </BoldText>
                            </Flex>
                        </Clickable>

                        <BigSpacer />

                        <Clickable
                            onClick={() => {
                                sortTasks();
                            }}
                        >
                            <MenuButtonContainer>
                                <BoldText>
                                    <FormattedMessage
                                        id="sort"
                                        defaultMessage="Sort"
                                    />
                                </BoldText>
                            </MenuButtonContainer>
                        </Clickable>
                        <BigSpacer />
                        <>
                            <FormattedMessage
                                id="changeAll"
                                defaultMessage="Change all"
                            />
                            <Spacer left={1} />
                            <Select
                                menuPlacement="auto"
                                options={[
                                    {
                                        value: InputState.DONE,
                                        label: selectDone,
                                    },
                                    {
                                        value: InputState.WONT_DO,
                                        label: selectDiscarded,
                                    },
                                    {
                                        value: InputState.NEXT_DAY,
                                        label: selectMovedToTomorrow,
                                    },
                                    {
                                        value: InputState.EMPTY,
                                        label: selectEmptyState,
                                    },
                                ]}
                                isSearchable={false}
                                value={changeAllOfType}
                                components={{ IndicatorSeparator: () => null }}
                                theme={(theme) => ({
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: FIRST_LAYER_COLOR,
                                        primary50: FIRST_LAYER_COLOR,
                                        primary: TEXT_COLOR,
                                        neutral0: TOP_LAYER_COLOR,
                                    },
                                    spacing: {
                                        ...theme.spacing,
                                        baseUnit: 4,
                                        controlHeight: 10,
                                    },
                                })}
                                onChange={(newValue) => {
                                    if (!newValue) return;
                                    setChangeAllOfType(newValue);
                                }}
                            />
                            <Spacer right={1} />
                            <FormattedMessage
                                id="tasksTo"
                                defaultMessage="tasks to"
                            />
                            <Spacer left={1} />
                            <CustomInput
                                state={inputState}
                                changeState={setInputState}
                            />
                            <Spacer left={2} />
                            <Clickable
                                onClick={() => {
                                    bulkAction(
                                        changeAllOfType.value,
                                        inputState,
                                        searchText,
                                    );
                                }}
                            >
                                <MenuButtonContainer>
                                    <BoldText>
                                        <FormattedMessage
                                            id="apply"
                                            defaultMessage="Apply"
                                        />
                                    </BoldText>
                                </MenuButtonContainer>
                            </Clickable>
                        </>
                        {isFriday && (
                            <>
                                <BigSpacer />
                                <FormattedMessage
                                    id="skipWeekends"
                                    defaultMessage="Skip work at weekends"
                                />
                                <Spacer right={1} />
                                <CustomCheckbox
                                    isOn={skipWeekends}
                                    changeState={setSkeepWeekends}
                                />
                            </>
                        )}
                    </Flex>
                </Flex>
            </Spacer>
        </FloatingMenuContainer>
    );
};
