import { useContext, useEffect } from "react";
import BigHeader from "../components/BigHeader";
import Flex from "../components/Flex";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { useNavigate } from "react-router-dom";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

const ContainerWithMaxWidth = styled.div`
    max-width: 800px;
    margin: auto;
`;

const CustomH2 = styled.h2`
    padding-top: 16px;
    padding-bottom: 4px;
`;
const EnglishCookiePolicy = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Introduction</CustomH2>
            <p>
                At Wise Owl, we are dedicated to safeguarding the privacy of our
                users and ensuring compliance with the European Union's General
                Data Protection Regulation (GDPR) regarding the use of cookies.
                This Cookie Policy outlines how we use cookies on
                www.wiseowl.cat to enhance the functionality of our web app
                while respecting your privacy rights.
            </p>
            <CustomH2>What are Cookies?</CustomH2>
            <p>
                Cookies are small pieces of data stored on your device when you
                visit a website. They are essential for the proper functioning
                of web applications, storing information needed to make the app
                work effectively.
            </p>
            <CustomH2>Types of Cookies Used on www.wiseowl.cat</CustomH2>
            <p>
                Essential Cookies: These cookies are crucial for the operation
                of our web app and store information necessary to make the app
                work seamlessly. They do not collect personal data and are
                essential for providing the services you request.
            </p>
            <CustomH2>How We Use Cookies</CustomH2>
            <p>
                We only store pieces of information that are essential for the
                functionality of our web app. These cookies help us deliver the
                services you expect and ensure a smooth user experience without
                compromising your privacy.
            </p>
            <CustomH2>Managing Cookies</CustomH2>
            <p>
                You can manage cookie preferences in your browser settings. By
                adjusting these settings, you can choose to accept or reject
                cookies or delete existing cookies on your device. Also, the
                first time you use our app, you will have the opportunity to
                accept them or not use the app, as they are essential for its
                operation.
            </p>
            <CustomH2>Third-Party Cookies</CustomH2>
            <p>
                We do not use any third-party cookies on www.wiseowl.cat. All
                cookies stored on your device are solely for the purpose of
                making our web app work effectively.
            </p>
            <CustomH2>Changes to this Cookie Policy</CustomH2>
            <p>
                Wise Owl reserves the right to update this Cookie Policy as
                needed to ensure compliance with EU regulations. Any significant
                changes will be communicated through our website.
            </p>
            <CustomH2>Contact Us</CustomH2>
            <p>
                If you have any questions or concerns about our Cookie Policy or
                the use of cookies on www.wiseowl.cat, please contact us at Wise
                Owl via email at hola@wiseowl.cat.
            </p>
            <CustomH2>Effective Date</CustomH2>
            <p>
                This Cookie Policy is effective as of may 11th of 2024 and will
                be reviewed and updated periodically to ensure compliance with
                EU regulations.
            </p>
        </ContainerWithMaxWidth>
    );
};

const SpanishCookiePolicy = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Introducción</CustomH2>
            <p>
                En Wise Owl, estamos dedicados a salvaguardar la privacidad de
                nuestros usuarios y garantizar el cumplimiento del Reglamento
                General de Protección de Datos de la Unión Europea (GDPR) en
                relación con el uso de cookies. Esta Política de Cookies
                describe cómo usamos las cookies en www.wiseowl.cat para mejorar
                la funcionalidad de nuestra aplicación web respetando tus
                derechos de privacidad.
            </p>
            <CustomH2>¿Qué son las Cookies?</CustomH2>
            <p>
                Las cookies son pequeños fragmentos de datos almacenados en tu
                dispositivo cuando visitas un sitio web. Son esenciales para el
                correcto funcionamiento de las aplicaciones web, almacenando la
                información necesaria para hacer que la aplicación funcione de
                manera efectiva.
            </p>
            <CustomH2>Tipos de Cookies utilizadas en www.wiseowl.cat</CustomH2>
            <p>
                Cookies esenciales: Estas cookies son cruciales para el
                funcionamiento de nuestra aplicación web y almacenan información
                necesaria para que la aplicación funcione sin problemas. No
                recopilan datos personales y son esenciales para proporcionar
                los servicios que solicitas.
            </p>
            <CustomH2>Cómo utilizamos las Cookies</CustomH2>
            <p>
                Solo almacenamos fragmentos de información que son esenciales
                para el funcionamiento de nuestra aplicación web. Estas cookies
                nos ayudan a entregar los servicios que esperas y garantizan una
                experiencia de usuario fluida sin comprometer tu privacidad.
            </p>
            <CustomH2>Gestión de Cookies</CustomH2>
            <p>
                Puedes gestionar las preferencias de cookies en la configuración
                de tu navegador. Al ajustar estas configuraciones, puedes elegir
                aceptar o rechazar cookies o eliminar las cookies existentes en
                tu dispositivo. También la primera vez que vayas a usar esta app
                tendrás la oportunidad de acceptarlas o no usar la app, ya que
                son esenciales para el funcionamiento de esta.
            </p>
            <CustomH2>Cookies de terceros</CustomH2>
            <p>
                No utilizamos ninguna cookie de terceros en www.wiseowl.cat.
                Todas las cookies almacenadas en tu dispositivo son únicamente
                para el propósito de hacer que nuestra aplicación web funcione
                de manera efectiva.
            </p>
            <CustomH2>Cambios en esta Política de Cookies</CustomH2>
            <p>
                Wise Owl se reserva el derecho de actualizar esta Política de
                Cookies según sea necesario para garantizar el cumplimiento de
                las regulaciones de la UE. Cualquier cambio significativo será
                comunicado a través de nuestro sitio web.
            </p>
            <CustomH2>Contáctanos</CustomH2>
            <p>
                Si tienes alguna pregunta o inquietud acerca de nuestra Política
                de Cookies o el uso de cookies en www.wiseowl.cat, por favor
                contáctanos en Wise Owl a través del correo electrónico en
                hola@wiseowl.cat.
            </p>
            <CustomH2>Fecha de vigencia</CustomH2>
            <p>
                Esta Política de Cookies es efectiva a partir de 11 de Mayo del
                2024 y será revisada y actualizada periódicamente para
                garantizar el cumplimiento de las regulaciones de la UE.
            </p>
        </ContainerWithMaxWidth>
    );
};

const CatalanCookiePolicy = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Introducció</CustomH2>
            <p>
                A Wise Owl, estem dedicats a salvaguardar la privacitat dels
                nostres usuaris i garantir el compliment del Reglament General
                de Protecció de Dades de la Unió Europea (GDPR) en relació amb
                l'ús de galetes. Aquesta Política de Galetes descriu com
                utilitzem les galetes a www.wiseowl.cat per millorar la
                funcionalitat de la nostra aplicació web respectant els teus
                drets de privacitat.
            </p>
            <CustomH2>Què són les galetes?</CustomH2>
            <p>
                Les galetes són petites peces de dades emmagatzemades al teu
                dispositiu quan visites un lloc web. Són essencials per al
                correcte funcionament de les aplicacions web, emmagatzemant la
                informació necessària per fer que l'aplicació funcioni de manera
                efectiva.
            </p>
            <CustomH2>Tipus de galetes utilitzades a www.wiseowl.cat</CustomH2>
            <p>
                Galetes essencials: Aquestes galetes són crucials per al
                funcionament de la nostra aplicació web i emmagatzemen
                informació necessària per fer que l'aplicació funcioni sense
                problemes. No recopilen dades personals i són essencials per
                proporcionar els serveis que sol·licites.
            </p>
            <CustomH2>Com utilitzem les Galetes</CustomH2>
            <p>
                Només emmagatzemem peces d'informació que són essencials per al
                funcionament de la nostra aplicació web. Aquestes galetes ens
                ajuden a lliurar els serveis que esperes i garanteixen una
                experiència d'usuari fluida sense comprometre la teva
                privacitat.
            </p>
            <CustomH2>Gestió de Galetes</CustomH2>
            <p>
                Pots gestionar les preferències de galetes a la configuració del
                teu navegador. En ajustar aquestes configuracions, pots triar
                acceptar o rebutjar galetes o eliminar les galetes existents al
                teu dispositiu. També el primer cop que vagis a fer ús de la
                nostra app tindràs l'oportunitat d'acceptar-les o no fer servir
                l'app, ja que són essencials pel funcionament d'aquesta.
            </p>
            <CustomH2>Galetes de tercers</CustomH2>
            <p>
                No utilitzem cap cookie de tercers a www.wiseowl.cat. Totes les
                galetes emmagatzemades al teu dispositiu són únicament per a
                l'objectiu de fer que la nostra aplicació web funcioni de manera
                efectiva.
            </p>
            <CustomH2>Canvis en aquesta Política de Galetes</CustomH2>
            <p>
                Wise Owl es reserva el dret d'actualitzar aquesta Política de
                Galetes segons sigui necessari per garantir el compliment de les
                regulacions de la UE. Qualsevol canvi significatiu serà
                comunicat a través del nostre lloc web.
            </p>
            <CustomH2>Contacta'ns</CustomH2>
            <p>
                Si tens alguna pregunta o preocupació sobre la nostra Política
                de Galetes o l'ús de galetes a www.wiseowl.cat, si us plau
                contacta'ns a Wise Owl a través del correu electrònic a
                hola@wiseowl.cat.
            </p>
            <CustomH2>Data d'efectivitat</CustomH2>
            <p>
                Aquesta Política de Galetes és efectiva a partir de 11 de maig
                del 2024 i serà revisada i actualitzada periòdicament per
                garantir el compliment de les regulacions de la UE.
            </p>
        </ContainerWithMaxWidth>
    );
};

export const CookiePolicy = () => {
    const navigate = useNavigate();
    const { isSmall } = useContext(ScreenSizeContext);
    const intl = useIntl();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let TitleComponent = BigHeader;
    if (isSmall) TitleComponent = Header;
    return (
        <Spacer
            left={isSmall ? 2 : 4}
            right={isSmall ? 2 : 10}
            top={isSmall ? 2 : 4}
            bottom={isSmall ? 2 : 4}
        >
            <Clickable onClick={() => navigate(-1)}>
                <Flex>
                    <img
                        src={ArrowLeft}
                        alt="Click here to go back to the previous page"
                    />
                    <Spacer right={1} />
                    <FormattedMessage id="back2" defaultMessage="Go back" />
                </Flex>
            </Clickable>
            <Spacer top={4} />
            <Flex direction="column">
                <ContainerWithMaxWidth>
                    <TitleComponent>
                        <FormattedMessage
                            id="cookiePolicy"
                            defaultMessage="Cookie Policy"
                        />
                    </TitleComponent>
                </ContainerWithMaxWidth>
                <Spacer top={3} />
                {intl.locale.substring(0, 2) === "ca" ? (
                    <CatalanCookiePolicy />
                ) : intl.locale.substring(0, 2) === "es" ? (
                    <SpanishCookiePolicy />
                ) : (
                    <EnglishCookiePolicy />
                )}
            </Flex>
        </Spacer>
    );
};
