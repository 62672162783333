import { ReactNode } from "react";
import styled from "styled-components";
import Flex from "./Flex";
import Spacer from "./Spacer";
import {
    INPUT_WONT_DO,
    TOP_LAYER_COLOR,
    PLACEHOLDER_TEXT_COLOR,
} from "../utils";

export const ErrorMessageContainer = styled.span`
    width: inherit;
`;

const StyledInput = styled.input<{ width?: string; isInvalid?: boolean }>`
    border: ${(props) =>
        props.isInvalid ? ` 2px solid ${INPUT_WONT_DO}` : `0px`};
    background: ${TOP_LAYER_COLOR};
    padding-right: 12px;
    padding-left: 12px;
    border-radius: 8px;
    width: ${(props) => props.width ?? "150px"};
    font-size: 14px;
    outline: none !important;
    min-height: 40px;
    width: 100%;
    max-width: 400px;

    ::placeholder {
        color: ${PLACEHOLDER_TEXT_COLOR};
    }

    &:hover {
        background: white;
    }

    &:focus {
        background: white;
    }
`;

export const InputText = ({
    textLabel,
    placeholder,
    id,
    type,
    width,
    ref,
    onChange,
    onValidate,
    inputValue,
    errorMessage,
    shouldShowError,
    turnOffAutocomplete,
}: {
    textLabel?: ReactNode;
    placeholder?: string;
    id: string;
    type: "text" | "email" | "password";
    width?: string;
    ref?: React.MutableRefObject<HTMLInputElement | null>;
    onChange?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    onValidate?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    inputValue?: string;
    errorMessage?: string;
    shouldShowError?: boolean;
    turnOffAutocomplete?: boolean;
}) => {
    return (
        <Flex direction="column" alignItems="start" width={width}>
            <label htmlFor={id}>{textLabel}</label>
            <Spacer top={0.5} />
            <StyledInput
                autoComplete={turnOffAutocomplete ? "off" : "on"}
                ref={ref}
                type={type}
                id={id}
                placeholder={placeholder}
                name={`${id}_input`}
                width={width}
                onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                    if (onChange) {
                        onChange(e);
                        if (onValidate) {
                            onValidate(e);
                        }
                    }
                }}
                value={inputValue}
                isInvalid={shouldShowError}
            />
            <FormErrorMessage
                errorMessage={errorMessage}
                shouldShowError={shouldShowError}
            />
        </Flex>
    );
};

export const FormErrorMessage = ({
    errorMessage,
    shouldShowError,
}: {
    errorMessage?: string;
    shouldShowError?: boolean;
}) => {
    if (errorMessage === undefined || !shouldShowError) {
        return null;
    }
    return (
        <ErrorMessageContainer>
            <Spacer top={0.5}>{errorMessage}</Spacer>
        </ErrorMessageContainer>
    );
};
