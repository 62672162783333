import styled from "styled-components";

const MULTIPLIER = 8;

const Spacer = styled.div<{
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
}>`
    padding-top: ${(props) =>
        props.top ? props.top * MULTIPLIER + "px" : "0px"};
    padding-left: ${(props) =>
        props.left ? props.left * MULTIPLIER + "px" : "0px"};
    padding-right: ${(props) =>
        props.right ? props.right * MULTIPLIER + "px" : "0px"};
    padding-bottom: ${(props) =>
        props.bottom ? props.bottom * MULTIPLIER + "px" : "0px"};
`;
export default Spacer;
