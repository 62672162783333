import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import Flex from "../components/Flex";
import Header from "../components/Header";
import { InputText } from "../components/InputText";
import Spacer from "../components/Spacer";
import CompleteArrowRight from "../images/completeArrowRight.svg";
import { Navigate, useNavigate } from "react-router-dom";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import { FormattedMessage } from "react-intl";
import { AuthorizationAPI } from "../api/api";
import { AuthContext } from "../contexts/AuthContext";
import { ButtonLink } from "../components/ButtonLink";
import { StyledRoundButton } from "../components/StyledRoundButton";
import FormattedText from "../components/FormattedText";
import { Divider } from "../components/Divider";
import { ErrorText } from "../components/ErrorText";
import { useString } from "../hooks/useString";
import { CLOUDFRONT_DOMAIN, ERROR_TEXT_COLOR } from "../utils";
import { RecoverPasswordPetition } from "./RecoverPasswordPetition";
import { isUserLoggedIn } from "../models/persistanceAPI";
import { useKeyDown } from "../hooks/useKeyDown";
import { StyledLogoImage } from "../components/StyledLogoImage";

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const StyledBanner = styled.div<{ success: boolean }>`
    background-color: ${(props) =>
        props.success ? "green" : ERROR_TEXT_COLOR};
`;

const VerifyEmailBanner = ({ success }: { success: boolean }) => {
    return (
        <StyledBanner success={success}>
            <Spacer top={2} left={2} bottom={2}>
                <FormattedText color="white" weight="300">
                    <FormattedMessage
                        id={
                            success
                                ? "emailVerified"
                                : "invalidEmailVerification"
                        }
                        defaultMessage={
                            success
                                ? "Congratulations, your email has been successfully verified! You can now log into Wise Owl :)"
                                : "Invalid email verification. You can log in and re-send the verification email."
                        }
                    />
                </FormattedText>
            </Spacer>
        </StyledBanner>
    );
};

export const LogIn = ({
    verifyEmailSuccess,
}: {
    verifyEmailSuccess?: boolean;
}) => {
    const navigate = useNavigate();
    const [shouldNavigateToApp, setShouldNavigateToApp] = useState<
        boolean | string
    >(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [randomImage, setRandomImage] = useState<number>(
        Math.floor(Math.random() * 4)
    );
    const [error, setError] = useState<string | undefined>(undefined);
    const [showRecoverPassword, setShowRecoverPassword] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { isSmall } = useContext(ScreenSizeContext);
    const { setToken } = useContext(AuthContext);

    const splash1 = `${CLOUDFRONT_DOMAIN}/wiseOwl1.jpeg`;
    const splash2 = `${CLOUDFRONT_DOMAIN}/wiseOwl2.jpeg`;
    const splash3 = `${CLOUDFRONT_DOMAIN}/wiseOwl3.jpeg`;
    const splash4 = `${CLOUDFRONT_DOMAIN}/wiseOwl4.jpeg`;

    const randomSplashImage = [splash1, splash2, splash3, splash4][randomImage];

    const invalidCredentialsError = useString("invalidCredentials");
    const placeholderEmail = useString("placeholderEmail");
    const placeholderPassword = useString("placeholderPassword");

    useEffect(() => {
        if (isUserLoggedIn()) {
            setShouldNavigateToApp("premium");
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const logIn = async () => {
        setIsLoading(true);
        await AuthorizationAPI.logIn(email, password)
            .catch((e) => {
                setError(e.detail);
            })
            .then((response) => {
                if (response?.status === 400 || response?.status === 401) {
                    setError(invalidCredentialsError);
                } else {
                    const access = response?.access;

                    if (access) {
                        setToken(access);
                        navigate("/premium");
                    }
                }
            });
        setIsLoading(false);
    };

    const isLoginDisabled = !email || !password;
    useKeyDown(() => {
        if (isLoginDisabled) {
            return;
        }
        logIn();
    }, ["Enter"]);

    if (shouldNavigateToApp === "premium") {
        return <Navigate to="/premium" />;
    }

    return (
        <>
            {verifyEmailSuccess !== undefined && (
                <VerifyEmailBanner success={verifyEmailSuccess} />
            )}
            <Flex grow={1} direction="row">
                <Flex grow={1} direction="column" style={{ height: "inherit" }}>
                    <Spacer
                        left={isSmall ? 2 : 4}
                        right={isSmall ? 2 : 10}
                        top={isSmall ? 2 : 4}
                        bottom={isSmall ? 2 : 4}
                    >
                        <Flex direction="column">
                            <Clickable
                                onClick={() => {
                                    if (showRecoverPassword) {
                                        setShowRecoverPassword(false);
                                    } else {
                                        navigate("/");
                                    }
                                }}
                            >
                                <Flex>
                                    <img
                                        src={ArrowLeft}
                                        alt="Click here to go back to the previous page"
                                    />
                                    <Spacer right={1} />
                                    <FormattedMessage
                                        id="back2"
                                        defaultMessage="Go back"
                                    />
                                </Flex>
                            </Clickable>
                            <Spacer top={4} />
                            <Header fontWeight="300" fontSize="48px">
                                <FormattedMessage
                                    id="welcome"
                                    defaultMessage="Welcome to Wise Owl"
                                />
                            </Header>
                        </Flex>
                        <Spacer top={8} />
                        {showRecoverPassword ? (
                            <RecoverPasswordPetition />
                        ) : (
                            <Flex
                                grow={1}
                                direction="column"
                                justifyContent="center"
                            >
                                <InputText
                                    placeholder={placeholderEmail}
                                    id="email"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    width={isSmall ? "300px" : undefined}
                                />
                                <Spacer top={2} />
                                <InputText
                                    placeholder={placeholderPassword}
                                    id="password"
                                    type="password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    width={isSmall ? "300px" : undefined}
                                />
                                <Spacer top={2} />
                                <ButtonLink
                                    onClick={() => setShowRecoverPassword(true)}
                                >
                                    <FormattedMessage
                                        id="forgotPassword"
                                        defaultMessage="Forgot your password?"
                                    />
                                </ButtonLink>
                                <Spacer top={4} />
                                <StyledRoundButton
                                    disabled={isLoginDisabled}
                                    isLoading={isLoading}
                                    onClick={logIn}
                                >
                                    <Spacer
                                        top={2}
                                        bottom={2}
                                        left={2}
                                        right={2}
                                    >
                                        <Flex alignItems="center">
                                            <FormattedText
                                                size="24px"
                                                weight="300"
                                            >
                                                <FormattedMessage
                                                    id="logIn"
                                                    defaultMessage="Log in"
                                                />
                                            </FormattedText>
                                            <Spacer left={2} />
                                            {!isLoading && (
                                                <StyledLogoImage
                                                    src={CompleteArrowRight}
                                                    alt="Click here to log into the app"
                                                />
                                            )}
                                        </Flex>
                                    </Spacer>
                                </StyledRoundButton>
                                {error && (
                                    <Spacer top={2}>
                                        <ErrorText>{error}</ErrorText>
                                    </Spacer>
                                )}

                                <Spacer top={error ? 2 : 4} />
                                <Divider />
                                <Spacer top={4} />
                                <Flex>
                                    <FormattedText>
                                        <FormattedMessage
                                            id="noAccount"
                                            defaultMessage="Don't have an account?"
                                        />
                                    </FormattedText>
                                    <Spacer left={0.5} />
                                    <ButtonLink
                                        onClick={() => navigate("/signup")}
                                    >
                                        <FormattedText underline>
                                            <FormattedMessage
                                                id="registerHere"
                                                defaultMessage="Register here"
                                            />
                                        </FormattedText>
                                    </ButtonLink>
                                </Flex>
                            </Flex>
                        )}
                    </Spacer>
                </Flex>
                {!isSmall && (
                    <Flex
                        grow={1}
                        direction="column"
                        style={{ height: "100vh" }}
                    >
                        <StyledImage src={randomSplashImage} />
                    </Flex>
                )}
            </Flex>
        </>
    );
};
