import { InputState } from "../models/constants";
import CustomInput from "./CustomInput";

export const CustomCheckbox = ({
    isOn,
    changeState,
}: {
    isOn: boolean;
    changeState: (newState: boolean) => void;
}) => {
    const changeStateHandler = (newState: InputState) => {
        if (isOn) changeState(false);
        else changeState(true);
    };
    if (isOn) {
        return (
            <CustomInput
                state={InputState.DONE}
                changeState={changeStateHandler}
            />
        );
    }
    return (
        <CustomInput
            state={InputState.WONT_DO}
            changeState={changeStateHandler}
        />
    );
};
