import { useContext, useEffect } from "react";
import BigHeader from "../components/BigHeader";
import Flex from "../components/Flex";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { useNavigate } from "react-router-dom";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { useString } from "../hooks/useString";

const ContainerWithMaxWidth = styled.div`
    max-width: 800px;
    margin: auto;
`;

const CustomH2 = styled.h2`
    padding-top: 16px;
    padding-bottom: 4px;
`;
const EnglishPrivacyPolicy = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Wise Owl Privacy Policy</CustomH2>
            <p>Effective Date: May 14th, 2024</p>

            <CustomH2>1. Introduction</CustomH2>
            <p>
                Wise Owl ("we," "our," or "us") is committed to protecting the
                privacy of our users ("you" or "users"). This Privacy Policy
                describes our practices concerning the collection, use, and
                disclosure of information we collect through our website
                [wiseowl.cat] and associated application (collectively, the
                "Service").
            </p>

            <CustomH2>2. Information We Collect</CustomH2>
            <p>
                <strong>Information You Provide:</strong> We collect information
                you provide directly when using our Service, such as when you
                register to create an account, create or manage tasks, or
                communicate with us.
            </p>
            <p>
                <strong>Automatic Information:</strong> We collect information
                automatically about your device and use of our Service, such as
                your browser type, pages visited, and cookie data.
            </p>
            <p>
                <strong>Cookies and Similar Technologies:</strong> We use
                cookies and similar technologies to collect information about
                your interaction with our Service and to improve your
                experience. For more information, please see our{" "}
                <a href="cookie-policy">Cookie Policy</a>.
            </p>

            <CustomH2>3. How We Use Your Information</CustomH2>
            <p>We use the information we collect to:</p>
            <p>- Provide, maintain, and improve our Service</p>
            <p>- Administer your account and provide customer support</p>
            <p>- Communicate with you about your account and our Service</p>
            <p>
                - Process payment transactions and manage subscriptions, if
                needed
            </p>
            <p>- Comply with legal and regulatory obligations</p>

            <CustomH2>4. Sharing Your Information</CustomH2>
            <p>
                We will not share your personal information with third parties
                except in the following circumstances:
            </p>
            <p>- With your consent</p>
            <p>
                - With service providers who assist us in operating our Service,
                including payment processors
            </p>
            <p>- To comply with applicable laws or respond to legal requests</p>

            <CustomH2>5. Your Rights</CustomH2>
            <p>
                You have the right to access, rectify, or delete your personal
                information, as well as to restrict or object to its processing.
                You also have the right to data portability and to lodge a
                complaint with a data protection authority.
            </p>

            <CustomH2>6. Security</CustomH2>
            <p>
                We implement reasonable security measures to protect your
                personal information from unauthorized access, use, alteration,
                and disclosure.
            </p>

            <CustomH2>7. Changes to this Privacy Policy</CustomH2>
            <p>
                We may update this Privacy Policy from time to time. We will
                post any changes on this page and update the effective date at
                the top of this policy.
            </p>

            <CustomH2>8. Contact</CustomH2>
            <p>
                If you have any questions about this Privacy Policy, you can
                contact us at hola@wiseowl.cat.
            </p>
        </ContainerWithMaxWidth>
    );
};

const SpanishPrivacyPolicy = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Política de Privacidad de Wise Owl</CustomH2>
            <p>Fecha de entrada en vigor: 14 de mayo del 2024</p>

            <CustomH2>1. Introducción</CustomH2>
            <p>
                Wise Owl ("nosotros", "nuestro" o "nos") se compromete a
                proteger la privacidad de nuestros usuarios ("usted" o
                "usuarios"). Esta Política de Privacidad describe nuestras
                prácticas con respecto a la recopilación, uso y divulgación de
                información que recopilamos a través de nuestro sitio web
                [wiseowl.cat] y la aplicación asociada (colectivamente, el
                "Servicio").
            </p>

            <CustomH2>2. Información que Recopilamos</CustomH2>
            <p>
                <strong>Información que Usted Proporciona:</strong> Recopilamos
                información que usted proporciona directamente al utilizar
                nuestro Servicio, como cuando se registra para crear una cuenta,
                crea o administra tareas, o se comunica con nosotros.
            </p>
            <p>
                <strong>Información Automática:</strong> Recopilamos información
                automáticamente sobre su dispositivo y el uso de nuestro
                Servicio, como su tipo de navegador, páginas visitadas y datos
                de cookies.
            </p>
            <p>
                <strong>Cookies y Tecnologías Similares:</strong> Utilizamos
                cookies y tecnologías similares para recopilar información sobre
                su interacción con nuestro Servicio y mejorar su experiencia.
                Para obtener más información, consulte nuestra{" "}
                <a href="cookie-policy">Política de Cookies</a>.
            </p>

            <CustomH2>3. Cómo Utilizamos su Información</CustomH2>
            <p>Utilizamos la información que recopilamos para:</p>
            <p>- Proporcionar, mantener y mejorar nuestro Servicio</p>
            <p>- Administrar su cuenta y proporcionar soporte al cliente</p>
            <p>- Comunicarnos con usted sobre su cuenta y nuestro Servicio</p>
            <p>
                - Procesar transacciones de pago y administrar suscripciones, si
                es necesario
            </p>
            <p>- Cumplir con obligaciones legales y regulatorias</p>

            <CustomH2>4. Compartir su Información</CustomH2>
            <p>
                No compartiremos su información personal con terceros excepto en
                las siguientes circunstancias:
            </p>
            <p>- Con su consentimiento</p>
            <p>
                - Con proveedores de servicios que nos ayuden a operar nuestro
                Servicio, incluidos procesadores de pagos
            </p>
            <p>
                - Para cumplir con leyes aplicables o responder a solicitudes
                legales
            </p>

            <CustomH2>5. Sus Derechos</CustomH2>
            <p>
                Usted tiene derecho a acceder, rectificar o eliminar su
                información personal, así como a restringir u oponerse a su
                procesamiento. También tiene derecho a la portabilidad de datos
                y a presentar una queja ante una autoridad de protección de
                datos.
            </p>

            <CustomH2>6. Seguridad</CustomH2>
            <p>
                Implementamos medidas de seguridad razonables para proteger su
                información personal contra el acceso, uso, alteración y
                divulgación no autorizados.
            </p>

            <CustomH2>7. Cambios a esta Política de Privacidad</CustomH2>
            <p>
                Podemos actualizar esta Política de Privacidad de vez en cuando.
                Publicaremos cualquier cambio en esta página y actualizaremos la
                fecha de entrada en vigor en la parte superior de esta política.
            </p>

            <CustomH2>8. Contacto</CustomH2>
            <p>
                Si tiene alguna pregunta sobre esta Política de Privacidad,
                puede contactarnos en hola@wiseowl.cat.
            </p>
        </ContainerWithMaxWidth>
    );
};

const CatalanPrivacyPolicy = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Política de Privacitat de Wise Owl</CustomH2>
            <p>Data d'entrada en vigor: 14 de maig del 2024</p>

            <CustomH2>1. Introducció</CustomH2>
            <p>
                Wise Owl ("nosaltres", "el nostre" o "nosaltres") es compromet a
                protegir la privacitat dels nostres usuaris ("vostè", "usuari" o
                "usuaris"). Aquesta Política de Privacitat descriu les nostres
                pràctiques en relació amb la recollida, ús i divulgació de la
                informació que recollim a través del nostre lloc web
                [wiseowl.cat] i l'aplicació associada (col·lectivament, el
                "Servei").
            </p>

            <CustomH2>2. Informació que recollim</CustomH2>
            <p>
                <strong>Informació que ens proporciona:</strong> Recollim la
                informació que ens proporciona directament quan utilitza el
                nostre Servei, com ara quan es registra per crear un compte,
                crea o gestiona tasques, o es comunica amb nosaltres.
            </p>
            <p>
                <strong>Informació automàtica:</strong> Recollim informació
                automàticament sobre el seu dispositiu i l'ús del nostre Servei,
                com ara el seu tipus de navegador, les pàgines que visita, i les
                dades de cookies.
            </p>
            <p>
                <strong>Cookies i tecnologies similars:</strong> Utilitzem
                cookies i tecnologies similars per recollir informació sobre la
                seva interacció amb el nostre Servei i millorar la seva
                experiència. Per a més informació, consulteu la nostra{" "}
                <a href="cookie-policy">Política de Cookies</a>.
            </p>

            <CustomH2>3. Com utilitzem la seva informació</CustomH2>
            <p>Utilitzem la informació que recollim per a:</p>
            <p>- Proporcionar, mantenir i millorar el nostre Servei</p>
            <p>
                - Administrar el seu compte i proporcionar assistència al client
            </p>
            <p>
                - Comunicar-nos amb vostè sobre el seu compte i el nostre Servei
            </p>
            <p>
                - Processar transaccions de pagament i gestionar subscripcions,
                si escau
            </p>
            <p>- Complir amb les obligacions legals i reguladores</p>

            <CustomH2>4. Compartir la seva informació</CustomH2>
            <p>
                No compartirem la seva informació personal amb tercers, excepte
                en les següents circumstàncies:
            </p>
            <p>- Amb el seu consentiment</p>
            <p>
                - Amb proveïdors de serveis que ens ajudin a operar el nostre
                Servei, incloent processadors de pagaments
            </p>
            <p>
                - Per complir amb les lleis aplicables o respondre a
                sol·licituds legals
            </p>

            <CustomH2>5. Els seus drets</CustomH2>
            <p>
                Vostè té dret a accedir, rectificar o esborrar la seva
                informació personal, així com a restringir o oposar-se al seu
                tractament. També té dret a la portabilitat de les dades i a
                presentar una reclamació davant una autoritat de protecció de
                dades.
            </p>

            <CustomH2>6. Seguretat</CustomH2>
            <p>
                Implementem mesures de seguretat raonables per protegir la seva
                informació personal contra l'accés, l'ús, la modificació i la
                divulgació no autoritzats.
            </p>

            <CustomH2>7. Canvis a aquesta política de privacitat</CustomH2>
            <p>
                Podem actualitzar aquesta Política de Privacitat de tant en
                tant. Publicarem qualsevol canvi a aquesta pàgina i
                actualitzarem la data d'entrada en vigor a la part superior
                d'aquesta política.
            </p>

            <CustomH2>8. Contacte</CustomH2>
            <p>
                Si té alguna pregunta sobre aquesta Política de Privacitat, pot
                contactar-nos a hola@wiseowl.cat.
            </p>
        </ContainerWithMaxWidth>
    );
};

export const PrivacyPolicy = ({
    onClickBack,
}: {
    onClickBack?: () => void;
}) => {
    const navigate = useNavigate();
    const { isSmall } = useContext(ScreenSizeContext);
    const intl = useIntl();
    const goBack = useString("back2");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let TitleComponent = BigHeader;
    if (isSmall) TitleComponent = Header;
    return (
        <Spacer
            left={isSmall ? 2 : 4}
            right={isSmall ? 2 : 10}
            top={isSmall ? 2 : 4}
            bottom={isSmall ? 2 : 4}
        >
            <Clickable
                onClick={() => (onClickBack ? onClickBack() : navigate(-1))}
            >
                <Flex>
                    <img
                        src={ArrowLeft}
                        alt="Click here to go back to the previous page"
                    />
                    <Spacer right={1} />
                    {goBack}
                </Flex>
            </Clickable>
            <Spacer top={4} />
            <Flex direction="column">
                <ContainerWithMaxWidth>
                    <TitleComponent>
                        <FormattedMessage
                            id="privacyPolicy"
                            defaultMessage="Privacy Policy"
                        />
                    </TitleComponent>
                </ContainerWithMaxWidth>
                <Spacer top={3} />
                {intl.locale.substring(0, 2) === "ca" ? (
                    <CatalanPrivacyPolicy />
                ) : intl.locale.substring(0, 2) === "es" ? (
                    <SpanishPrivacyPolicy />
                ) : (
                    <EnglishPrivacyPolicy />
                )}
            </Flex>
        </Spacer>
    );
};
