import { useNavigate, useParams } from "react-router-dom";
import Flex from "../components/Flex";
import FormattedText from "../components/FormattedText";
import { FormattedMessage } from "react-intl";
import { useString } from "../hooks/useString";
import Spacer from "../components/Spacer";
import { InputText } from "../components/InputText";
import { useContext, useState } from "react";
import { ErrorText } from "../components/ErrorText";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import CompleteArrowRight from "../images/completeArrowRight.svg";
import { StyledRoundButton } from "../components/StyledRoundButton";
import { UserAPI } from "../api/api";
import { ButtonLink } from "../components/ButtonLink";

export const RecoverPassword = () => {
    const navigate = useNavigate();
    const { user_id, hash } = useParams();
    const { isSmall } = useContext(ScreenSizeContext);

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [backendResponse, setBackendResponse] = useState<any>(undefined);

    const repeatPassword = useString("placeholderRepeatPassword");
    const passwordsMustMatchError = useString("passwordsMustMatch");
    const passwordTooShortError = useString("passwordTooShort");
    const placeholderPassword = useString("placeholderPassword");
    const placeholderRepeatPassword = useString("placeholderRepeatPassword");

    const doPasswordsNotMatch =
        password !== password2 && !!password && !!password2;
    const isPasswordTooShort = !!password && password.length < 8;
    return (
        <Flex direction="column" alignItems="center">
            <Flex direction="column">
                <Spacer top={8} />
                <FormattedMessage
                    id="createNewPassword"
                    defaultMessage="Create a new password"
                />
                <InputText
                    placeholder={placeholderPassword}
                    id="password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    width={isSmall ? "300px" : undefined}
                />
                {isPasswordTooShort && (
                    <Spacer top={1}>
                        <ErrorText>{passwordTooShortError}</ErrorText>
                    </Spacer>
                )}
                <Spacer top={2} />
                {repeatPassword}
                <InputText
                    placeholder={placeholderRepeatPassword}
                    id="password"
                    type="password"
                    onChange={(e) => setPassword2(e.target.value)}
                    width={isSmall ? "300px" : undefined}
                />
                {doPasswordsNotMatch && (
                    <Spacer top={1}>
                        <ErrorText>{passwordsMustMatchError}</ErrorText>
                    </Spacer>
                )}
                <Spacer top={4} />
                <StyledRoundButton
                    disabled={
                        !password ||
                        !password2 ||
                        doPasswordsNotMatch ||
                        isPasswordTooShort ||
                        user_id === undefined ||
                        hash === undefined
                    }
                    onClick={() => {
                        if (user_id === undefined || hash === undefined) {
                            return;
                        }
                        UserAPI.resetPassword(user_id, hash, password).then(
                            (response) => {
                                setBackendResponse(response.status);
                            }
                        );
                    }}
                >
                    <Spacer top={2} bottom={2} left={2} right={2}>
                        <Flex>
                            <FormattedText size="24px" weight="300">
                                <FormattedMessage
                                    id="updatePassword"
                                    defaultMessage="Update password"
                                />
                            </FormattedText>
                            <Spacer left={2} />
                            <img
                                src={CompleteArrowRight}
                                alt="Click here to update your password"
                            />
                        </Flex>
                    </Spacer>
                </StyledRoundButton>
                {backendResponse && <Spacer top={2} />}
                {backendResponse &&
                    (backendResponse === 200 ? (
                        <Flex direction="row">
                            <FormattedText color="green" weight="300">
                                <FormattedMessage
                                    id="passwordUpdated"
                                    defaultMessage="Password updated"
                                />
                            </FormattedText>
                            <Spacer right={1} />
                            <ButtonLink onClick={() => navigate("/login")}>
                                <FormattedMessage
                                    id="goToLogin"
                                    defaultMessage="Go to login"
                                />
                            </ButtonLink>
                        </Flex>
                    ) : (
                        <ErrorText>
                            <FormattedMessage
                                id="passwordUpdateError"
                                defaultMessage="Error updating password"
                            />
                        </ErrorText>
                    ))}
            </Flex>
        </Flex>
    );
};
