import styled from "styled-components"
import { TOP_LAYER_COLOR, MIDDLE_FIRST_SECOND_LAYER_COLOR, LIGHT_TEXT_COLOR, TEXT_COLOR } from "../utils";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { useEffect, useRef, useState } from "react";
import SearchIcon from "../images/search.svg";
import Flex from "./Flex";
import Spacer from "./Spacer";
import { useString } from "../hooks/useString";
import { useScreenSize } from "../hooks/useScreenSize";
import Clickable from "./Clickable";
import FormattedText from "./FormattedText";
import { FormattedMessage } from "react-intl";

const SearchBoxContainer = styled.div<{hasFocus?: boolean, isSmall?: boolean}>`
    background: ${(props) => (props.hasFocus ? TOP_LAYER_COLOR : MIDDLE_FIRST_SECOND_LAYER_COLOR)};
    border-radius: ${(props) => props.isSmall ? "0px":"8px"};
    width: ${(props) => props.isSmall ? "inherit":"100%"};
    padding: 12px;

    &:hover {
        cursor: pointer;
    }
`;

const SearchBoxInput = styled.input`
    width: 100%;
    height: 20px;
    border: none;
    outline: none;
    resize: none;
    font-size: 18px;
    overflow: hidden;
    background: transparent;
    ::placeholder {
        color: ${LIGHT_TEXT_COLOR};
    }
`;

const ClearButtonContainer = styled.div`
    width: fit-content;
`;

export const SearchBox = ({
    text,
    setText
}:{
    text: string | null;
    setText: (text: string | null) => void;
}) => {
    const containerRef = useRef(null);
    const searchBoxRef = useRef<HTMLInputElement>(null);
    const { isSmall} = useScreenSize()
    const [hasFocus, setHasFocus] = useState(false);
    
    const placeholder = useString("search_box_placeholder");
    useOnClickOutside(containerRef, () => {
        setHasFocus(false);
    })

    useEffect(() => {
        if (hasFocus && searchBoxRef.current) {
            searchBoxRef.current.focus();
        }
    }, [hasFocus, text]);

    useEffect(() => {
        if (text === "" && !hasFocus) {
            setText(null);
        }
    }, [text, hasFocus, setText])
    
    return (
        <SearchBoxContainer
            ref={containerRef}
            hasFocus={hasFocus}
            onClick={() => {
                if (searchBoxRef.current) {
                    searchBoxRef.current.focus();
                }
                setHasFocus(true);
            }}
            isSmall={isSmall}
        >
            <Flex alignItems="center">
                <img 
                    src={SearchIcon}
                    alt="search"
                    style={{width: "20px", height: "20px"}}
                />
                <Spacer right={2}/>
                <Flex justifyContent="space-between" alignItems="center">
                    <SearchBoxInput
                        ref={searchBoxRef}
                        placeholder={placeholder}
                        value={text ?? ""}
                        onChange={(e) => {
                            e.stopPropagation();
                            setHasFocus(true);
                            setText(e.target.value)
                        }}
                        onFocus={() => setHasFocus(true)}
                    />
                    {text && text.length > 0 && (
                        <ClearButtonContainer>
                            <Clickable onClick={() => setText("")}>
                                <FormattedText color={TEXT_COLOR} weight="300">
                                    <FormattedMessage 
                                        id="clear" 
                                        defaultMessage="Clear"
                                    />
                                </FormattedText>
                            </Clickable>
                            <Spacer right={4}/> 
                        </ClearButtonContainer>
                    )}
                </Flex>
            </Flex>
        </SearchBoxContainer>
    )
}