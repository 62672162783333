import React, { ReactNode, useEffect, useState } from "react";
import { ACCESS_TOKEN } from "../api/api";
import { setInvalidateTokenSingletonCallback } from "../api/invalidateTokenSingleton";

type AuthContextType = {
    token: string | null;
    setToken: (token: string) => void;
};

export const AuthContext = React.createContext<AuthContextType>({
    token: null,
    setToken: () => {},
});

type PropsWithChildren<P = unknown> = P & { children: ReactNode };

const AuthProvider = ({ children }: PropsWithChildren) => {
    const [token, setToken] = useState(localStorage.getItem(ACCESS_TOKEN));

    const invalidateToken = () => {
        setToken(null);
    }

    useEffect(() => {
        setInvalidateTokenSingletonCallback(invalidateToken)
    })
    
    return (
        <AuthContext.Provider
            value={{
                token,
                setToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
