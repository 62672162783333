import Flex from "../components/Flex";
import { InputText } from "../components/InputText";
import Spacer from "../components/Spacer";
import CompleteArrowRight from "../images/completeArrowRight.svg";
import { FormattedMessage } from "react-intl";
import { StyledRoundButton } from "../components/StyledRoundButton";
import FormattedText from "../components/FormattedText";
import { ErrorText } from "../components/ErrorText";
import { useString } from "../hooks/useString";
import { useContext, useState } from "react";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { UserAPI } from "../api/api";
import { StyledLogoImage } from "../components/StyledLogoImage";

export const RecoverPasswordPetition = () => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState<undefined | boolean>(undefined);
    const { isSmall } = useContext(ScreenSizeContext);
    const placeholderEmail = useString("placeholderEmail");
    return (
        <Flex grow={1} direction="column" justifyContent="center">
            <FormattedText>
                <FormattedMessage
                    id="recoverPasswordTitle"
                    defaultMessage="Recover your password by indicating your email:"
                />
            </FormattedText>
            <Spacer top={2} />
            <InputText
                placeholder={placeholderEmail}
                id="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                width={isSmall ? "300px" : undefined}
            />
            <Spacer top={3} />
            <StyledRoundButton
                disabled={!email}
                isLoading={isLoading}
                onClick={async () => {
                    setIsLoading(true);
                    UserAPI.resetPasswordPetition(email).then((response) => {
                        if (response.status === 200) {
                            setResult(true);
                        } else {
                            setResult(false);
                        }
                    });
                    setIsLoading(false);
                }}
            >
                <Spacer top={2} bottom={2} left={2} right={2}>
                    <Flex>
                        <FormattedText size="24px" weight="300">
                            <FormattedMessage
                                id="sendRecoverPasswordMail"
                                defaultMessage="Send mail"
                            />
                        </FormattedText>
                        <Spacer left={2} />
                        <StyledLogoImage
                            src={CompleteArrowRight}
                            alt="Click here to recover your password"
                        />
                    </Flex>
                </Spacer>
            </StyledRoundButton>
            {result !== undefined && (
                <Spacer top={2}>
                    {result ? (
                        <FormattedText color="green" weight="300">
                            <FormattedMessage
                                id="emailSent"
                                defaultMessage="Email sent"
                            />
                        </FormattedText>
                    ) : (
                        <ErrorText>
                            <FormattedMessage
                                id="emailNotFound"
                                defaultMessage="Email not found"
                            />
                        </ErrorText>
                    )}
                </Spacer>
            )}
        </Flex>
    );
};
