import React from "react";
import { InputState, calculateNextInputState } from "../models/constants";
import Clickable from "./Clickable";
import styled from "styled-components";

import {
    CLOUDFRONT_DOMAIN,
    FIRST_LAYER_COLOR,
    INPUT_DONE,
    INPUT_NEXT_DAY,
    INPUT_WONT_DO,
} from "../utils";

const InputBase = styled.div`
    width: 30px;
    height: 30px;
    border: 2px solid black;
    box-shadow: 1px 1px;
    #background: ${FIRST_LAYER_COLOR};
`;

const InputDone = styled(InputBase)`
    border: 2px solid ${INPUT_DONE};
    box-shadow: 1px 1px ${INPUT_DONE};
`;

const InputNextDay = styled(InputBase)`
    border: 2px solid ${INPUT_NEXT_DAY};
    box-shadow: 1px 1px ${INPUT_NEXT_DAY};
`;

const InputWontDo = styled(InputBase)`
    border: 2px solid ${INPUT_WONT_DO};
    box-shadow: 1px 1px ${INPUT_WONT_DO};
`;

const CustomInput = ({
    state,
    changeState,
}: {
    state: InputState;
    changeState: (newState: InputState) => void;
}) => {
    const CheckIcon = `${CLOUDFRONT_DOMAIN}/checkIcon.svg`;
    const ArrowIcon = `${CLOUDFRONT_DOMAIN}/arrowIcon.svg`;
    const CrossIcon = `${CLOUDFRONT_DOMAIN}/crossIcon.svg`;

    let InputWrapper = InputBase;
    let Icon = undefined;
    if (state === InputState.DONE) {
        InputWrapper = InputDone;
        Icon = CheckIcon;
    } else if (state === InputState.NEXT_DAY) {
        InputWrapper = InputNextDay;
        Icon = ArrowIcon;
    } else if (state === InputState.WONT_DO) {
        InputWrapper = InputWontDo;
        Icon = CrossIcon;
    }

    return (
        <InputWrapper>
            <Clickable
                onClick={() => {
                    const newState = calculateNextInputState(state);
                    changeState(newState);
                }}
            >
                <img src={Icon} alt={""} />
            </Clickable>
        </InputWrapper>
    );
};

export default CustomInput;
