let invalidateTokenCallback = () => {
    console.warn('invalidateToken not implemented');
};

export const setInvalidateTokenSingletonCallback = (callback: () => void) => {
    invalidateTokenCallback = callback;
};

export const invalidateTokenSingleton = () => {
    invalidateTokenCallback();
};