import { useContext } from "react";
import BigHeader from "../components/BigHeader";
import Flex from "../components/Flex";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { useNavigate } from "react-router-dom";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import { FormattedMessage } from "react-intl";
import { ButtonLink } from "../components/ButtonLink";

export const Changelog = () => {
    const navigate = useNavigate();
    const { isSmall } = useContext(ScreenSizeContext);
    let TitleComponent = BigHeader;
    if (isSmall) TitleComponent = Header;
    return (
        <Spacer
            left={isSmall ? 2 : 4}
            right={isSmall ? 2 : 10}
            top={isSmall ? 2 : 4}
            bottom={isSmall ? 2 : 4}
        >
            <Clickable onClick={() => navigate(-1)}>
                <Flex>
                    <img
                        src={ArrowLeft}
                        alt="Click here to go back to the previous page"
                    />
                    <Spacer right={1} />
                    <FormattedMessage id="back2" defaultMessage="Go back" />
                </Flex>
            </Clickable>
            <Spacer top={4} />
            <Flex direction="column">
                <TitleComponent>
                    <FormattedMessage
                        id="changelog"
                        defaultMessage="CHANGELOG"
                    />
                </TitleComponent>
                <Spacer top={4} />
                <Header>v2.0 🥇</Header>
                <Spacer top={2} />
                <ul>
                    <li>
                        <FormattedMessage
                            id="v2.0_1"
                            defaultMessage="Finally Wise Owl is available on multiple devices for the same user! You can now use Wise Owl in your phone, tablet, and computer and all your tasks will be synchronized."
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="v2.0_2"
                            defaultMessage="We have the new {logIn} {signUp} pages in Wise Owl. Now you can create an account and log in to Wise Owl to have your tasks synchronized in all your devices."
                            values={{
                                logIn: (
                                    <Spacer left={3}>
                                        <li>
                                            <ButtonLink
                                                onClick={() =>
                                                    navigate("/login")
                                                }
                                            >
                                                <FormattedMessage
                                                    id="logIn"
                                                    defaultMessage="Log in"
                                                />
                                            </ButtonLink>
                                        </li>
                                    </Spacer>
                                ),
                                signUp: (
                                    <Spacer left={3}>
                                        <li>
                                            <ButtonLink
                                                onClick={() =>
                                                    navigate("/signup")
                                                }
                                            >
                                                <FormattedMessage
                                                    id="signUp"
                                                    defaultMessage="Sign up"
                                                />
                                            </ButtonLink>
                                        </li>
                                    </Spacer>
                                ),
                            }}
                        />
                    </li>
                </ul>
                <Spacer top={4} />
                <Header>v1.3.0</Header>
                <Spacer top={2} />
                <ul>
                    <li>
                        <FormattedMessage
                            id="v1.3_1"
                            defaultMessage="Now you can directly paste tasks to do/done from a
                        markdown file in Owl."
                        />
                    </li>
                </ul>
                <Spacer top={4} />
                <Header>v1.2.0</Header>
                <Spacer top={2} />
                <ul>
                    <li>
                        <FormattedMessage
                            id="v1.2_1"
                            defaultMessage="Added the floating menu that allows you to sort tasks
                        and apply bulk action on them. Also, you will be able to
                        decide when passing tasks from a friday to the next day
                        whether you want the tasks to skip the weekend or not."
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="v1.2_2"
                            defaultMessage="Now you can visit the homepage to read information about
                        Owl even after you have started to use this amazing tool"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="v1.2_3"
                            defaultMessage="Added this Changelog!"
                        />
                    </li>
                </ul>
                <Spacer top={4} />
                <Header>v1.1.0</Header>
                <Spacer top={2} />
                <FormattedMessage
                    id="v1_0"
                    defaultMessage="This is the first public release 🎉 These are the main features:"
                />

                <ul>
                    <li>
                        <FormattedMessage
                            id="v1_1"
                            defaultMessage="Add the tasks you need to do each day so you can
                        calendarize them and avoid feeling overwhelmed by the
                        amount of work you need to get done"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="v1_2"
                            defaultMessage="Manage those tasks easily: mark them as done, discard
                        them, or move them to the next day"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="v1_3"
                            defaultMessage="Your unfinished tasks from yesterday will be carried out
                        automatically to today, so you can avoid doing that
                        manually"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="v1_4"
                            defaultMessage="Use a beautiful and intuitive UI to manage everything"
                        />
                    </li>
                </ul>
            </Flex>
        </Spacer>
    );
};
