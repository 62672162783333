import styled from "styled-components";
import { TOP_LAYER_COLOR, DARK_BACKGROUND_COLOR } from "../utils";
import { PropsWithChildren } from "react";
import Flex from "./Flex";
import { Spinner } from "./Spinner";

const StyledRoundButtonContainer = styled.button<{
    disabled?: boolean;
    dark?: boolean;
    customColor?: string;
    borderColor?: string;
}>`
    background: ${(props) =>
        props.customColor
            ? props.customColor
            : props.dark
            ? DARK_BACKGROUND_COLOR
            : TOP_LAYER_COLOR};
    border-radius: 8px;
    border-width: 0px;
    width: fit-content;
    border: ${(props) =>
        props.borderColor ? `2px solid ${props.borderColor}` : undefined};
    &:hover {
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    }

    &:focus {
        filter: brightness(92%);
    }
`;

export const StyledRoundButton = ({
    children,
    isLoading,
    disabled,
    dark,
    customColor,
    onClick,
    borderColor,
}: PropsWithChildren<{}> & {
    isLoading?: boolean;
    disabled?: boolean;
    dark?: boolean;
    customColor?: string;
    borderColor?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
    return (
        <StyledRoundButtonContainer
            disabled={disabled}
            dark={dark}
            customColor={customColor}
            onClick={onClick}
            borderColor={borderColor}
        >
            {isLoading ? (
                <Flex direction="row">
                    <Spinner />
                    {children}
                </Flex>
            ) : (
                children
            )}
        </StyledRoundButtonContainer>
    );
};
