import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserAPI } from "../api/api";
import Spacer from "../components/Spacer";
import { LogIn } from "./LogIn";

export const VerifyEmailPage = () => {
    const { user_id, hash } = useParams();
    const [backendResponse, setBackendResponse] = useState<any>(undefined);

    useEffect(() => {
        if (user_id === undefined || hash === undefined) {
            setBackendResponse({
                status: 400,
                message: "Invalid URL params.",
            });
        } else {
            UserAPI.verifyUser(user_id, hash).then((response) => {
                setBackendResponse(response);
            });
        }
    }, [hash, user_id]);

    if (
        backendResponse &&
        "status" in backendResponse &&
        backendResponse.status === 200
    ) {
        return <LogIn verifyEmailSuccess />;
    } else if (
        backendResponse &&
        "status" in backendResponse &&
        backendResponse.status === 400
    ) {
        return <LogIn verifyEmailSuccess={false} />;
    }
    return (
        <Spacer top={4} left={2}>
            ...
        </Spacer>
    );
};
