import styled from "styled-components";
import Flex from "../components/Flex";
import Spacer from "../components/Spacer";
import { FormattedMessage } from "react-intl";
import { TOP_LAYER_COLOR } from "../utils";

const FeaturesContainer = styled(Flex)`
    background: ${TOP_LAYER_COLOR};
    border-radius: 8px;
`;

const FeatureItem = ({
    children,
    emoji,
}: {
    children: React.ReactNode[] | React.ReactNode;
    emoji: string;
}) => {
    return (
        <Spacer bottom={2}>
            <Flex alignItems="center">
                {emoji}
                <Spacer right={1} />
                {children}
            </Flex>
        </Spacer>
    );
};

export const PremiumFeatures = () => {
    return (
        <FeaturesContainer>
            <Spacer top={2} left={3} right={3}>
                <Flex direction="column">
                    <FeatureItem emoji="✅">
                        <FormattedMessage
                            id="subscribeFeature1"
                            defaultMessage="Zero bureaucracy! Avoid stress by organizing quickly."
                        />
                    </FeatureItem>
                    <FeatureItem emoji="💯">
                        <FormattedMessage
                            id="subscribeFeature2"
                            defaultMessage="Enjoy an intuitive interface with which you can write tasks quickly and simply."
                        />
                    </FeatureItem>
                    <FeatureItem emoji="🧭">
                        <FormattedMessage
                            id="subscribeFeature3"
                            defaultMessage="Works in any modern browser."
                        />
                    </FeatureItem>
                    <FeatureItem emoji="💻">
                        <FormattedMessage
                            id="subscribeFeature4"
                            defaultMessage="Work on a mobile, a tablet or a computer."
                        />
                    </FeatureItem>
                    <FeatureItem emoji="⛅️">
                        <FormattedMessage
                            id="subscribeFeature5"
                            defaultMessage="Store your tasks securely on the cloud."
                        />
                    </FeatureItem>
                    <FeatureItem emoji="🥲">
                        <FormattedMessage
                            id="subscribeFeature6"
                            defaultMessage="Unsubscribe whenever you want"
                        />
                    </FeatureItem>
                </Flex>
            </Spacer>
        </FeaturesContainer>
    );
};

export const FreeFeatures = () => {
    return (
        <FeaturesContainer>
            <Spacer top={2} left={3} right={3}>
                <Flex direction="column">
                    <FeatureItem emoji="✅">
                        <FormattedMessage
                            id="freeFeature1"
                            defaultMessage="Try Wise Owl for free in your browser and start organizing better."
                        />
                    </FeatureItem>
                    <FeatureItem emoji="✅">
                        <FormattedMessage
                            id="freeFeature11"
                            defaultMessage="All essential features are available for free."
                        />
                    </FeatureItem>
                    <FeatureItem emoji="💻">
                        <FormattedMessage
                            id="freeFeature2"
                            defaultMessage="All your data is stored on your computer or phone locally."
                        />
                    </FeatureItem>
                    <FeatureItem emoji="❌">
                        <FormattedMessage
                            id="freeFeature3"
                            defaultMessage="There is no multi-device sync, you can only use it on one device."
                        />
                    </FeatureItem>
                    <FeatureItem emoji="❌">
                        <FormattedMessage
                            id="freeFeature4"
                            defaultMessage="You won't have access to advanced features like the calendar."
                        />
                    </FeatureItem>
                    <FeatureItem emoji="❌">
                        <FormattedMessage
                            id="freeFeature5"
                            defaultMessage="There is no technical support for free users."
                        />
                    </FeatureItem>
                </Flex>
            </Spacer>
        </FeaturesContainer>
    );
};
