import { InputState } from "../models/constants";
import { Day, Task, TaskInDay } from "../models/types";
import { getInputStateFromPair } from "../utils";
import {
    DayFromAPI,
    TaskFromAPI,
    TaskInDayFromAPI,
    TypeWithStatus,
} from "./adapterTypes";
import { DayAPI, TaskInDayAPI, TaskAPI } from "./api";

export class TaskAPIAdapter {
    private static fromTaskFromAPItoTask = (
        task: TypeWithStatus<TaskFromAPI>
    ): Task => {
        return {
            id: task.data.id.toString(),
            text: task.data.text,
        };
    };

    static getTask = async (task_id: string): Promise<undefined | Task> => {
        const task = await TaskAPI.getTask(task_id);
        if (task === undefined) {
            return undefined;
        }
        return TaskAPIAdapter.fromTaskFromAPItoTask(task);
    };

    static createTask = async (text: string): Promise<Task> => {
        const task = await TaskAPI.createTask(text);
        return TaskAPIAdapter.fromTaskFromAPItoTask(task);
    };

    static updateTask = async (
        task_id: string,
        text: string
    ): Promise<undefined | Task> => {
        const task = await TaskAPI.updateTask(task_id, text);
        if (task === undefined) {
            return undefined;
        }
        return TaskAPIAdapter.fromTaskFromAPItoTask(task);
    };

    static deleteTask = async (task_id?: string): Promise<boolean> => {
        if (task_id === undefined) {
            return false;
        }
        const task = await TaskAPI.deleteTask(task_id);
        return task.status === 204;
    };

    static getTasks = async (): Promise<Task[]> => {
        const tasks = await TaskAPI.getTasks();
        return tasks.data.map((task) =>
            TaskAPIAdapter.fromTaskFromAPItoTask({
                data: task,
                status: tasks.status,
            })
        );
    };
}

export class TaskInDayAPIAdapter {
    private static fromTaskInDayFromAPItoTaskInDay = (
        taskInDay: TypeWithStatus<TaskInDayFromAPI>
    ): TaskInDay => {
        return {
            id: taskInDay.data.task.toString(),
            state: getInputStateFromPair(taskInDay.data.status),
        };
    };

    static getTaskInDay = async (
        task_id: string,
        day_date: Date
    ): Promise<undefined | TaskInDay> => {
        const taskInDay = await TaskInDayAPI.getTaskInDay(task_id, day_date);
        if (taskInDay === undefined) {
            return undefined;
        }
        return TaskInDayAPIAdapter.fromTaskInDayFromAPItoTaskInDay(taskInDay);
    };

    static createTaskInDay = async (
        task_id: string,
        day_date: Date,
        index?: number
    ): Promise<TaskInDay> => {
        const taskInDay = await TaskInDayAPI.createTaskInDay(
            task_id,
            day_date,
            index
        );
        return TaskInDayAPIAdapter.fromTaskInDayFromAPItoTaskInDay(taskInDay);
    };

    static updateTaskInDay = async (
        task_id: string,
        day_date: Date,
        new_status: InputState
    ): Promise<TaskInDay> => {
        const taskInDay = await TaskInDayAPI.updateTaskInDay(
            task_id,
            day_date,
            new_status
        );
        return TaskInDayAPIAdapter.fromTaskInDayFromAPItoTaskInDay(taskInDay);
    };

    static deleteTaskInDay = async (
        task_id: string,
        day_date: Date
    ): Promise<undefined | TaskInDay> => {
        await TaskInDayAPI.deleteTaskInDay(task_id, day_date);
        return;
    };

    static getTasksInDay = async (day_date: Date): Promise<TaskInDay[]> => {
        const tasksInDay = await TaskInDayAPI.getTasksInDay(day_date);
        return tasksInDay.data.map((taskInDay) =>
            TaskInDayAPIAdapter.fromTaskInDayFromAPItoTaskInDay({
                data: taskInDay,
                status: tasksInDay.status,
            })
        );
    };

    static updateTasksInDay = async (
        day_date: Date,
        tasks: string[],
        new_status: InputState
    ): Promise<TaskInDay[]> => {
        const tasksInDay = await TaskInDayAPI.updateTasksInDay(
            day_date,
            tasks,
            new_status
        );
        return tasksInDay.data.map((taskInDay) =>
            TaskInDayAPIAdapter.fromTaskInDayFromAPItoTaskInDay({
                data: taskInDay,
                status: tasksInDay.status,
            })
        );
    };
}

export class DayAPIAdapter {
    private static fromDayFromAPItoDay = (
        day: TypeWithStatus<DayFromAPI>
    ): Day => {
        const assignedTasks = day.data.tasks_in_day.map(
            (taskInDay): TaskInDay => {
                return {
                    id: taskInDay.task.toString(),
                    state: getInputStateFromPair(taskInDay.status),
                };
            }
        );
        return {
            date: day.data.date,
            assignedTasks,
            pendingTasksPassed: day.data.pending_tasks_passed,
        };
    };

    static getDay = async (day_date: Date): Promise<undefined | Day> => {
        const day = await DayAPI.getDay(day_date);
        if (day === undefined || day.status === 400) {
            return undefined;
        }
        return DayAPIAdapter.fromDayFromAPItoDay(day);
    };

    static createDay = async (day_date: Date): Promise<Day> => {
        const day = await DayAPI.createDay(day_date);
        return DayAPIAdapter.fromDayFromAPItoDay(day);
    };

    static updateDay = async (
        day_date: Date,
        pending_tasks_passed: boolean,
        should_sort_day?: boolean
    ): Promise<undefined | Day> => {
        const day = await DayAPI.updateDay(
            day_date,
            pending_tasks_passed,
            should_sort_day
        );
        return DayAPIAdapter.fromDayFromAPItoDay(day);
    };

    static getDays = async (): Promise<Day[]> => {
        const days = await DayAPI.getDays();
        return days.data.map((day) =>
            DayAPIAdapter.fromDayFromAPItoDay({
                data: day,
                status: days.status,
            })
        );
    };
}
