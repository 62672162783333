import {
    CLOUDFRONT_DOMAIN,
    dateToDDMMYYString,
    differenceBetweenDatesInDays,
} from "../utils/utils";
import { AppData } from "../models/types";
import DatePicker from "react-datepicker";
import ca from "date-fns/locale/ca";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-GB";
import "../custom_styles/custom_datepicker.css";
import FormattedText from "../components/FormattedText";
import { FormattedMessage, useIntl } from "react-intl";
import Clickable from "../components/Clickable";
import { TEXT_COLOR } from "../utils";

export const Calendar = ({
    state,
    goToDate,
    setDiffWithToday,
}: {
    state: AppData;
    goToDate: (date: Date) => void;
    setDiffWithToday: (diff: number) => void;
}) => {
    const { locale } = useIntl();
    let calendarLocale: typeof en | typeof es | typeof ca = en;
    if (locale.includes("es")) {
        calendarLocale = es;
    } else if (locale.includes("ca")) {
        calendarLocale = ca;
    }
    const calendarIcon = `${CLOUDFRONT_DOMAIN}/calendar.png`;
    return (
        <DatePicker
            selected={state.currentDate}
            locale={calendarLocale}
            onChange={(date: Date | null) => {
                if (date) {
                    setDiffWithToday(
                        differenceBetweenDatesInDays(state.currentDate, date)
                    );
                    goToDate(date);
                }
            }}
            excludeScrollbar={true}
            onSelect={() => {
                // this is needed
            }}
            customInput={
                <Clickable onClick={() => {}}>
                    <img
                        src={calendarIcon}
                        alt="Click here to open the calendar"
                        style={{
                            width: "auto",
                            height: "auto",
                            maxWidth: "40px",
                            objectFit: "contain",
                            background: TEXT_COLOR,
                            borderRadius: "8px",
                        }}
                    />
                </Clickable>
            }
            dayClassName={(date: Date) => {
                const dateCasted = dateToDDMMYYString(date, 1);
                let doesDayExist =
                    state.agenda.days[dateCasted] &&
                    state.agenda.days[dateCasted].assignedTasks.length > 0;
                if (doesDayExist) {
                    const taskIds = state.agenda.days[
                        dateCasted
                    ].assignedTasks.map((task) => task.id);
                    doesDayExist = taskIds.some(
                        (taskId) => {
                            if (state.agenda.tasks[taskId] && state.agenda.tasks[taskId].text && state.agenda.tasks[taskId].text.length > 0) {
                                return true;
                            }
                            return false;
                        }
                    );
                }
                if (doesDayExist) {
                    return "highlighted-day";
                }
            }}
        >
            <FormattedText size="12px">
                <FormattedMessage
                    id="days_highlighted_explanation"
                    defaultMessage="Days with tasks are highlighted"
                />
            </FormattedText>
        </DatePicker>
    );
};
