import { v4 as uuidv4 } from "uuid";
import { InputState } from "./constants";
import { Task, AppData, TaskInDay } from "./types";
import { shouldSkipWeekends, throttledSaveAppData } from "./persistanceAPI";
import { dateToDDMMYYString } from "../utils/utils";
import { Agenda } from "./types";

export enum Actions {
    DeleteTask,
    UpdateTextForTask,
    PasteTextInTask,
    PassTaskToNextDay,
    DeleteTaskInNextDay,
    UpdateStateForTask,
    GoForwardOneDay,
    GoBackOneDay,
    GoToDate,
    GoToToday,
    LoadFirstDay,
    Sort,
    BulkAction,
}

const newEmptyTask = (tasksKeys: string[]) => {
    let id = uuidv4();
    while (id in tasksKeys) {
        id = uuidv4();
    }
    return {
        id,
        text: "",
    } as Task;
};

class ReducerMethods {
    /*
	    TODO:
	    hi haura problemes pk no estic comparant dates de DIA/MES/ANY, sino que tenen hores i segons i minuts i al fer el +1/-1 
	    doncs es descuadra i no funcionara res xD fix it!
    */
    static createTask = (
        state: AppData,
        index?: number,
        date?: Date,
        pushToLocalStack?: (id: string) => void,
        currentFilteredText?: string,
    ): AppData => {
        if (
            date !== undefined &&
            state.agenda.days[dateToDDMMYYString(date)] === undefined
        ) {
            return state;
        }

        const targetedDate = date ? date : state.currentDate;
        const newTask = newEmptyTask(Object.keys(state.agenda.tasks));
        
        if (currentFilteredText) {
            newTask.text = currentFilteredText;
        }

        const updatedAgenda = state.agenda;
        updatedAgenda.tasks[newTask.id] = newTask;
        const newTaskInDay = { id: newTask.id, state: InputState.EMPTY };
        const dateAsString = dateToDDMMYYString(targetedDate);
        if (index !== undefined) {
            updatedAgenda.days[dateAsString].assignedTasks = [
                ...updatedAgenda.days[dateAsString].assignedTasks.slice(
                    0,
                    index
                ),
                newTaskInDay,
                ...updatedAgenda.days[dateAsString].assignedTasks.slice(index),
            ];
        } else {
            updatedAgenda.days[dateAsString].assignedTasks.push(newTaskInDay);
        }
        updatedAgenda.days[dateAsString].pendingTasksPassed = false;

        const updatedState = {
            ...state,
            agenda: updatedAgenda,
        };
        pushToLocalStack && pushToLocalStack(newTask.id);
        return updatedState;
    };

    static deleteTask = (
        state: AppData,
        taskId?: string,
        pushToLocalStack?: (id: string) => void
    ): AppData => {
        if (taskId === undefined) {
            return state;
        }
        let updatedAgenda: Agenda = state.agenda;
        if (updatedAgenda.tasks[taskId] === undefined) {
            return state;
        }

        for (let date in updatedAgenda.days) {
            const day = updatedAgenda.days[date];
            day.assignedTasks = day.assignedTasks.filter(
                (task: TaskInDay) => task.id !== taskId
            );
            if (day.assignedTasks.length === 0) {
                updatedAgenda = ReducerMethods.createTask(
                    { ...state, agenda: updatedAgenda },
                    0,
                    day.date,
                    pushToLocalStack
                ).agenda;
            }
        }
        delete updatedAgenda.tasks[taskId];

        const updatedState = {
            ...state,
            agenda: updatedAgenda,
        };
        return updatedState;
    };

    static updateTextForTask = (
        state: AppData,
        text?: string,
        index?: number,
        pushToLocalStack?: (id: string) => void,
        currentFilteredText?: string,
    ): AppData => {
        if (text === undefined || index === undefined) {
            return state;
        }
        if (text[text.length - 1] === "\n") {
            /*
        TODO: feedback del Toni - no permetre crear tasca si l'anterior és buida
        if (text.length === 1) {
            return state;
        }*/
            return ReducerMethods.createTask(
                state,
                index !== undefined ? index + 1 : 0,
                undefined,
                pushToLocalStack,
                currentFilteredText,
            );
        }
        const taskId =
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks[index].id;
        if (taskId === undefined) {
            return { ...state };
        }
        const updatedState = { ...state };
        updatedState.agenda.tasks[taskId].text = text;
        updatedState.agenda.days[
            dateToDDMMYYString(state.currentDate)
        ].pendingTasksPassed = false;
        return updatedState;
    };

    static pasteTextInTask = (
        state: AppData,
        text?: string,
        index?: number,
        pasteIndex: number = 0
    ) => {
        if (text === undefined || index === undefined) {
            return { updatedState: state, tasksToDo: [], tasksDone: [] };
        }
        const taskId =
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks[index].id;
        if (taskId === undefined) {
            return { updatedState: state, tasksToDo: [], tasksDone: [] };
        }
        text = text.split("\n").join("");
        let newText = "";
        let tasksToDo: string[] = [];
        let tasksDone: string[] = [];

        const TO_DO_SEPARATOR = "- [ ]";
        const DONE_SEPARATOR = "- [x]";

        const firstToDoSeparator = text.indexOf(TO_DO_SEPARATOR);
        const firstDoneSeparator = text.indexOf(DONE_SEPARATOR);
        let firstSeparator = -1;
        if (
            (firstDoneSeparator < firstToDoSeparator ||
                firstToDoSeparator === -1) &&
            firstDoneSeparator !== -1
        ) {
            firstSeparator = firstDoneSeparator;
        }
        if (
            (firstDoneSeparator > firstToDoSeparator ||
                firstDoneSeparator === -1) &&
            firstToDoSeparator !== -1
        ) {
            firstSeparator = firstToDoSeparator;
        }

        if (firstSeparator === -1) {
            return {
                updatedState: ReducerMethods.updateTextForTask(
                    state,
                    state.agenda.tasks[taskId].text.slice(0, pasteIndex) +
                        text +
                        state.agenda.tasks[taskId].text.slice(pasteIndex),

                    index
                ),
                tasksToDo: [],
                tasksDone: [],
            };
        }
        if (firstSeparator > 0) {
            newText = text.substring(0, firstSeparator);
        }
        let isToDo =
            text.substring(firstSeparator, firstSeparator + 5) ===
            TO_DO_SEPARATOR;
        let secondSeparator = firstSeparator;

        const addTaskToBeProcessed = (taskText: string) => {
            if (isToDo) {
                tasksToDo.push(taskText.substring(TO_DO_SEPARATOR.length));
            } else {
                tasksDone.push(taskText.substring(DONE_SEPARATOR.length));
            }
        };
        while (secondSeparator < text.length) {
            if (
                firstSeparator !== secondSeparator &&
                text[secondSeparator] === "-"
            ) {
                if (
                    text.substring(secondSeparator, secondSeparator + 5) ===
                    TO_DO_SEPARATOR
                ) {
                    addTaskToBeProcessed(
                        text.substring(firstSeparator, secondSeparator)
                    );
                    isToDo = true;
                    firstSeparator = secondSeparator;
                } else if (
                    text.substring(secondSeparator, secondSeparator + 5) ===
                    DONE_SEPARATOR
                ) {
                    addTaskToBeProcessed(
                        text.substring(firstSeparator, secondSeparator)
                    );
                    isToDo = false;
                    firstSeparator = secondSeparator;
                }
            }
            secondSeparator = secondSeparator + 1;
        }
        //need to add the last one here
        addTaskToBeProcessed(text.substring(firstSeparator));

        let updatedState = { ...state };
        updatedState.agenda.tasks[taskId].text =
            updatedState.agenda.tasks[taskId].text.slice(0, pasteIndex) +
            newText +
            updatedState.agenda.tasks[taskId].text.slice(pasteIndex);
        updatedState.agenda.days[
            dateToDDMMYYString(state.currentDate)
        ].pendingTasksPassed = false;
        let newTaskIndex = 1;

        for (const taskText of tasksToDo) {
            updatedState = ReducerMethods.createTask(
                updatedState,
                index + newTaskIndex,
                state.currentDate
            );

            updatedState = ReducerMethods.updateTextForTask(
                updatedState,
                taskText,
                index + newTaskIndex
            );
            newTaskIndex = newTaskIndex + 1;
        }
        for (const taskText of tasksDone) {
            updatedState = ReducerMethods.createTask(
                updatedState,
                index + newTaskIndex,
                state.currentDate
            );

            updatedState = ReducerMethods.updateTextForTask(
                updatedState,
                taskText,
                index + newTaskIndex
            );
            updatedState = ReducerMethods.updateStateForTask(
                updatedState,
                InputState.DONE,
                index + newTaskIndex
            );
            newTaskIndex = newTaskIndex + 1;
        }
        return { updatedState, tasksToDo, tasksDone };
    };

    static passTaskToNextDay = (state: AppData, index?: number): AppData => {
        /* 
	NOTA: aixo nomes serveix per a passar una tasca dun dia al seguent, pero aqui no es fa el calcul de l'estat (DONE -> NEXT_DAY)
	*/
        if (index === undefined) {
            return state;
        }
        const date = new Date(state.currentDate);
        if (date.getDay() === 5 && shouldSkipWeekends()) {
            date.setDate(date.getDate() + 3);
        } else if (date.getDay() === 6 && shouldSkipWeekends()) {
            date.setDate(date.getDate() + 2);
        } else {
            date.setDate(date.getDate() + 1);
        }

        const tomorrowDate = dateToDDMMYYString(date);
        const taskId =
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks[index].id;

        if (state.agenda.days[tomorrowDate] === undefined) {
            state.agenda.days[tomorrowDate] = {
                date,
                assignedTasks: [{ id: taskId, state: InputState.EMPTY }],
                pendingTasksPassed: false,
            };
        } else {
            state.agenda.days[tomorrowDate].assignedTasks.push({
                id: taskId,
                state: InputState.EMPTY,
            });
        }
        return { ...state };
    };

    static deleteTaskInNextDay = (state: AppData, index?: number): AppData => {
        if (index === undefined) {
            return state;
        }
        const date = new Date(state.currentDate);
        date.setDate(date.getDate() + 1);
        
        const taskId =
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks[index].id;

        const deleteTaskRecursively = (state: AppData, taskId: string, date: Date ) => {
            const tomorrowDate = dateToDDMMYYString(date);
            if (state.agenda.days[tomorrowDate] !== undefined) {
                const pastNumberOfTasks = state.agenda.days[tomorrowDate].assignedTasks.length;
                state.agenda.days[tomorrowDate].assignedTasks = state.agenda.days[
                    tomorrowDate
                ].assignedTasks.filter((task) => task.id !== taskId);
                const newNumberOfTasks = state.agenda.days[tomorrowDate].assignedTasks.length;
                if (newNumberOfTasks < pastNumberOfTasks) {
                    date.setDate(date.getDate() + 1);
                    deleteTaskRecursively(state, taskId, date);
                }
            }
        }
        
        deleteTaskRecursively(state, taskId, date);
        
        return { ...state };
    };

    static updateStateForTask = (
        state: AppData,
        newTaskState?: InputState,
        index?: number
    ): AppData => {
        if (
            state === undefined ||
            index === undefined ||
            newTaskState === undefined ||
            index < 0 ||
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks.length -
                1 <
                index
        ) {
            return state;
        }
        state.agenda.days[dateToDDMMYYString(state.currentDate)].assignedTasks[
            index
        ].state = newTaskState;
        return { ...state };
    };

    static goForwardOneDay = (state: AppData): AppData => {
        const date = new Date(state.currentDate);
        date.setDate(date.getDate() + 1);
        const cleanedTomorrowDate = dateToDDMMYYString(date);
        state.currentDate = date;
        if (state.agenda.days[cleanedTomorrowDate] === undefined) {
            state.agenda.days[cleanedTomorrowDate] = {
                date,
                assignedTasks: [],
                pendingTasksPassed: false,
            };
            ReducerMethods.createTask(state);
        } else if (
            state.agenda.days[cleanedTomorrowDate].assignedTasks.length === 0
        ) {
            ReducerMethods.createTask(state);
        } else if (
            state.agenda.days[cleanedTomorrowDate].assignedTasks.length === 1
        ) {
            if (
                !state.agenda.tasks[
                    state.agenda.days[cleanedTomorrowDate].assignedTasks[0].id
                ]
            ) {
                state.agenda.tasks[
                    state.agenda.days[cleanedTomorrowDate].assignedTasks[0].id
                ] = {
                    id: state.agenda.days[cleanedTomorrowDate].assignedTasks[0]
                        .id,
                    text: "",
                };
            }
        }
        return { ...state };
    };

    static goToDate = (state: AppData, date: Date): AppData => {
        state.currentDate = date;
        const cleanedDate = dateToDDMMYYString(date);
        if (state.agenda.days[cleanedDate] === undefined) {
            state.agenda.days[cleanedDate] = {
                date,
                assignedTasks: [],
                pendingTasksPassed: false,
            };
            ReducerMethods.createTask(state);
        } else if (state.agenda.days[cleanedDate].assignedTasks.length === 0) {
            ReducerMethods.createTask(state);
        } else if (state.agenda.days[cleanedDate].assignedTasks.length === 1) {
            if (
                !state.agenda.tasks[
                    state.agenda.days[cleanedDate].assignedTasks[0].id
                ]
            ) {
                state.agenda.tasks[
                    state.agenda.days[cleanedDate].assignedTasks[0].id
                ] = {
                    id: state.agenda.days[cleanedDate].assignedTasks[0].id,
                    text: "",
                };
            }
        }
        return { ...state };
    };

    static goToToday = (state: AppData): AppData => {
        const date = new Date();
        state.currentDate = date;
        const cleanedTodaysDate = dateToDDMMYYString(date);
        if (state.agenda.days[cleanedTodaysDate] === undefined) {
            // this should never be executed if we always start on today
            state.agenda.days[cleanedTodaysDate] = {
                date,
                assignedTasks: [],
                pendingTasksPassed: false,
            };
            ReducerMethods.createTask(state);
        }
        return { ...state };
    };

    static goBackOneDay = (state: AppData): AppData => {
        if (state.currentDate === undefined) {
            return state;
        }
        const date = new Date(state.currentDate);
        date.setDate(date.getDate() - 1);
        state.currentDate = date;

        const cleanedYesterdayDate = dateToDDMMYYString(date);
        if (state.agenda.days[cleanedYesterdayDate] === undefined) {
            state.agenda.days[cleanedYesterdayDate] = {
                date,
                assignedTasks: [],
                pendingTasksPassed: false,
            };
            ReducerMethods.createTask(state);
        } else if (
            state.agenda.days[cleanedYesterdayDate].assignedTasks.length === 0
        ) {
            ReducerMethods.createTask(state);
        } else if (
            state.agenda.days[cleanedYesterdayDate].assignedTasks.length === 1
        ) {
            if (
                !state.agenda.tasks[
                    state.agenda.days[cleanedYesterdayDate].assignedTasks[0].id
                ]
            ) {
                state.agenda.tasks[
                    state.agenda.days[cleanedYesterdayDate].assignedTasks[0].id
                ] = {
                    id: state.agenda.days[cleanedYesterdayDate].assignedTasks[0]
                        .id,
                    text: "",
                };
            }
        }
        return { ...state };
    };

    static loadFirstDay = (state: AppData): AppData => {
        for (const day in state.agenda.days) {
            state.agenda.days[day].pendingTasksPassed = false;
        }

        // calcula quina és l'última data amb tasques de passar al següent dia
        const date = new Date(state.currentDate.getTime());
        let lastProcessedDate = new Date(date.getTime());
        while (
            dateToDDMMYYString(lastProcessedDate) in state.agenda.days &&
            !state.agenda.days[dateToDDMMYYString(lastProcessedDate)]
                .pendingTasksPassed
        ) {
            lastProcessedDate = new Date(
                lastProcessedDate.setDate(lastProcessedDate.getDate() - 1)
            );
        }
        let checkedDate = lastProcessedDate;

        while (checkedDate < date) {
            if (
                dateToDDMMYYString(checkedDate) in state.agenda.days &&
                !state.agenda.days[dateToDDMMYYString(checkedDate)]
                    .pendingTasksPassed
            ) {
                state.currentDate = checkedDate;
                const unfinishedTasks = state.agenda.days[
                    dateToDDMMYYString(checkedDate)
                ].assignedTasks
                    .map((value, index) => {
                        return { ...value, index };
                    })
                    .filter(
                        (task: TaskInDay) => task.state === InputState.EMPTY
                    );
                for (let { index } of unfinishedTasks) {
                    if (
                        state.agenda.tasks[
                            state.agenda.days[dateToDDMMYYString(checkedDate)]
                                .assignedTasks[index].id
                        ].text === ""
                    ) {
                        continue;
                    }
                    state = ReducerMethods.passTaskToNextDay(state, index);
                    state = ReducerMethods.updateStateForTask(
                        state,
                        InputState.NEXT_DAY,
                        index
                    );
                }
                state.agenda.days[
                    dateToDDMMYYString(checkedDate)
                ].pendingTasksPassed = true;
            }
            checkedDate = new Date(
                lastProcessedDate.setDate(lastProcessedDate.getDate() + 1)
            );
        }
        state.currentDate = date;

        //make sure tasks on each day are unique by ID
        // this is a temporal patch because in the following scenario
        // tasks were being duplicated and triplicated
        // SCENARIO:
        // use owl, different days
        // let tasks pass to the present
        // then, modify a past day => BREAK
        for (const dayDate in state.agenda.days) {
            let seenIds = new Set();
            let cleanedTasks = [];
            for (const task of state.agenda.days[dayDate].assignedTasks) {
                if (!seenIds.has(task.id)) {
                    cleanedTasks.push(task);
                    seenIds.add(task.id);
                }
            }
            state.agenda.days[dayDate].assignedTasks = cleanedTasks;
        }
        return { ...state };
    };

    static sort = (state: AppData): AppData => {
        function compare(a: TaskInDay, b: TaskInDay) {
            if (a.state > b.state) {
                return 1;
            }
            if (a.state < b.state) {
                return -1;
            }
            const aLength = state.agenda.tasks[a.id].text.length;
            const bLength = state.agenda.tasks[b.id].text.length;
            if (aLength > bLength) {
                return 1;
            }
            if (aLength < bLength) {
                return -1;
            }
            return 0;
        }
        const updatedState = { ...state };
        updatedState.agenda.days[
            dateToDDMMYYString(state.currentDate)
        ].assignedTasks =
            updatedState.agenda.days[
                dateToDDMMYYString(state.currentDate)
            ].assignedTasks.sort(compare);

        return updatedState;
    };
}

const reducer = (
    state: AppData,
    action: {
        index?: number;
        pasteIndex?: number;
        id?: string;
        bulkIds?: string[];
        text?: string;
        inputState?: InputState;
        date?: Date;
        isPremium?: boolean;
        type: Actions;
        idSync: {
            pushToRemoteStack: (id: string) => void;
            findWaitingValueAndSubstitute: (
                waitingValue: string,
                newId: string
            ) => void;
            pushToLocalStack: (id: string) => void;
            getRemoteIDFromLocalID: (localId: string) => string | undefined;
        };
        overrideState?: AppData;
        currentFilteredText?: string;
    }
): AppData => {
    let newState: AppData | undefined = undefined;
    switch (action.type) {
        case Actions.DeleteTask:
            newState = ReducerMethods.deleteTask(state, action.id);
            break;
        case Actions.UpdateTextForTask:
            newState = ReducerMethods.updateTextForTask(
                state,
                action.text,
                action.index
            );
            break;
        case Actions.PasteTextInTask:
            newState = ReducerMethods.pasteTextInTask(
                state,
                action.text,
                action.index,
                action.pasteIndex
            ).updatedState;
            break;
        case Actions.PassTaskToNextDay:
            newState = ReducerMethods.passTaskToNextDay(state, action.index);
            break;
        case Actions.DeleteTaskInNextDay:
            newState = ReducerMethods.deleteTaskInNextDay(state, action.index);
            break;
        case Actions.UpdateStateForTask:
            newState = ReducerMethods.updateStateForTask(
                state,
                action.inputState,
                action.index
            );
            break;
        case Actions.GoForwardOneDay:
            newState = ReducerMethods.goForwardOneDay(state);
            break;
        case Actions.GoBackOneDay:
            newState = ReducerMethods.goBackOneDay(state);
            break;
        case Actions.LoadFirstDay:
            newState = ReducerMethods.loadFirstDay(state);
            break;
        case Actions.GoToToday:
            newState = ReducerMethods.goToToday(state);
            break;
        case Actions.GoToDate:
            if (action.date) {
                newState = ReducerMethods.goToDate(state, action.date);
            } else {
                newState = state;
            }
            break;
        case Actions.Sort:
            newState = ReducerMethods.sort(state);
            break;
        case Actions.BulkAction:
            // TODO: implement if it´s too slow the way it is now
            newState = state;
            break;
        default:
            throw Error("no valid action specified!");
    }
    if (!action.isPremium) {
        throttledSaveAppData(newState);
    }
    return newState;
};

export default reducer;
export { newEmptyTask, ReducerMethods };
