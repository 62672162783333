import React from "react";

type GlobalScreenSizeContextType = {
    isSmall: boolean;
    isMedium: boolean;
    isLarge: boolean;
    isXL: boolean;
    width: number;
    height: number;
};

const ScreenSizeContext = React.createContext<GlobalScreenSizeContextType>({
    isSmall: false,
    isMedium: false,
    isLarge: false,
    isXL: false,
    width: 0,
    height: 0,
});

export default ScreenSizeContext;
