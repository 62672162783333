import { useState, useEffect } from "react";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

const SMALL_BREAKPOINT = 500;
const MEDIUM_BREAKPOINT = 900;
const LARGE_BREAKPOINT = 1200;

export function useScreenSize() {
    const { width, height } = useWindowDimensions();
    const isSmall = width < SMALL_BREAKPOINT;
    const isMedium = width < MEDIUM_BREAKPOINT && !isSmall;
    const isLarge = width < LARGE_BREAKPOINT && !isMedium && !isSmall;
    const isXL = width > LARGE_BREAKPOINT && !isLarge && !isMedium && !isSmall;
    return {
        isSmall,
        isMedium,
        isLarge,
        isXL,
        width,
        height,
    };
}
