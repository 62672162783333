import React, { useContext, useEffect, useState } from "react";
import Flex from "../components/Flex";
import BigHeader from "../components/BigHeader";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import {
    isUserLoggedIn,
    isUserPreferringNoSignUp,
    storePreferNoSignup,
} from "../models/persistanceAPI";
import { Navigate, useNavigate } from "react-router-dom";
import {
    CLOUDFRONT_DOMAIN,
    CONTRAST_COLOR,
    DARK_TEXT_COLOR,
    FIRST_LAYER_COLOR,
    TEXT_COLOR,
    TOP_LAYER_COLOR,
} from "../utils";
import { Footer } from "./Footer";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import { Slide, Fade } from "react-awesome-reveal";
import { StyledRoundButton } from "../components/StyledRoundButton";
import FormattedText from "../components/FormattedText";
import { FreeFeatures, PremiumFeatures } from "../components/FeaturesContainer";
import { Helmet } from "react-helmet";

const LogInContainer = styled.div<{ isSmall?: boolean }>`
    width: 100%;
    height: 100%;
    padding: ${(props) =>
        props.isSmall ? "20px 0px 10px 0px" : "20px 40px 10px 40px"};
`;

const GifContainer = styled.div`
    height: fit-content;
    position: relative;
`;

const GifTag = styled.div`
    float: left;
    position: absolute;
    left: 8px;
    bottom: 10px;
    z-index: 1000;
    border-radius: 8px;
    background-color: ${DARK_TEXT_COLOR};
    padding: 6px;
    color: white;
    font-weight: bold;
`;

const LogInSheet = styled.div`
    background: ${FIRST_LAYER_COLOR};
    height: auto;
    border-radius: 8px;
    padding: 40px;
    height: inherit;
`;

const BigText = styled.p<{ color?: string }>`
    font-size: 20px;
    text-align: justify;
    color: ${(props) => props.color ?? TEXT_COLOR};
`;

const UnstyledListItem = styled.li`
    list-style: none;
`;
export const OwlDescription = ({
    setLocalUsage,
}: {
    setLocalUsage?: () => void;
}) => {
    const navigate = useNavigate();
    const { isSmall, isXL } = useContext(ScreenSizeContext);
    const { locale } = useIntl();

    const gifCatalan = `${CLOUDFRONT_DOMAIN}/presentacio_ca.gif`;
    const gifEnglish = `${CLOUDFRONT_DOMAIN}/presentacio_en.gif`;
    const gifSpanish = `${CLOUDFRONT_DOMAIN}/presentacio_es.gif`;
    const getPresentationGif = () => {
        switch (locale.substring(0, 2)) {
            case "ca":
                return gifCatalan;
            case "es":
                return gifSpanish;
            default:
                return gifEnglish;
        }
    };
    const logo = `${CLOUDFRONT_DOMAIN}/owl.png`;
    const isHomePage = !!setLocalUsage;
    return (
        <>
            <Flex justifyContent="center">
                <LogInContainer isSmall={isSmall}>
                    <Spacer left={isSmall ? 5 : 0} right={isSmall ? 5 : 0}>
                        {!isHomePage && (
                            <>
                                <Clickable onClick={() => navigate(-1)}>
                                    <Flex>
                                        <img
                                            src={ArrowLeft}
                                            alt="Click here to go to the previous page"
                                        />
                                        <Spacer right={1} />
                                        <FormattedMessage
                                            id="back"
                                            defaultMessage="Go back"
                                        />
                                    </Flex>
                                </Clickable>
                                <Spacer top={4} />
                            </>
                        )}
                        <Slide>
                            <Flex direction="column">
                                <Flex alignItems="center">
                                    <img
                                        src={logo}
                                        alt="Wise owl logo"
                                        style={{
                                            width: "48px",
                                            height: "48px",
                                            objectFit: "contain",
                                            padding: "8px",
                                            background: TEXT_COLOR,
                                            borderRadius: "8px",
                                        }}
                                    />
                                    <Spacer right={2} />
                                    <BigHeader>wise owl</BigHeader>
                                </Flex>
                                <Spacer top={isSmall ? 3 : 4} />
                                <Flex maxWidth="1100px">
                                    <Header
                                        fontWeight="bold"
                                        fontSize={isSmall ? "36px" : "60px"}
                                    >
                                        <FormattedMessage
                                            id="log_in_subheader"
                                            defaultMessage="Write down your work. Avoid feeling overwhelmed by calendarizing your tasks."
                                        />
                                    </Header>
                                </Flex>
                            </Flex>
                        </Slide>
                        {isHomePage && (
                            <>
                                <Spacer top={isSmall ? 2 : 4} />
                                <Flex direction={isSmall ? "column" : "row"}>
                                    <StyledRoundButton
                                        onClick={() => navigate("/login")}
                                    >
                                        <Spacer
                                            top={2.5}
                                            bottom={2.5}
                                            left={2.5}
                                            right={2.5}
                                        >
                                            <FormattedText
                                                weight="bold"
                                                color={CONTRAST_COLOR}
                                                size="28px"
                                                lineHeight="28px"
                                            >
                                                <FormattedMessage
                                                    id="logIn"
                                                    defaultMessage="Log in"
                                                />
                                            </FormattedText>
                                        </Spacer>
                                    </StyledRoundButton>
                                    <Spacer right={2} top={isSmall ? 2 : 0} />
                                    <StyledRoundButton onClick={setLocalUsage}>
                                        <Spacer
                                            top={2.5}
                                            bottom={2.5}
                                            left={2.5}
                                            right={2.5}
                                        >
                                            <FormattedText
                                                weight="300"
                                                size="28px"
                                                lineHeight="28px"
                                            >
                                                <FormattedMessage
                                                    id="start_without_credentials"
                                                    defaultMessage="Try it without signing up"
                                                />
                                            </FormattedText>
                                        </Spacer>
                                    </StyledRoundButton>
                                </Flex>
                            </>
                        )}
                    </Spacer>
                    <Spacer top={6} />

                    <LogInSheet>
                        <Flex direction={isXL ? "row" : "column"}>
                            <Flex>
                                <Spacer top={2} />
                                <GifContainer>
                                    <GifTag>GIF</GifTag>
                                    <img
                                        width={1009}
                                        height={610}
                                        src={getPresentationGif()}
                                        alt="Wise owl demo GIF. See everything you can do on Wise Owl, the best To-Do list to avoid feeling overwhelmed."
                                        style={{
                                            border: "2px solid black",
                                            borderRadius: "8px",
                                            width: "auto",
                                            height: "auto",
                                            maxWidth: "100%",
                                            objectFit: "contain",
                                            background: TOP_LAYER_COLOR,
                                        }}
                                    />
                                </GifContainer>
                            </Flex>
                            <Spacer right={6} top={isXL ? 0 : 4} />
                            <Flex direction="column">
                                <Header fontSize={isXL ? "48px" : undefined}>
                                    <FormattedMessage
                                        id="log_in_header_description"
                                        defaultMessage="You have a thousand tasks to do, big and small, every day. We can help with that."
                                    />
                                </Header>
                                <Spacer top={2} />
                                <FormattedText size="24px" weight="300">
                                    <FormattedMessage
                                        id="log_in_description_simple"
                                        defaultMessage="Simple, yet effective: Wise Owl is an amazing way to reduce the bureaucracy of your work."
                                    />
                                </FormattedText>
                                <Spacer top={isXL ? 4 : 2} />
                                <FormattedText size={isXL ? "20px" : undefined}>
                                    <FormattedMessage
                                        id="log_in_description_4"
                                        defaultMessage="Easily see what you need to get done today, what you did yesterday, and what you can leave for tomorrow. These are some key features:"
                                    />
                                </FormattedText>

                                <Spacer top={isXL ? 3 : 1} />
                                <Flex direction="column">
                                    <UnstyledListItem>
                                        <Flex alignItems="center">
                                            <BigText>1</BigText>
                                            <Spacer right={2} />
                                            <FormattedText
                                                size={isXL ? "20px" : undefined}
                                            >
                                                <FormattedMessage
                                                    id="log_in_description_3_list_1"
                                                    defaultMessage="Use an intuitive interface to manage and write down your tasks, day by day."
                                                />
                                            </FormattedText>
                                        </Flex>
                                    </UnstyledListItem>
                                    <Spacer top={isXL ? 2 : 1} />
                                    <UnstyledListItem>
                                        <Flex alignItems="center">
                                            <BigText>2</BigText>
                                            <Spacer right={2} />
                                            <FormattedText
                                                size={isXL ? "20px" : undefined}
                                            >
                                                <FormattedMessage
                                                    id="log_in_description_3_list_2"
                                                    defaultMessage="Use an intuitive interface to manage and write down your tasks, day by day."
                                                />
                                            </FormattedText>
                                        </Flex>
                                    </UnstyledListItem>
                                    <Spacer top={isXL ? 2 : 1} />
                                    <UnstyledListItem>
                                        <Flex alignItems="center">
                                            <BigText>3</BigText>
                                            <Spacer right={2} />
                                            <FormattedText
                                                size={isXL ? "20px" : undefined}
                                            >
                                                <FormattedMessage
                                                    id="log_in_description_3_list_3"
                                                    defaultMessage="Use an intuitive interface to manage and write down your tasks, day by day."
                                                />
                                            </FormattedText>
                                        </Flex>
                                    </UnstyledListItem>
                                    <Spacer top={isXL ? 2 : 1} />
                                    <FormattedText
                                        size={isXL ? "20px" : undefined}
                                    >
                                        <FormattedMessage
                                            id="log_in_description_other"
                                            defaultMessage="And many more features to help you organize your work and life!"
                                        />
                                    </FormattedText>
                                </Flex>
                            </Flex>
                        </Flex>
                    </LogInSheet>
                </LogInContainer>
            </Flex>
            {!isHomePage && (
                <>
                    <Spacer top={5} />
                    <Footer
                        isSmall={isSmall}
                        skipMoreInfoSection={true}
                        paddingTop={2.5}
                        paddingRight={isSmall ? 1 : 6}
                        paddingBot={2.5}
                        paddingLeft={isSmall ? 1 : 6}
                        isPremium={false}
                    />
                </>
            )}
        </>
    );
};

export const HomePage = () => {
    const [shouldNavigateToApp, setShouldNavigateToApp] = useState<
        boolean | string
    >(false);
    const { isSmall, isMedium } = useContext(ScreenSizeContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (isUserPreferringNoSignUp()) {
            setShouldNavigateToApp("local");
        }
        if (isUserLoggedIn()) {
            setShouldNavigateToApp("premium");
        }
    }, []);

    const setLocalUsage = () => {
        storePreferNoSignup();
        navigate("/local");
    };

    if (shouldNavigateToApp === "local") {
        return <Navigate to="/local" />;
    }
    if (shouldNavigateToApp === "premium") {
        return <Navigate to="/premium" />;
    }

    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://www.wiseowl.cat"} />
            </Helmet>
            <OwlDescription setLocalUsage={setLocalUsage} />
            <Flex justifyContent="center">
                <LogInContainer isSmall={isSmall}>
                    <LogInSheet>
                        <Flex
                            direction={
                                isMedium || isSmall
                                    ? "column-reverse"
                                    : "row-reverse"
                            }
                        >
                            <Flex
                                direction="column"
                                grow={1}
                                justifyContent="space-between"
                                height="auto"
                            >
                                <div>
                                    <Header fontWeight="300">
                                        <FormattedMessage
                                            id="no_login"
                                            defaultMessage="Use owl without credentials"
                                        />
                                    </Header>
                                    <Spacer top={1} />
                                    <BigText>
                                        <FormattedMessage
                                            id="log_in_description_1"
                                            defaultMessage="You can use Wise Owl without signing up and for free. All your data is stored in your computer and never sent to the cloud."
                                        />
                                    </BigText>
                                </div>
                                <Spacer top={2} />
                                <FreeFeatures />
                                <Spacer top={3} />
                                <StyledRoundButton onClick={setLocalUsage}>
                                    <Spacer
                                        top={2}
                                        bottom={2}
                                        left={2}
                                        right={2}
                                    >
                                        <FormattedText weight="300" size="22px">
                                            <FormattedMessage
                                                id="start_without_credentials"
                                                defaultMessage="Start without signing up"
                                            />
                                        </FormattedText>
                                    </Spacer>
                                </StyledRoundButton>
                            </Flex>
                            <Spacer
                                right={isMedium || isSmall ? 0 : 6}
                                top={isMedium || isSmall ? 6 : 0}
                            />
                            <Flex direction="column" grow={1}>
                                <Header fontWeight="300">
                                    <FormattedMessage
                                        id="sign_up"
                                        defaultMessage="Sign up"
                                    />
                                </Header>
                                <Spacer top={1} />
                                <BigText>
                                    <FormattedMessage
                                        id="sign_up_description"
                                        defaultMessage="Sign up to use Wise Owl on multiple devices. Registered users will enjoy more features too!"
                                    />
                                </BigText>
                                <Spacer top={2} />
                                <PremiumFeatures />
                                <Spacer top={3} />
                                <Flex direction="column">
                                    <Fade delay={250}>
                                        <StyledRoundButton
                                            onClick={() => {
                                                navigate("/signup");
                                            }}
                                            borderColor={CONTRAST_COLOR}
                                        >
                                            <Spacer
                                                top={2}
                                                bottom={2}
                                                left={2}
                                                right={2}
                                            >
                                                <FormattedText
                                                    weight="bold"
                                                    color={CONTRAST_COLOR}
                                                    size="22px"
                                                >
                                                    <FormattedMessage
                                                        id="sign_up_button"
                                                        defaultMessage="Sign up"
                                                    />
                                                </FormattedText>
                                            </Spacer>
                                        </StyledRoundButton>
                                    </Fade>
                                </Flex>
                            </Flex>
                        </Flex>
                    </LogInSheet>
                </LogInContainer>
            </Flex>
            <Spacer top={5} />
            <Footer
                isSmall={isSmall}
                skipMoreInfoSection={true}
                paddingTop={2.5}
                paddingRight={isSmall ? 1 : 6}
                paddingBot={2.5}
                paddingLeft={isSmall ? 1 : 6}
                isPremium={false}
            />
        </>
    );
};
