import styled from "styled-components";

const BigHeader = styled.h1<{
    color?: string;
}>`
    font-family: "Inter", Helvetica;
    font-weight: bold;
    font-size: 70px;
    color: ${(props) => (props.color ? props.color : "")};
`;

export default BigHeader;
