import { FormattedMessage } from "react-intl";
import BottomModal from "../components/BottomModal";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import Flex from "../components/Flex";
import { StyledRoundButton } from "../components/StyledRoundButton";
import { useNavigate } from "react-router-dom";
import FormattedText from "../components/FormattedText";
import { INPUT_DONE } from "../utils";
import { useContext } from "react";
import ScreenSizeContext from "../contexts/ScreenSizeContext";

export const PremiumUpsellBottomModal = ({
    closePremiumUpsell,
}: {
    closePremiumUpsell: () => void;
}) => {
    const navigate = useNavigate();
    const { isSmall } = useContext(ScreenSizeContext);
    return (
        <BottomModal>
            <Header>
                <FormattedMessage
                    id="premiumUpsellHeader"
                    defaultMessage="Register to unblock all the features of Wise Owl"
                />
            </Header>
            <Spacer bottom={1.5} />
            <FormattedMessage
                id="premiumUpsellText"
                defaultMessage="Get access to all the features of Wise Owl by registering for an account. You will be able to create unlimited tasks, sync them between your devices, store your tasks safely on the cloud, and more to come!"
            />
            <Spacer bottom={1.5} />
            <Flex direction="row" justifyContent={isSmall ? undefined : "end"}>
                <StyledRoundButton onClick={closePremiumUpsell}>
                    <Spacer top={2} bottom={2} left={2} right={2}>
                        <FormattedText weight="300" size="18px">
                            <FormattedMessage
                                id="closePremiumUpsell"
                                defaultMessage="No, thanks"
                            />
                        </FormattedText>
                    </Spacer>
                </StyledRoundButton>
                <Spacer right={3} />
                <StyledRoundButton
                    onClick={() => {
                        navigate("/signup");
                    }}
                    borderColor={INPUT_DONE}
                >
                    <Spacer top={2} bottom={2} left={2} right={2}>
                        <FormattedText
                            weight="bold"
                            color={INPUT_DONE}
                            size="18px"
                        >
                            <FormattedMessage
                                id="acceptPremium"
                                defaultMessage="Register now"
                            />
                        </FormattedText>
                    </Spacer>
                </StyledRoundButton>
            </Flex>
        </BottomModal>
    );
};
