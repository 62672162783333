import React from "react";
import styled, { keyframes } from "styled-components";
import Flex from "./Flex";
import { TOP_LAYER_COLOR } from "../utils";

const topToBottom = (deltaY?: number) => {
    return keyframes`
    0% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
      transform: translateY(-${deltaY ?? 300}px);
    }
  `;
};

const AnimatedDivAppearFromBottom = styled.div<{ deltaY?: number }>`
    background: ${TOP_LAYER_COLOR};
    padding: 40px;
    opacity: 0;
    border-radius: 8px;
    width: 75%;
    max-width: 900px;
    animation: ${(props) =>
            props.deltaY ? topToBottom(props.deltaY) : topToBottom()}
        1s forwards;
`;

const BottomModalContainer = styled.div`
    position: absolute;
`;

const BottomModal = ({
    children,
    deltaY,
}: {
    children: React.ReactNode[] | React.ReactNode;
    deltaY?: number;
}) => {
    return (
        <BottomModalContainer>
            <Flex
                alignItems="center"
                justifyContent="center"
                width="100vw"
                grow={1}
            >
                <AnimatedDivAppearFromBottom deltaY={deltaY}>
                    {children}
                </AnimatedDivAppearFromBottom>
            </Flex>
        </BottomModalContainer>
    );
};

export default BottomModal;
