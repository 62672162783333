import { FormattedMessage } from "react-intl";
import Flex from "./Flex";
import Header from "./Header";
import { Modal } from "./Modal";
import Spacer from "./Spacer";
import BlackCross from "../images/blackCross.svg";
import { InputText } from "./InputText";
import { useString } from "../hooks/useString";
import { ErrorText } from "./ErrorText";
import { useContext, useState } from "react";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { StyledRoundButton } from "./StyledRoundButton";
import FormattedText from "./FormattedText";
import { Divider } from "./Divider";
import { ERROR_TEXT_COLOR, FIRST_LAYER_COLOR } from "../utils";
import CompleteArrowRight from "../images/completeArrowRight.svg";
import { AuthorizationAPI, PaymentAPI, UserAPI } from "../api/api";
import { ImageIcon } from "./ImageIcon";
import { clearAllData } from "../models/persistanceAPI";
import UserContext from "../contexts/UserContext";
import { FormattedDate } from "react-intl";
import { invalidateTokenSingleton } from "../api/invalidateTokenSingleton";

export const UserConfigurationModal = ({
    closeModal,
}: {
    closeModal: () => void;
}) => {
    const { isSmall } = useContext(ScreenSizeContext);

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [showSubscriptionStopped, setShowSubscriptionStopped] =
        useState(false);
    const [showDeleteAccountUI, setShowDeleteAccountUI] = useState(false);
    const { user } = useContext(UserContext);
    const userInfo = user;
    const passwordsMustMatchError = useString("passwordsMustMatch");
    const passwordTooShortError = useString("passwordTooShort");
    const placeholderPassword = useString("placeholderPassword");
    const placeholderRepeatPassword = useString("placeholderRepeatPassword");

    const doPasswordsNotMatch =
        password !== password2 && !!password && !!password2;
    const isPasswordTooShort = !!password && password.length < 8;
    return (
        <Modal onClickOutside={closeModal}>
            <Flex style={{ width: "100%" }} direction="column">
                <Flex justifyContent="space-between">
                    <Header>
                        <FormattedMessage
                            id="userConfiguration"
                            defaultMessage="User configuration"
                        />
                    </Header>
                    <StyledRoundButton
                        onClick={closeModal}
                        customColor={FIRST_LAYER_COLOR}
                    >
                        <Flex>
                            <ImageIcon src={BlackCross} alt="Close" />
                        </Flex>
                    </StyledRoundButton>
                </Flex>
                <Spacer top={2} />
                {userInfo && (
                    <FormattedText weight="300" size="20px">
                        {userInfo.email}
                    </FormattedText>
                )}
                <Spacer top={2} />
                <Flex direction="column">
                    <form>
                        <InputText
                            turnOffAutocomplete
                            placeholder={placeholderPassword}
                            id="password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            width={isSmall ? "300px" : undefined}
                        />
                        {isPasswordTooShort && (
                            <Spacer top={1}>
                                <ErrorText>{passwordTooShortError}</ErrorText>
                            </Spacer>
                        )}
                        <Spacer top={2} />
                        <InputText
                            turnOffAutocomplete
                            placeholder={placeholderRepeatPassword}
                            id="password_repeat"
                            type="password"
                            onChange={(e) => setPassword2(e.target.value)}
                            width={isSmall ? "300px" : undefined}
                        />
                    </form>
                    {doPasswordsNotMatch && (
                        <Spacer top={1}>
                            <ErrorText>{passwordsMustMatchError}</ErrorText>
                        </Spacer>
                    )}
                    <Spacer top={2.5} />
                    <StyledRoundButton
                        dark
                        disabled={doPasswordsNotMatch || isPasswordTooShort}
                        onClick={async () => {
                            await UserAPI.updateUserPassword(password);
                            setPassword("");
                            setPassword2("");
                        }}
                    >
                        <Spacer top={1} bottom={1} left={1} right={1}>
                            <FormattedText weight="300">
                                <FormattedMessage
                                    id="changePassword"
                                    defaultMessage="Change password"
                                />
                            </FormattedText>
                        </Spacer>
                    </StyledRoundButton>
                </Flex>
                {user?.has_paying_subscription &&
                    user.subscription_status === "active" &&
                    !showSubscriptionStopped && (
                        <>
                            <Spacer top={3} />
                            <FormattedMessage
                                id="cancelSubscriptionDesc"
                                defaultMessage="El teu compte és premium i tens accés a totes les funcionalitats. Si vols aturar la subscripció a Wise Owl, clica aquí:"
                            />
                            <Spacer top={2} />
                            <StyledRoundButton
                                dark
                                disabled={
                                    doPasswordsNotMatch || isPasswordTooShort
                                }
                                onClick={async () => {
                                    PaymentAPI.cancelSubscription().then(
                                        ({ status }) => {
                                            if (status === 200) {
                                                setShowSubscriptionStopped(
                                                    true
                                                );
                                            }
                                        }
                                    );
                                }}
                            >
                                <Spacer top={1} bottom={1} left={1} right={1}>
                                    <FormattedText weight="300">
                                        <FormattedMessage
                                            id="cancelSubscriptionButton"
                                            defaultMessage="Cancel subscription"
                                        />
                                    </FormattedText>
                                </Spacer>
                            </StyledRoundButton>
                        </>
                    )}
                {((user?.has_paying_subscription &&
                    user.subscription_status === "canceled") ||
                    (user && showSubscriptionStopped)) && (
                    <>
                        <Spacer top={3} />
                        {user.current_period_end ? (
                            <FormattedMessage
                                id="canceledSubscriptionWithDate"
                                defaultMessage="Your Wise Owl subscription has been canceled. You will have access to all the premium features until the end of the current billing period the {{date}}."
                                values={{
                                    date: (
                                        <FormattedDate
                                            value={Date.parse(
                                                user.current_period_end
                                            )}
                                        />
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id="canceledSubscription"
                                defaultMessage="Your Wise Owl subscription has been canceled. You will have access to all the premium features until the end of the current billing period."
                            />
                        )}
                    </>
                )}
                <Spacer top={3} />
                <Divider />
                <Spacer top={2} />
                {showDeleteAccountUI ? (
                    <Flex direction="column">
                        <FormattedText weight="300" size="18px">
                            <FormattedMessage
                                id="deleteAccountDesc"
                                defaultMessage="Deleting your account is an irreversible action. All your data will be deleted. Are you sure you want to continue?"
                            />
                        </FormattedText>
                        <Spacer top={2} />
                        <Flex>
                            <StyledRoundButton
                                onClick={async () => {
                                    PaymentAPI.cancelSubscription().then(
                                        async ({ status }) => {
                                            if (status === 200) {
                                                setShowSubscriptionStopped(
                                                    true
                                                );
                                                await UserAPI.deleteUser();
                                                await AuthorizationAPI.logOut();
                                                invalidateTokenSingleton();
                                                clearAllData();
                                            }
                                        }
                                    );
                                }}
                            >
                                <Spacer top={2} bottom={2} left={2} right={2}>
                                    <FormattedText
                                        weight="300"
                                        color={ERROR_TEXT_COLOR}
                                    >
                                        <FormattedMessage
                                            id="yes"
                                            defaultMessage="Yes"
                                        />
                                    </FormattedText>
                                </Spacer>
                            </StyledRoundButton>
                            <Spacer left={2} />
                            <StyledRoundButton
                                onClick={() => setShowDeleteAccountUI(false)}
                            >
                                <Spacer top={2} bottom={2} left={2} right={2}>
                                    <FormattedText weight="300">
                                        <FormattedMessage
                                            id="no"
                                            defaultMessage="No"
                                        />
                                    </FormattedText>
                                </Spacer>
                            </StyledRoundButton>
                        </Flex>
                    </Flex>
                ) : (
                    <Flex direction={isSmall ? "column" : "row"}>
                        <StyledRoundButton
                            onClick={async () => {
                                await AuthorizationAPI.logOut();
                                invalidateTokenSingleton();
                                clearAllData();
                            }}
                        >
                            <Spacer top={2} bottom={2} left={2} right={2}>
                                <Flex>
                                    <FormattedText weight="300" size="24px">
                                        <FormattedMessage
                                            id="logOut"
                                            defaultMessage="Log out of the app"
                                        />
                                    </FormattedText>
                                    <Spacer left={2} />
                                    <ImageIcon
                                        src={CompleteArrowRight}
                                        alt="Click here to log out of the app"
                                    />
                                </Flex>
                            </Spacer>
                        </StyledRoundButton>
                        <Spacer
                            right={isSmall ? 0 : 4}
                            bottom={isSmall ? 4 : 0}
                        />
                        <StyledRoundButton
                            onClick={() => setShowDeleteAccountUI(true)}
                        >
                            <Spacer top={2} bottom={2} left={2} right={2}>
                                <FormattedText
                                    weight="300"
                                    size="24px"
                                    color={ERROR_TEXT_COLOR}
                                >
                                    <FormattedMessage
                                        id="deleteAccount"
                                        defaultMessage="Delete account"
                                    />
                                </FormattedText>
                            </Spacer>
                        </StyledRoundButton>
                    </Flex>
                )}
            </Flex>
        </Modal>
    );
};
