import React from "react";
import { InputState } from "../models/constants";
import Flex from "./Flex";
import CustomInput from "./CustomInput";
import TextInput from "./TextInput";
import Spacer from "./Spacer";

const Task = ({
    text,
    state,
    changeState,
    onTextChange,
    onTextPaste,
    id,
    shouldAutofocus,
}: {
    text: string;
    state: InputState;
    changeState: (newState: InputState) => void;
    onTextChange: (newText: string) => void;
    onTextPaste: (pastedText: string, atIndex?: number) => void;
    id: number | string;
    shouldAutofocus?: boolean;
}) => {
    return (
        <Flex>
            <CustomInput state={state} changeState={changeState} />
            <Spacer right={1} />
            <TextInput
                text={text}
                state={state}
                onPaste={onTextPaste}
                onChange={onTextChange}
                id={id}
                shouldAutofocus={shouldAutofocus}
            />
        </Flex>
    );
};

export default Task;
