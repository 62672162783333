import { useState, useEffect } from "react";

const useActiveElement = () => {
    const [activeID, setActiveID] = useState(undefined);

    const handleFocusIn = (e: FocusEvent) => {
        if (e.target === null) {
            return;
        }
        // @ts-ignore
        setActiveID(e.target.id);
    };

    useEffect(() => {
        document.addEventListener("focusin", handleFocusIn);
        return () => {
            document.removeEventListener("focusin", handleFocusIn);
        };
    }, []);

    return activeID;
};

export default useActiveElement;
