import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Task from "../components/Task";
import Spacer from "../components/Spacer";
import Flex from "../components/Flex";
import { InputState } from "../models/constants";
import { FormattedDate } from "react-intl";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import ArrowRight from "../images/arrowRight.svg";
import { FormattedMessage } from "react-intl";
import Header from "../components/Header";
import { CLOUDFRONT_DOMAIN, getFullTasksForCurrentDay } from "../utils/utils";
import CookiesModal from "./CookiesModal";
import { areCookiesAccepted, isUserLoggedIn } from "../models/persistanceAPI";
import { FIRST_LAYER_COLOR, SECOND_LAYER_COLOR, TEXT_COLOR, TOP_LAYER_COLOR } from "../utils";
import { Footer } from "./Footer";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { FloatingMenu } from "../components/FloatingMenu";
import useDay from "../models/useDayReducer";
import { UserConfigurationModal } from "../components/UserConfigurationModal";
import { Navigate } from "react-router-dom";
import { PremiumUpsellBottomModal } from "./PremiumUpsellBottomModal";

const StrongText = styled.div`
    font-size: 20px;
    font-family: "Inter";
`;

// PAST CONFIG that generated a bug: height: calc(100vh - 112px); && no min-height
const DayTasksContainer = styled.div`
    background: ${FIRST_LAYER_COLOR};
    height: auto;
    min-height: 86vh;
    border-radius: 8px;
`;

const DayTasksInnerContainer = styled.div`
    background: ${FIRST_LAYER_COLOR};
    height: auto;
    border-radius: 8px;
`;

const SmallDeviceButtonContainer = styled.div`
    min-width: 50px;
    min-height: 50px;
`;

const StyledButtonContainer = styled(Flex)<{isSmall?: boolean}>`
    padding: ${(props) => props.isSmall ? "0px":"10px"};
    margin: ${(props) => props.isSmall ? "8px":"0px"};
    border-radius: 8px;
    background-color: ${SECOND_LAYER_COLOR};

    &:hover {
        background-color: ${FIRST_LAYER_COLOR};
    }
    
    &:active {
        background-color: ${TOP_LAYER_COLOR};
    }

`;

const GoBackOneDayText = ({ diffWithToday }: { diffWithToday: number }) => {
    switch (diffWithToday) {
        case 0:
            return (
                <FormattedMessage id="yesterday" defaultMessage="yesterday" />
            );
        case 1:
            return <FormattedMessage id="today" defaultMessage="today" />;
        case 2:
            return <FormattedMessage id="tomorrow" defaultMessage="tomorrow" />;
        default:
            return (
                <FormattedMessage id="day_before" defaultMessage="day before" />
            );
    }
};

const GoForwardOneDayText = ({ diffWithToday }: { diffWithToday: number }) => {
    switch (diffWithToday) {
        case 0:
            return <FormattedMessage id="tomorrow" defaultMessage="tomorrow" />;
        case -1:
            return <FormattedMessage id="today" defaultMessage="today" />;
        case -2:
            return (
                <FormattedMessage id="yesterday" defaultMessage="yesterday" />
            );
        default:
            return <FormattedMessage id="next_day" defaultMessage="next day" />;
    }
};

const Day = () => {
    const isPremium = false;
    const {
        state,
        updateTextForTask,
        updateStateForTask,
        pasteTextInTask,
        goForwardOneDay,
        goBackOneDay,
        //goToToday,
        sort,
        bulkAction,
    } = useDay(isPremium);

    const { isSmall, isMedium } = useContext(ScreenSizeContext);

    // this piece of states indicates how many days you have to add or substract to get to today
    const [diffWithToday, setDiffWithToday] = useState(0);
    const date = state.currentDate;
    const logo = `${CLOUDFRONT_DOMAIN}/owl.png`;

    const goBackOneDayHandler = () => {
        setDiffWithToday(diffWithToday - 1);
        goBackOneDay();
    };

    const goForwardOneDayHandler = () => {
        setDiffWithToday(diffWithToday + 1);
        goForwardOneDay();
    };

    /*const goBackToToday = () => {
        setDiffWithToday(0);
        // TODO: fix this, right now it's not working on local
        //goToToday();
    };*/

    const tasks = getFullTasksForCurrentDay(state);
    const [shouldShowCookiesModal, setShouldShowCookiesModal] = useState(
        !areCookiesAccepted()
    );
    const [showPremiumUpsell, setShowPremiumUpsell] = useState(
        !shouldShowCookiesModal
    );
    const [
        shouldShowUserConfigurationModal,
        setShouldShowUserConfigurationModal,
    ] = useState(false);

    const [shouldNavigateToPremium, setShouldNavigateToPremium] =
        useState(false);

    useEffect(() => {
        if (isUserLoggedIn()) {
            setShouldNavigateToPremium(true);
        }
    }, []);

    if (shouldNavigateToPremium) {
        return <Navigate to="/premium" />;
    }
    return (
        <>
            <Flex direction={isSmall ? "column" : "row"}>
                <Spacer
                    right={isSmall ? 0 : 3}
                    left={isSmall ? 0 : 3}
                    top={isSmall ? 2 : 3}
                    style={{ flexGrow: 1 }}
                >
                    <Flex justifyContent="space-between" alignItems="center">
                        <Clickable onClick={goBackOneDayHandler}>
                            <StyledButtonContainer alignItems="center" isSmall={isSmall}>
                                {isSmall && <Spacer left={1} />}
                                {isSmall ? (
                                    <SmallDeviceButtonContainer>
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            height="50px"
                                            width="50px"
                                        >
                                            <img
                                                src={ArrowLeft}
                                                alt="Click here to go to the previous day"
                                            />
                                        </Flex>
                                    </SmallDeviceButtonContainer>
                                ) : (
                                    <img
                                        src={ArrowLeft}
                                        alt="Click here to go to the previous day"
                                    />
                                )}

                                <Spacer right={0.5} />
                                {!isSmall && (
                                    <StrongText>
                                        <GoBackOneDayText
                                            diffWithToday={diffWithToday}
                                        />
                                    </StrongText>
                                )}
                            </StyledButtonContainer>
                        </Clickable>
                        {isSmall || isMedium ? (
                            <Flex>
                                <img
                                    src={logo}
                                    alt="Wise owl logo"
                                    style={{
                                        width: "auto",
                                        height: "auto",
                                        maxWidth: "24px",
                                        objectFit: "contain",
                                        padding: "8px",
                                        background: TEXT_COLOR,
                                        borderRadius: "8px",
                                    }}
                                />
                                <Spacer right={1} />
                                <Header>
                                    <FormattedDate value={date} />
                                </Header>
                            </Flex>
                        ) : (
                            <Flex>
                                <img
                                    src={logo}
                                    alt="Wise owl logo"
                                    style={{
                                        width: "auto",
                                        height: "auto",
                                        maxWidth: "24px",
                                        objectFit: "contain",
                                        padding: "8px",
                                        background: TEXT_COLOR,
                                        borderRadius: "8px",
                                    }}
                                />
                                <Spacer right={1} />
                                <Header>
                                    <FormattedDate
                                        value={date}
                                        weekday="long"
                                        year="numeric"
                                        month="long"
                                        day="numeric"
                                    />
                                </Header>
                            </Flex>
                        )}
                        <Clickable onClick={goForwardOneDayHandler}>
                            <StyledButtonContainer alignItems="center" isSmall={isSmall}>
                                {!isSmall && (
                                    <StrongText>
                                        <GoForwardOneDayText
                                            diffWithToday={diffWithToday}
                                        />
                                    </StrongText>
                                )}
                                <Spacer right={0.5} />
                                {isSmall ? (
                                    <SmallDeviceButtonContainer>
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            height="50px"
                                            width="50px"
                                        >
                                            <img
                                                src={ArrowRight}
                                                alt="Click here to go to the next day"
                                            />
                                        </Flex>
                                    </SmallDeviceButtonContainer>
                                ) : (
                                    <img
                                        src={ArrowRight}
                                        alt="Click here to go to the next day"
                                    />
                                )}

                                {isSmall && <Spacer right={1} />}
                            </StyledButtonContainer>
                        </Clickable>
                    </Flex>
                    <Spacer bottom={isSmall ? 1 : 2} />
                    <DayTasksContainer>
                        <DayTasksInnerContainer>
                            <Spacer right={5} left={5} top={5} bottom={3}>
                                {/* Also interesting: https://github.com/dvtng/react-loading-skeleton#readme */}
                                {tasks.map((task, index) => {
                                    return (
                                        <Spacer bottom={1} key={task.id}>
                                            <Task
                                                id={task.id}
                                                key={task.id}
                                                text={
                                                    state.agenda.tasks &&
                                                    state.agenda.tasks[task.id]
                                                        ? state.agenda.tasks[
                                                              task.id
                                                          ].text
                                                        : ""
                                                }
                                                state={task.state}
                                                changeState={(
                                                    newState: InputState
                                                ) =>
                                                    updateStateForTask(
                                                        newState,
                                                        index
                                                    )
                                                }
                                                onTextChange={(
                                                    newText: string
                                                ) =>
                                                    updateTextForTask(
                                                        newText,
                                                        index
                                                    )
                                                }
                                                onTextPaste={(
                                                    pastedText: string,
                                                    atIndex?: number
                                                ) => {
                                                    pasteTextInTask(
                                                        pastedText,
                                                        index,
                                                        atIndex
                                                    );
                                                }}
                                            />
                                        </Spacer>
                                    );
                                })}
                            </Spacer>
                        </DayTasksInnerContainer>
                    </DayTasksContainer>
                </Spacer>
            </Flex>
            {shouldShowCookiesModal && (
                <CookiesModal
                    hideCookiesModal={() => setShouldShowCookiesModal(false)}
                />
            )}
            {showPremiumUpsell && (
                <PremiumUpsellBottomModal
                    closePremiumUpsell={() => setShowPremiumUpsell(false)}
                />
            )}
            <Spacer top={2} />
            {!isSmall && !shouldShowCookiesModal && !showPremiumUpsell && (
                <FloatingMenu
                    currentDate={state.currentDate}
                    sortTasks={sort}
                    bulkAction={bulkAction}
                    searchText={null}
                />
            )}
            <Spacer top={2} />
            <Footer
                isSmall={isSmall}
                skipMoreInfoSection={false}
                paddingRight={isSmall ? 1 : 3}
                paddingLeft={isSmall ? 1 : 3}
                paddingTop={isSmall ? 2 : 3}
                paddingBot={isSmall ? 2 : 3}
                isPremium={isPremium}
                showUserConfigurationModal={() =>
                    setShouldShowUserConfigurationModal(true)
                }
            />
            {isPremium && shouldShowUserConfigurationModal && (
                <UserConfigurationModal
                    closeModal={() =>
                        setShouldShowUserConfigurationModal(false)
                    }
                />
            )}
        </>
    );
};

export default Day;
