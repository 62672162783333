import { useContext, useState } from "react";
import ColoredText from "../components/ColoredText";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import { FIRST_LAYER_COLOR, TEXT_COLOR } from "../utils";
import TextInput from "../components/TextInput";
import { InputState } from "../models/constants";
import styled from "styled-components";
import {
    clearAllData,
    loadAppData,
    saveAppData,
} from "../models/persistanceAPI";
import Flex from "../components/Flex";
import { StyledButton } from "../components/StyledButton";
import ScreenSizeContext from "../contexts/ScreenSizeContext";

const DayTasksContainer = styled.div`
    background: ${FIRST_LAYER_COLOR};
    height: auto;
    min-height: 86vh;
    border-radius: 8px;
`;

const StyledTextArea = styled.textarea`
    width: 100%;
    height: 500px;
`;

export const Debug = () => {
    const { isSmall } = useContext(ScreenSizeContext);
    const EXPECTED_PASSWORD = "mussolsavi96";
    const [password, setPassword] = useState("");
    const [data, setData] = useState(JSON.stringify(loadAppData()));

    const saveChanges = () => {
        saveAppData(JSON.parse(data));
    };

    const copyText = () => {
        navigator.clipboard.writeText(data);
    };

    const deleteContent = () => {
        clearAllData();
    };
    if (password !== EXPECTED_PASSWORD) {
        return (
            <Spacer
                top={isSmall ? 4 : 8}
                bottom={isSmall ? 4 : 8}
                left={isSmall ? 4 : 8}
                right={isSmall ? 4 : 8}
            >
                <DayTasksContainer>
                    <Spacer top={2} bottom={2} left={2} right={2}>
                        <Header>Debug tool - please authenticate</Header>
                        <Spacer top={4} />
                        <ColoredText color={TEXT_COLOR}>
                            Input the right password below:
                        </ColoredText>
                        <Spacer top={1} />
                        <TextInput
                            text={password}
                            onPaste={setPassword}
                            onChange={setPassword}
                            state={InputState.DONE}
                            id="pwd"
                            hidePlaceholder
                        />
                    </Spacer>
                </DayTasksContainer>
            </Spacer>
        );
    }
    return (
        <Spacer
            top={isSmall ? 4 : 8}
            bottom={isSmall ? 4 : 8}
            left={isSmall ? 4 : 8}
            right={isSmall ? 4 : 8}
        >
            <DayTasksContainer>
                <Spacer top={2} bottom={2} left={2} right={2}>
                    <Header>Welcome to the debug tool!</Header>
                    <Spacer top={1} />
                    <ColoredText color={TEXT_COLOR}>
                        This tool is meant to be used to try to reproduce
                        problems. You can see your current Wiseowl data here or
                        input a new one.
                    </ColoredText>
                    <Spacer top={2} />
                    <StyledTextArea
                        name="your_data"
                        defaultValue={data}
                        onChange={(e) => {
                            e.preventDefault();
                            setData(e.target.value);
                        }}
                    />
                    <Spacer top={2} />
                    <Flex>
                        <StyledButton onClick={saveChanges}>
                            <ColoredText color={TEXT_COLOR}>
                                Save changes
                            </ColoredText>
                        </StyledButton>
                        <Spacer right={1} />
                        <StyledButton onClick={copyText}>
                            <ColoredText color={TEXT_COLOR}>
                                Copy content
                            </ColoredText>
                        </StyledButton>
                        <Spacer right={1} />
                        <StyledButton onClick={deleteContent}>
                            <ColoredText color={TEXT_COLOR}>
                                Delete ALL content, keep the text in the text
                                area
                            </ColoredText>
                        </StyledButton>
                    </Flex>
                </Spacer>
            </DayTasksContainer>
        </Spacer>
    );
};
