import { AuthorizationAPI, PaymentAPI, UserAPI } from "../api/api";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import Flex from "../components/Flex";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import CompleteArrowRightContrast from "../images/completeArrowRightContrast.svg";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import { FormattedMessage } from "react-intl";
import { StyledRoundButton } from "../components/StyledRoundButton";
import FormattedText from "../components/FormattedText";
import { CLOUDFRONT_DOMAIN, CONTRAST_COLOR, TEXT_COLOR } from "../utils";
import { StyledLogoImage } from "../components/StyledLogoImage";
import { clearAllData } from "../models/persistanceAPI";
import { Footer } from "./Footer";
import { UserConfigurationModal } from "../components/UserConfigurationModal";
import { User } from "../models/types";
import { useNavigate } from "react-router-dom";
import UserContext from "../contexts/UserContext";
import { PremiumFeatures } from "../components/FeaturesContainer";
import { invalidateTokenSingleton } from "../api/invalidateTokenSingleton";

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const Subscribe = () => {
    const navigate = useNavigate();
    const { isSmall } = useContext(ScreenSizeContext);
    const { user } = useContext(UserContext);

    const [userToSubscribe, setUser] = useState<User | null>(user);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [randomImage, setRandomImage] = useState<number>(
        Math.floor(Math.random() * 4)
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [
        shouldShowUserConfigurationModal,
        setShouldShowUserConfigurationModal,
    ] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCreatePayment = () => {
        setIsLoading(true);
        PaymentAPI.createPayment()
            .then(({ data }) => {
                if (data.url) {
                    window.location.href = data.url;
                } else {
                    console.error("Error:", data.error);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        UserAPI.getUserDetails().then((user) => {
            setUser(user.data);
        });
    }, []);

    useEffect(() => {
        if (userToSubscribe && userToSubscribe.has_paying_subscription) {
            navigate("/premium");
        }
    }, [userToSubscribe, navigate]);

    const splash1 = `${CLOUDFRONT_DOMAIN}/wiseOwl1.jpeg`;
    const splash2 = `${CLOUDFRONT_DOMAIN}/wiseOwl2.jpeg`;
    const splash3 = `${CLOUDFRONT_DOMAIN}/wiseOwl3.jpeg`;
    const splash4 = `${CLOUDFRONT_DOMAIN}/wiseOwl4.jpeg`;
    const randomSplashImage = [splash1, splash2, splash3, splash4][randomImage];
    const stillHasTrial =
        userToSubscribe && userToSubscribe.free_trial_days_remaining > 0;
    const logo = `${CLOUDFRONT_DOMAIN}/owl.png`;
    return (
        <UserContext.Provider value={{ user: userToSubscribe, setUser }}>
            <Flex grow={1} direction="row">
                <Flex grow={1} direction="column" style={{ height: "inherit" }}>
                    <Spacer
                        left={isSmall ? 2 : 4}
                        right={isSmall ? 2 : 10}
                        top={isSmall ? 2 : 4}
                        bottom={isSmall ? 2 : 4}
                    >
                        <Flex direction="column">
                            {stillHasTrial ? (
                                <Clickable
                                    onClick={async () => {
                                        navigate("/premium");
                                    }}
                                >
                                    <Flex>
                                        <img
                                            src={ArrowLeft}
                                            alt="Click here to go back to the app"
                                        />
                                        <Spacer right={1} />
                                        <FormattedMessage
                                            id="goBackToApp"
                                            defaultMessage="Go back to the app"
                                        />
                                    </Flex>
                                </Clickable>
                            ) : (
                                <Clickable
                                    onClick={async () => {
                                        await AuthorizationAPI.logOut();
                                        invalidateTokenSingleton();
                                        clearAllData();
                                    }}
                                >
                                    <Flex>
                                        <img
                                            src={ArrowLeft}
                                            alt="Click here to log out"
                                        />
                                        <Spacer right={1} />
                                        <FormattedMessage
                                            id="logOut"
                                            defaultMessage="Log out of the app"
                                        />
                                    </Flex>
                                </Clickable>
                            )}
                            <Spacer top={4} />
                            <Flex alignItems="start">
                                <img
                                    src={logo}
                                    alt="Wise owl logo"
                                    style={{
                                        width: "48px",
                                        height: "48px",
                                        objectFit: "contain",
                                        padding: "8px",
                                        background: TEXT_COLOR,
                                        borderRadius: "8px",
                                    }}
                                />
                                <Spacer right={2} />
                                <Header fontWeight="300" fontSize="39px">
                                    {stillHasTrial ? (
                                        <FormattedMessage
                                            id="subscribeTitleHasTrial"
                                            defaultMessage="Subscribe now to enjoy a 30% discount the first month!"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="subscribeTitle"
                                            defaultMessage="Subscribe to Wise Owl to continue enjoying the app"
                                        />
                                    )}
                                </Header>
                            </Flex>
                        </Flex>
                        <Spacer top={3} />
                        <FormattedText size="24px" lineHeight="1.0">
                            {stillHasTrial ? (
                                <FormattedMessage
                                    id="trialNotFinished"
                                    defaultMessage="Subscribe to Wise Owl for just €1 this month, then €1.49 the following ones:"
                                />
                            ) : (
                                <FormattedMessage
                                    id="trialFinished"
                                    defaultMessage="Your trial period has ended, but for €1.49/month you can continue using Wise Owl:"
                                />
                            )}
                        </FormattedText>
                        <Spacer top={2} />
                        <PremiumFeatures />
                        <Spacer top={1} />
                        <Flex
                            grow={1}
                            direction="column"
                            justifyContent="center"
                        >
                            <Spacer top={3} />
                            <StyledRoundButton
                                isLoading={isLoading}
                                onClick={handleCreatePayment}
                            >
                                <Spacer top={2} bottom={2} left={2} right={2}>
                                    <Flex alignItems="center">
                                        <FormattedText
                                            size="24px"
                                            weight="300"
                                            color={CONTRAST_COLOR}
                                            lineHeight="1.0"
                                        >
                                            {stillHasTrial ? (
                                                <FormattedMessage
                                                    id="subscribeButtonStillTrial"
                                                    defaultMessage="Subscribe for only €1 this month"
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    id="subscribeButton"
                                                    defaultMessage="Subscribe for only €1.49/month"
                                                />
                                            )}
                                        </FormattedText>
                                        <Spacer left={2} />
                                        {!isLoading && (
                                            <StyledLogoImage
                                                src={CompleteArrowRightContrast}
                                                alt="Click here to log into the app"
                                            />
                                        )}
                                    </Flex>
                                </Spacer>
                            </StyledRoundButton>

                            <Spacer top={4} />

                            <Footer
                                isSmall={isSmall}
                                skipMoreInfoSection={false}
                                paddingRight={0}
                                paddingLeft={0}
                                paddingTop={isSmall ? 2 : 3}
                                paddingBot={isSmall ? 2 : 3}
                                isPremium={true}
                                showUserConfigurationModal={() =>
                                    setShouldShowUserConfigurationModal(true)
                                }
                            />
                            {shouldShowUserConfigurationModal && (
                                <UserConfigurationModal
                                    closeModal={() =>
                                        setShouldShowUserConfigurationModal(
                                            false
                                        )
                                    }
                                />
                            )}
                            <Spacer top={4} />
                        </Flex>
                    </Spacer>
                </Flex>
                {!isSmall && (
                    <Flex 
                        grow={1} 
                        direction="column" 
                        style={{ height: "100vh" }}
                    >
                        <StyledImage src={randomSplashImage} />
                    </Flex>
                )}
            </Flex>
        </UserContext.Provider>
    );
};

export default Subscribe;
