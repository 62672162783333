import { useEffect } from "react";

export const useOnClickOutside = (
    ref: React.MutableRefObject<null>,
    callback: () => void
) => {
    useEffect(() => {
        //@ts-expect-error I won't add typing to the event
        function handleClickOutside(event) {
            // @ts-expect-error I won't check this either
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [callback, ref]);
};
