import store from "store";
import { AppData } from "./types";
import { ACCESS_TOKEN } from "../api/api";

const appDataKey = "avantData";
const noLoginKey = "noLogin";
const cookiesAccepted = "cookiesAccepted";
const skipWeekends = "skipWeekends";
const isMenuOpen = "isMenuOpen";

/// SETTERS /////
export const saveAppData = (appData: AppData): void => {
    store.set(appDataKey, appData);
};

export const clearAppData = (): void => {
    store.remove(appDataKey);
};

// check if using throttle is safe or not... can we run into race conditions?!
export const throttledSaveAppData = saveAppData; //throttle(saveAppData, 500)

export const storePreferNoSignup = (): void => {
    store.set(noLoginKey, "true");
};

export const storePreferSignup = (): void => {
    store.set(noLoginKey, "false");
};

export const setCookiesAccepted = (): void => {
    store.set(cookiesAccepted, "true");
};

export const clearAllData = (): void => {
    store.clearAll();
};

export const setIsMenuOpen = (isOpen: boolean) => {
    store.set(isMenuOpen, isOpen.toString());
};

export const setSkipWeekends = (shouldSkipWeekends: boolean) => {
    store.set(skipWeekends, shouldSkipWeekends.toString());
};

/// GETTERS /////

export const loadAppData = (): undefined | AppData => {
    return store.get(appDataKey);
};

export const isUserPreferringNoSignUp = (): boolean => {
    const storedValue = store.get(noLoginKey) ?? false;
    return storedValue === "true";
};

export const isUserLoggedIn = (): boolean => {
    const storedValue = store.get(ACCESS_TOKEN) ?? false;
    return !!storedValue && storedValue !== "undefined";
};

export const areCookiesAccepted = (): boolean => {
    const storedValue = store.get(cookiesAccepted) ?? false;
    return storedValue === "true";
};

export const shouldMenuBeOpened = () => {
    const shouldMenuBeOpened = store.get(isMenuOpen);
    return !(shouldMenuBeOpened === "false");
};

export const shouldSkipWeekends = () => {
    const shouldSkipWeekends = store.get(skipWeekends);
    return shouldSkipWeekends === "true";
};
