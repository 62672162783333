import styled from "styled-components";
import { PropsWithChildren, useContext } from "react";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { TEXT_COLOR } from "../utils";

const FormattedTextWithScreensize = styled.div<{
    weight?: string;
    color?: string;
    size?: string;
    lineHeight?: string;
    textAlign?: string;
    isSmall?: boolean;
    underline?: boolean;
}>`
    color: ${(props) => props.color ?? TEXT_COLOR};
    font-family: "Inter";
    font-size: ${(props) => props.size ?? (props.isSmall ? "14px" : "16px")};
    text-align: ${(props) => props.textAlign ?? "left"};
    font-style: normal;
    font-weight: ${(props) => props.weight ?? "400"};
    line-height: ${(props) =>
        props.lineHeight ? props.lineHeight : props.isSmall ? "20px" : "24px"};
    text-decoration: ${(props) => (props.underline ? "underline" : "none")};
`;

export const EllipsisFormattedText = styled(FormattedTextWithScreensize)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    max-height: 40px;
`;

const FormattedText = ({
    children,
    weight,
    color,
    size,
    lineHeight,
    textAlign,
    underline,
}: PropsWithChildren<{}> & {
    weight?: string;
    color?: string;
    size?: string;
    lineHeight?: string;
    textAlign?: string;
    underline?: boolean;
}) => {
    const { isSmall } = useContext(ScreenSizeContext);
    return (
        <FormattedTextWithScreensize
            weight={weight}
            color={color}
            size={size}
            lineHeight={lineHeight}
            textAlign={textAlign}
            isSmall={isSmall}
            underline={underline}
        >
            {children}
        </FormattedTextWithScreensize>
    );
};

export default FormattedText;
