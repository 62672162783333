import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import ColoredText from "../components/ColoredText";
import Flex from "../components/Flex";
import {
    DARK_TEXT_COLOR,
    TEXT_COLOR,
    SECOND_LAYER_COLOR,
    CLOUDFRONT_DOMAIN,
} from "../utils";
import { useNavigate } from "react-router-dom";
import Clickable from "../components/Clickable";
import Spacer from "../components/Spacer";
import { Divider } from "../components/Divider";
import { ButtonLink } from "../components/ButtonLink";
import { useString } from "../hooks/useString";

const StyledAnchorPoint = styled.a`
    color: ${DARK_TEXT_COLOR};
`;

const FooterContainer = styled.div`
    background: ${SECOND_LAYER_COLOR};
`;

const UnderlinedText = styled.div`
    text-decoration: underline;
`;

export function Footer({
    paddingTop,
    paddingBot,
    paddingLeft,
    paddingRight,
    isSmall,
    skipMoreInfoSection,
    isPremium,
    showUserConfigurationModal,
}: {
    paddingTop?: number;
    paddingBot?: number;
    paddingLeft?: number;
    paddingRight?: number;
    isSmall: boolean;
    skipMoreInfoSection: boolean;
    isPremium: boolean;
    showUserConfigurationModal?: () => void;
}) {
    const navigate = useNavigate();
    const cookiePolicy = useString("cookiePolicy");
    const privacyPolicy = useString("privacyPolicy");
    const termsAndConditions = useString("TermsAndConditions");
    const legalNotice = useString("legalNotice");
    const logo = `${CLOUDFRONT_DOMAIN}/owl.png`;
    return (
        <FooterContainer>
            <Spacer left={paddingLeft} right={paddingRight}>
                <Divider />
            </Spacer>

            <Spacer
                top={paddingTop}
                bottom={paddingBot}
                left={paddingLeft}
                right={paddingRight}
            >
                <Flex
                    justifyContent="space-between"
                    direction={isSmall ? "column" : "row"}
                >
                    <Flex
                        direction={isSmall ? "column" : "row"}
                        grow={2}
                        alignItems={isSmall ? undefined : "start"}
                    >
                        <img
                            src={logo}
                            alt="Wise owl logo"
                            style={{
                                width: "48px",
                                height: "48px",
                                objectFit: "contain",
                                padding: "8px",
                                background: TEXT_COLOR,
                                borderRadius: "8px",
                            }}
                        />
                        <Spacer top={isSmall ? 1 : 0} right={isSmall ? 0 : 2} />
                        <Flex direction="column">
                            {!skipMoreInfoSection && (
                                <Clickable onClick={() => navigate("/info")}>
                                    <UnderlinedText>
                                        <FormattedMessage
                                            id="moreInfo"
                                            defaultMessage="More information"
                                        />
                                    </UnderlinedText>
                                </Clickable>
                            )}
                            {!skipMoreInfoSection && (
                                <Spacer right={isSmall ? 0 : 2} top={1} />
                            )}

                            <Clickable onClick={() => navigate("/changelog")}>
                                <UnderlinedText>
                                    <FormattedMessage
                                        id="changelog_smallcase"
                                        defaultMessage="Changelog"
                                    />
                                </UnderlinedText>
                            </Clickable>

                            <Spacer right={isSmall ? 0 : 2} top={1} />
                            <ButtonLink
                                onClick={() => navigate("/cookie-policy")}
                            >
                                {cookiePolicy}
                            </ButtonLink>

                            <Spacer right={isSmall ? 0 : 2} top={1} />
                            <ButtonLink
                                onClick={() => navigate("/legal-notice")}
                            >
                                {legalNotice}
                            </ButtonLink>

                            <Spacer right={isSmall ? 0 : 2} top={1} />
                            <ButtonLink
                                onClick={() => navigate("/privacy-policy")}
                            >
                                {privacyPolicy}
                            </ButtonLink>

                            <Spacer right={isSmall ? 0 : 2} top={1} />
                            <ButtonLink
                                onClick={() =>
                                    navigate("/terms-and-conditions")
                                }
                            >
                                {termsAndConditions}
                            </ButtonLink>
                        </Flex>
                    </Flex>

                    {isSmall && <Spacer top={2} />}
                    <Flex grow={1} direction="column">
                        {isPremium ? (
                            <ButtonLink
                                onClick={() => {
                                    if (showUserConfigurationModal) {
                                        showUserConfigurationModal();
                                    }
                                }}
                            >
                                <FormattedMessage
                                    id="userConfiguration"
                                    defaultMessage="User configuration"
                                />
                            </ButtonLink>
                        ) : (
                            <ButtonLink onClick={() => navigate("/signup")}>
                                <FormattedMessage
                                    id="registerCTA"
                                    defaultMessage="Register"
                                />
                            </ButtonLink>
                        )}
                        <Spacer top={1} />
                        <ColoredText color="#30251b">
                            <FormattedMessage
                                id="feedback"
                                defaultMessage="You can leave feedback or ask for support via mail at"
                            />{" "}
                            <StyledAnchorPoint href="mailto:hola@wiseowl.cat">
                                hola@wiseowl.cat
                            </StyledAnchorPoint>{" "}
                            - Wise Owl v2.0
                        </ColoredText>
                    </Flex>
                </Flex>
            </Spacer>
        </FooterContainer>
    );
}
