// @ts-nocheck
import React from "react";
import Day from "./screens/Day";
import styled from "styled-components";
import { IntlProvider } from "react-intl";
import ScreenSizeContext from "./contexts/ScreenSizeContext";
import messages_ca from "./translations/ca.json";
import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";
import { HomePage, OwlDescription } from "./screens/HomePage";
import { Routes, Route, Navigate } from "react-router-dom";
import { SECOND_LAYER_COLOR } from "./utils";
import { useScreenSize } from "./hooks/useScreenSize";
import { Debug } from "./screens/Debug";
import { Changelog } from "./screens/Changelog";
import { LogIn } from "./screens/LogIn";
import { SignUp } from "./screens/SignUp";
import { VerifyEmailPage } from "./screens/VerifyEmailPage";
import { PrivateRoute } from "./components/PrivateRoute";
import AuthProvider from "./contexts/AuthContext";
import { RecoverPassword } from "./screens/RecoverPassword";
import { CookiePolicy } from "./screens/CookiePolicy";
import { Helmet } from "react-helmet";
import { LegalNotice } from "./screens/LegalNotice";
import { PrivacyPolicy } from "./screens/PrivacyPolicy";
import { TermsAndConditions } from "./screens/TermsAndConditions";
import PremiumDay from "./screens/PremiumDay";
import Subscribe from "./screens/Subscribe";

const messages = {
    ca: messages_ca,
    es: messages_es,
    en: messages_en,
};

const Background = styled.div`
    background: ${SECOND_LAYER_COLOR};
`;

const VALID_LANGUAGES = ["ca", "es", "en"];
const WiseOwl = () => {
    const screenSizeValues = useScreenSize();

    let language: "ca" | "es" | "en" =
        navigator.language.split(/[-_]/)[0] ?? "en";
    if (
        !VALID_LANGUAGES.find(
            (validLang: string) =>
                validLang.toLowerCase() === language.toLowerCase()
        )
    ) {
        language = "en";
    }

    return (
        <AuthProvider>
            <IntlProvider
                locale={navigator.language}
                messages={messages[language]}
            >
                <Helmet>
                    <title>{messages[language]["appTitle"]} </title>
                </Helmet>
                <ScreenSizeContext.Provider value={{ ...screenSizeValues }}>
                    <Background>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/local" element={<Day />} />
                            <Route path="/login" element={<LogIn />} />
                            <Route path="/signup" element={<SignUp />} />
                            <Route
                                path="/premium"
                                element={
                                    <PrivateRoute>
                                        <PremiumDay />
                                    </PrivateRoute>
                                }
                            />
                            <Route path="/debug" element={<Debug />} />
                            <Route path="/changelog" element={<Changelog />} />
                            <Route path="/info" element={<OwlDescription />} />
                            <Route
                                path="/verify-email/:user_id/:hash"
                                element={<VerifyEmailPage />}
                            />
                            <Route
                                path="/recover-password/:user_id/:hash"
                                element={<RecoverPassword />}
                            />
                            <Route
                                path="/cookie-policy"
                                element={<CookiePolicy />}
                            />
                            <Route
                                path="/legal-notice"
                                element={<LegalNotice />}
                            />
                            <Route
                                path="/privacy-policy"
                                element={<PrivacyPolicy />}
                            />
                            <Route
                                path="/terms-and-conditions"
                                element={<TermsAndConditions />}
                            />
                            <Route
                                path="/subscribe"
                                element={
                                    <PrivateRoute>
                                        <Subscribe />
                                    </PrivateRoute>
                                }
                            />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </Background>
                </ScreenSizeContext.Provider>
            </IntlProvider>
        </AuthProvider>
    );
};

export default WiseOwl;
