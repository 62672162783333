import { useContext, useEffect } from "react";
import BigHeader from "../components/BigHeader";
import Flex from "../components/Flex";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { useNavigate } from "react-router-dom";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { useString } from "../hooks/useString";

const ContainerWithMaxWidth = styled.div`
    max-width: 800px;
    margin: auto;
`;

const CustomH2 = styled.h2`
    padding-top: 16px;
    padding-bottom: 4px;
`;
const EnglishTermsAndConditions = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Terms and Conditions of Use for Wise Owl</CustomH2>
            <p>Effective Date: May 14th, 2024</p>

            <CustomH2>1. Acceptance of Terms</CustomH2>
            <p>
                By accessing or using our Service, you agree to be bound by
                these Terms and Conditions of Use ("Terms"). If you disagree
                with these Terms, do not use the Service.
            </p>

            <CustomH2>2. Modifications to the Terms</CustomH2>
            <p>
                We may modify these Terms at any time. We will post the modified
                Terms on this page and update the effective date. Your continued
                use of the Service after any modification constitutes acceptance
                of the modified Terms.
            </p>

            <CustomH2>3. Account Registration</CustomH2>
            <p>
                To use certain features of our Service, you may need to register
                and create an account. You are responsible for maintaining the
                confidentiality of your account credentials and all activities
                conducted under your account.
            </p>

            <CustomH2>4. Acceptable Use</CustomH2>
            <p>
                You agree to use the Service only for lawful purposes and in
                accordance with these Terms. You will not use the Service to:
            </p>
            <ul>
                <li>Violate any applicable law or regulation</li>
                <li>Infringe the intellectual property rights of others</li>
                <li>Transmit unlawful, offensive, or harmful material</li>
            </ul>

            <CustomH2>5. Limitation of Liability</CustomH2>
            <p>
                The Service is provided "as is" and "as available". We do not
                guarantee that the Service will be uninterrupted or error-free.
                To the extent permitted by law, we disclaim all warranties,
                express or implied.
            </p>

            <CustomH2>6. Indemnification</CustomH2>
            <p>
                You agree to indemnify and hold Wise Owl harmless from any
                claim, damage, loss, or expense (including legal fees) arising
                from your use of the Service or your violation of these Terms.
            </p>

            <CustomH2>7. Applicable Law</CustomH2>
            <p>
                These Terms shall be governed and construed in accordance with
                the laws of Spain. Any dispute arising out of these Terms shall
                be subject to the exclusive jurisdiction of the courts of
                Barcelona.
            </p>

            <CustomH2>8. Contact</CustomH2>
            <p>
                If you have any questions about these Terms, you can contact us
                at hola@wiseowl.cat.
            </p>
        </ContainerWithMaxWidth>
    );
};

const SpanishTermsAndConditions = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Términos y Condiciones de Uso de Wise Owl</CustomH2>
            <p>Fecha de entrada en vigor: 14 de mayo de 2024</p>

            <CustomH2>1. Aceptación de los Términos</CustomH2>
            <p>
                Al acceder o utilizar nuestro Servicio, usted acepta quedar
                vinculado por estos Términos y Condiciones de Uso ("Términos").
                Si no está de acuerdo con estos Términos, no utilice el
                Servicio.
            </p>

            <CustomH2>2. Modificaciones a los Términos</CustomH2>
            <p>
                Podemos modificar estos Términos en cualquier momento.
                Publicaremos los Términos modificados en esta página y
                actualizaremos la fecha de entrada en vigor. El uso continuado
                del Servicio después de cualquier modificación constituye la
                aceptación de los Términos modificados.
            </p>

            <CustomH2>3. Registro de Cuenta</CustomH2>
            <p>
                Para utilizar algunas funciones de nuestro Servicio, puede ser
                necesario registrarse y crear una cuenta. Usted es responsable
                de mantener la confidencialidad de las credenciales de su cuenta
                y de todas las actividades que se realicen bajo su cuenta.
            </p>

            <CustomH2>4. Uso Aceptable</CustomH2>
            <p>
                Usted acepta utilizar el Servicio solo para fines legales y de
                acuerdo con estos Términos. Usted no utilizará el Servicio para:
            </p>
            <ul>
                <li>Violar cualquier ley o regulación aplicable</li>
                <li>
                    Infringir los derechos de propiedad intelectual de otros
                </li>
                <li>Transmitir material ilícito, ofensivo o perjudicial</li>
            </ul>

            <CustomH2>5. Limitación de Responsabilidad</CustomH2>
            <p>
                El Servicio se proporciona "tal cual" y "según disponibilidad".
                No garantizamos que el Servicio sea ininterrumpido o esté libre
                de errores. En la medida permitida por la ley, declinamos todas
                las garantías, expresas o implícitas.
            </p>

            <CustomH2>6. Indemnización</CustomH2>
            <p>
                Usted acepta indemnizar y mantener indemne a Wise Owl de
                cualquier reclamación, daño, pérdida o gasto (incluidos
                honorarios legales) derivados de su uso del Servicio o su
                violación de estos Términos.
            </p>

            <CustomH2>7. Legislación Aplicable</CustomH2>
            <p>
                Estos Términos se regirán e interpretarán de acuerdo con las
                leyes de España. Cualquier disputa derivada de estos Términos
                estará sujeta a la jurisdicción exclusiva de los tribunales de
                Barcelona.
            </p>

            <CustomH2>8. Contacto</CustomH2>
            <p>
                Si tiene alguna pregunta sobre estos Términos, puede
                contactarnos en hola@wiseowl.cat.
            </p>
        </ContainerWithMaxWidth>
    );
};

const CatalanTermsAndConditions = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Termes i Condicions d'Ús de Wise Owl</CustomH2>
            <p>Data d'entrada en vigor: 14 de maig del 2024</p>

            <CustomH2>1. Acceptació dels Termes</CustomH2>
            <p>
                En accedir o utilitzar el nostre Servei, vostè accepta estar
                vinculat per aquests Termes i Condicions d'Ús ("Termes"). Si no
                està d'acord amb aquests Termes, no utilitzi el Servei.
            </p>

            <CustomH2>2. Modificacions als Termes</CustomH2>
            <p>
                Podem modificar aquests Termes en qualsevol moment. Publicarem
                els Termes modificats a aquesta pàgina i actualitzarem la data
                d'entrada en vigor. L'ús continuat del Servei després de
                qualsevol modificació constitueix l'acceptació dels Termes
                modificats.
            </p>

            <CustomH2>3. Registre de Compte</CustomH2>
            <p>
                Per utilitzar algunes funcions del nostre Servei, pot ser
                necessari registrar-se i crear un compte. Vostè és responsable
                de mantenir la confidencialitat de les credencials del seu
                compte i de totes les activitats que es realitzin sota el seu
                compte.
            </p>

            <CustomH2>4. Ús Acceptable</CustomH2>
            <p>
                Vostè accepta utilitzar el Servei només per a fins legals i
                d'acord amb aquests Termes. Vostè no utilitzarà el Servei per a:
            </p>
            <ul>
                <li>Violar qualsevol llei o regulació aplicable</li>
                <li>Infringir els drets de propietat intel·lectual d'altres</li>
                <li>Transmetre material il·lícit, ofensiu o perjudicial</li>
            </ul>

            <CustomH2>5. Limitació de Responsabilitat</CustomH2>
            <p>
                El Servei es proporciona "tal com és" i "segons disponibilitat".
                No garantim que el Servei serà ininterromput o lliure d'errors.
                En la mesura permesa per la llei, declinem totes les garanties,
                expressades o implícites.
            </p>

            <CustomH2>6. Indemnització</CustomH2>
            <p>
                Vostè accepta indemnitzar i mantenir indemne Wise Owl de
                qualsevol reclamació, dany, pèrdua o despesa (inclosos honoraris
                legals) derivats del seu ús del Servei o de la seva violació
                d'aquests Termes.
            </p>

            <CustomH2>7. Legislació Aplicable</CustomH2>
            <p>
                Aquests Termes es regiran i interpretaran d'acord amb les lleis
                d'Espanya. Qualsevol disputa derivada d'aquests Termes es
                sotmetrà a la jurisdicció exclusiva dels tribunals de Barcelona.
            </p>

            <CustomH2>8. Contacte</CustomH2>
            <p>
                Si té alguna pregunta sobre aquests Termes, pot contactar-nos a
                hola@wiseowl.cat.
            </p>
        </ContainerWithMaxWidth>
    );
};

export const TermsAndConditions = ({
    onClickBack,
}: {
    onClickBack?: () => void;
}) => {
    const navigate = useNavigate();
    const { isSmall } = useContext(ScreenSizeContext);
    const intl = useIntl();
    const goBack = useString("back2");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let TitleComponent = BigHeader;
    if (isSmall) TitleComponent = Header;
    return (
        <Spacer
            left={isSmall ? 2 : 4}
            right={isSmall ? 2 : 10}
            top={isSmall ? 2 : 4}
            bottom={isSmall ? 2 : 4}
        >
            <Clickable
                onClick={() => (onClickBack ? onClickBack() : navigate(-1))}
            >
                <Flex>
                    <img
                        src={ArrowLeft}
                        alt="Click here to go back to the previous page"
                    />
                    <Spacer right={1} />
                    {goBack}
                </Flex>
            </Clickable>
            <Spacer top={4} />
            <Flex direction="column">
                <ContainerWithMaxWidth>
                    <TitleComponent>
                        <FormattedMessage
                            id="TermsAndConditions"
                            defaultMessage="Terms & Conditions"
                        />
                    </TitleComponent>
                </ContainerWithMaxWidth>
                <Spacer top={3} />
                {intl.locale.substring(0, 2) === "ca" ? (
                    <CatalanTermsAndConditions />
                ) : intl.locale.substring(0, 2) === "es" ? (
                    <SpanishTermsAndConditions />
                ) : (
                    <EnglishTermsAndConditions />
                )}
            </Flex>
        </Spacer>
    );
};
