import styled from "styled-components";

const Flex = styled.div<{
    direction?: "column" | "row" | "column-reverse" | "row-reverse";
    justifyContent?:
        | "center"
        | "space-between"
        | "center"
        | "end"
        | "start"
        | "space-around";
    alignItems?: "center" | "auto" | "baseline" | "end" | "start";
    grow?: number;
    height?: string;
    width?: string;
    maxWidth?: string;
    flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
    gap?: number;
}>`
    display: flex;
    flex-direction: ${(props) => (props.direction ? props.direction : "row")};
    justify-content: ${(props) =>
        props.justifyContent ? props.justifyContent : "baseline"};
    align-items: ${(props) => (props.alignItems ? props.alignItems : "auto")};
    flex-grow: ${(props) => props.grow ?? "0"};
    flex-shrink: ${(props) => (props.grow ? "1" : undefined)};
    flex-basis: ${(props) => (props.grow ? "0" : "1")};
    height: ${(props) => props.height ?? "inherit"};
    width: ${(props) => props.width ?? "inherit"};
    max-width: ${(props) => props.maxWidth ?? undefined};
    flex-wrap: ${(props) => props.flexWrap ?? "nowrap"};
    gap: ${(props) => (props.gap ? `${props.gap * 8}px` : undefined)};
`;

export default Flex;
