import styled from "styled-components";

const Header = styled.h1<{
    color?: string;
    fontWeight?: string;
    fontSize?: string;
}>`
    font-family: "Inter", Helvetica;
    font-weight: ${(props) => props.fontWeight ?? "bold"};
    font-size: ${(props) => props.fontSize ?? "30px"};
    color: ${(props) => (props.color ? props.color : "")};
    letter-spacing: -0.5px;
    line-height: 1.1;
`;

export default Header;
