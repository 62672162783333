import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Task from "../components/Task";
import Spacer from "../components/Spacer";
import Flex from "../components/Flex";
import { InputState } from "../models/constants";
import { FormattedDate } from "react-intl";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import ArrowRight from "../images/arrowRight.svg";
import { FormattedMessage } from "react-intl";
import Header from "../components/Header";
import { CLOUDFRONT_DOMAIN, getFullTasksForCurrentDay } from "../utils/utils";
import CookiesModal from "./CookiesModal";
import { areCookiesAccepted } from "../models/persistanceAPI";
import { FIRST_LAYER_COLOR, SECOND_LAYER_COLOR, TEXT_COLOR, TOP_LAYER_COLOR } from "../utils";
import { Footer } from "./Footer";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { FloatingMenu } from "../components/FloatingMenu";
import useDay from "../models/useDayReducer";
import { UserConfigurationModal } from "../components/UserConfigurationModal";
import { Spinner } from "../components/Spinner";
import { ButtonLink } from "../components/ButtonLink";
import { Slide } from "react-awesome-reveal";
import { ImageIcon } from "../components/ImageIcon";
import BlackCross from "../images/blackCross.svg";
import WhiteCross from "../images/whiteCross.svg";
import { UserAPI } from "../api/api";
import UserContext from "../contexts/UserContext";
import { User } from "../models/types";
import { Calendar } from "../components/Calendar";
import { useNavigate, useSearchParams } from "react-router-dom";
import FormattedText from "../components/FormattedText";
import { StyledRoundButton } from "../components/StyledRoundButton";
import { SearchBox } from "../components/SearchBox";

const StrongText = styled.div`
    font-size: 20px;
    font-family: "Inter";
`;

// PAST CONFIG that generated a bug: height: calc(100vh - 112px); && no min-height
const DayTasksContainer = styled.div`
    background: ${FIRST_LAYER_COLOR};
    height: auto;
    min-height: 86vh;
    border-radius: 8px;
`;

const DayTasksInnerContainer = styled.div`
    background: ${FIRST_LAYER_COLOR};
    height: auto;
    border-radius: 8px;
`;

const SmallDeviceButtonContainer = styled.div`
    min-width: 50px;
    min-height: 50px;
`;

const StyledBanner = styled.div<{ color?: string }>`
    background-color: ${(props) => props.color ?? "white"};
`;

const StyledButtonContainer = styled(Flex)<{isSmall?: boolean}>`
    padding: ${(props) => props.isSmall ? "0px":"10px"};
    border-radius: 8px;
    background-color: ${SECOND_LAYER_COLOR};

    &:hover {
        background-color: ${FIRST_LAYER_COLOR};
    }
    
    &:active {
        background-color: ${TOP_LAYER_COLOR};
    }

`;

const GoBackOneDayText = ({ diffWithToday }: { diffWithToday: number }) => {
    switch (diffWithToday) {
        case 0:
            return (
                <FormattedMessage id="yesterday" defaultMessage="yesterday" />
            );
        case 1:
            return <FormattedMessage id="today" defaultMessage="today" />;
        case 2:
            return <FormattedMessage id="tomorrow" defaultMessage="tomorrow" />;
        default:
            return (
                <FormattedMessage id="day_before" defaultMessage="day before" />
            );
    }
};

const GoForwardOneDayText = ({ diffWithToday }: { diffWithToday: number }) => {
    switch (diffWithToday) {
        case 0:
            return <FormattedMessage id="tomorrow" defaultMessage="tomorrow" />;
        case -1:
            return <FormattedMessage id="today" defaultMessage="today" />;
        case -2:
            return (
                <FormattedMessage id="yesterday" defaultMessage="yesterday" />
            );
        default:
            return <FormattedMessage id="next_day" defaultMessage="next day" />;
    }
};

const VerifyEmailBanner = ({
    setShouldShowVerifyEmailBanner,
}: {
    setShouldShowVerifyEmailBanner: (newValue: boolean) => void;
}) => {
    return (
        <Slide direction="down" duration={600}>
            <StyledBanner>
                <Spacer top={2} left={3} bottom={2} right={3}>
                    <Flex alignItems="center">
                        <Clickable
                            onClick={() =>
                                setShouldShowVerifyEmailBanner(false)
                            }
                        >
                            <Flex>
                                <ImageIcon
                                    src={BlackCross}
                                    alt="Close"
                                    maxSize="15px"
                                />
                            </Flex>
                        </Clickable>
                        <Spacer right={2} />
                        <Flex alignItems="center">
                            <FormattedMessage
                                id="rememberVerifyEmail"
                                defaultMessage="Please, verify your email in case you need to recover your password"
                            />
                            <Spacer right={2} />
                            <ButtonLink
                                onClick={() => {
                                    UserAPI.resendVerificationEmail();
                                    setShouldShowVerifyEmailBanner(false);
                                }}
                            >
                                <FormattedMessage
                                    id="resendVerificationEmail"
                                    defaultMessage="Resend verification email"
                                />
                            </ButtonLink>
                        </Flex>
                    </Flex>
                </Spacer>
            </StyledBanner>
        </Slide>
    );
};

const SuccessSubscriptionBanner = ({
    setShouldShowSuccessBanner,
}: {
    setShouldShowSuccessBanner: (newValue: boolean) => void;
}) => {
    return (
        <Slide direction="down" duration={600}>
            <StyledBanner color="green">
                <Spacer top={2} left={3} bottom={2} right={3}>
                    <Flex alignItems="center">
                        <Clickable
                            onClick={() => {
                                setShouldShowSuccessBanner(false);
                            }}
                        >
                            <Flex>
                                <ImageIcon
                                    src={WhiteCross}
                                    alt="Close"
                                    maxSize="15px"
                                />
                            </Flex>
                        </Clickable>
                        <Spacer right={2} />
                        <FormattedText color="white" weight="300" size="20px">
                            <FormattedMessage
                                id="successSubscription"
                                defaultMessage="Congratulations, you're now a premium user of Wise Owl. We hope it makes your daily work easier. Enjoy!"
                            />
                        </FormattedText>
                    </Flex>
                </Spacer>
            </StyledBanner>
        </Slide>
    );
};

const TrialRemaningBanner = ({
    setShouldShowTrialBanner,
    daysRemaining,
}: {
    setShouldShowTrialBanner: (newValue: boolean) => void;
    daysRemaining: number;
}) => {
    const navigate = useNavigate();
    const { isSmall } = useContext(ScreenSizeContext);
    return (
        <Slide direction="down" duration={600}>
            <StyledBanner color="green">
                <Spacer top={2} left={3} bottom={2} right={3}>
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        direction={isSmall ? "column" : "row"}
                    >
                        <Flex alignItems="center">
                            <Clickable
                                onClick={() => {
                                    setShouldShowTrialBanner(false);
                                }}
                            >
                                <Flex>
                                    <ImageIcon
                                        src={WhiteCross}
                                        alt="Close"
                                        maxSize="15px"
                                    />
                                </Flex>
                            </Clickable>
                            <Spacer right={2} />

                            <FormattedText
                                color="white"
                                weight="300"
                                size="20px"
                            >
                                <FormattedMessage
                                    id="trialTimeRemaining"
                                    defaultMessage="You still have {daysRemaining} days remaining of free trial. Enjoy!"
                                    values={{ daysRemaining }}
                                />
                            </FormattedText>
                        </Flex>
                        {isSmall && <Spacer top={2} />}
                        <Slide direction="right" duration={650}>
                            <StyledRoundButton
                                onClick={() => navigate("/subscribe")}
                            >
                                <Spacer top={1} bottom={1} left={1} right={1}>
                                    <FormattedText color="green" weight="bold">
                                        <FormattedMessage
                                            id="subscribeNowWithDiscount"
                                            defaultMessage="Subscribe now with 30% off"
                                        />
                                    </FormattedText>
                                </Spacer>
                            </StyledRoundButton>
                        </Slide>
                    </Flex>
                </Spacer>
            </StyledBanner>
        </Slide>
    );
};

const PremiumDay = () => {
    const isPremium = true;
    const {
        state,
        updateTextForTask,
        updateStateForTask,
        pasteTextInTask,
        goForwardOneDay,
        goBackOneDay,
        goToToday,
        sort,
        bulkAction,
        isLoadingForPremium,
        goToDate,
    } = useDay(isPremium);
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const success: string | null = searchParams.get("success");

    const { isSmall, isMedium } = useContext(ScreenSizeContext);
    const [user, setUser] = useState<User | null>(null);

    const [shouldShowVerifyEmailBanner, setShouldShowVerifyEmailBanner] =
        useState(false);
    const [shouldShowTrialBanner, setShouldShowTrialBanner] = useState(false);
    const [searchText, setSearchText] = useState<string|null>(null);
    const [shouldShowSuccessBanner, setShouldShowSuccessBanner] = useState(
        success === "true"
    );

    // this piece of states indicates how many days you have to add or substract to get to today
    const [diffWithToday, setDiffWithToday] = useState(0);
    const date = state.currentDate;
    const logo = `${CLOUDFRONT_DOMAIN}/owl.png`;

    const goBackOneDayHandler = () => {
        setDiffWithToday(diffWithToday - 1);
        goBackOneDay();
    };

    const goForwardOneDayHandler = () => {
        setDiffWithToday(diffWithToday + 1);
        goForwardOneDay();
    };

    const goBackToToday = () => {
        setDiffWithToday(0);
        goToToday();
    };

    const tasks = getFullTasksForCurrentDay(state);
    const [shouldShowCookiesModal, setShouldShowCookiesModal] = useState(
        !areCookiesAccepted()
    );

    const [
        shouldShowUserConfigurationModal,
        setShouldShowUserConfigurationModal,
    ] = useState(false);

    useEffect(() => {
        UserAPI.getUserDetails().then((user) => {
            setUser(user.data);
            if (user && !user.data.is_email_verified) {
                setShouldShowVerifyEmailBanner(true);
            }
            if (
                user &&
                user.data.free_trial_days_remaining > 0 &&
                !user.data.has_paying_subscription
            ) {
                setShouldShowTrialBanner(true);
            }
        });
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }
        if (
            user?.free_trial_days_remaining <= 0 &&
            !user.has_paying_subscription
        ) {
            navigate("/subscribe");
        }
    }, [user, navigate]);

    const filteredTasks = tasks.filter((task) => {
        const text = state.agenda.tasks && state.agenda.tasks[task.id] ? state.agenda.tasks[task.id].text : ""
        if (searchText && !text.toLowerCase().includes(searchText.toLowerCase())) {
            return false;
        }
        return true;
    })

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {shouldShowVerifyEmailBanner && (
                <VerifyEmailBanner
                    setShouldShowVerifyEmailBanner={
                        setShouldShowVerifyEmailBanner
                    }
                />
            )}
            {shouldShowSuccessBanner && (
                <SuccessSubscriptionBanner
                    setShouldShowSuccessBanner={setShouldShowSuccessBanner}
                />
            )}
            {!shouldShowVerifyEmailBanner &&
                shouldShowTrialBanner &&
                !shouldShowSuccessBanner &&
                user && (
                    <TrialRemaningBanner
                        setShouldShowTrialBanner={setShouldShowTrialBanner}
                        daysRemaining={user.free_trial_days_remaining}
                    />
                )}
            <Flex direction={isSmall ? "column" : "row"}>
                <Spacer
                    right={isSmall ? 0 : 3}
                    left={isSmall ? 0 : 3}
                    top={isSmall ? 2 : 3}
                    style={{ flexGrow: 1 }}
                >
                    <Flex justifyContent="space-between" alignItems="center">
                        <Clickable onClick={goBackOneDayHandler}>
                            <StyledButtonContainer alignItems="center" isSmall={isSmall}>
                                {isSmall && <Spacer left={1} />}
                                {isSmall ? (
                                    <SmallDeviceButtonContainer>
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            height="50px"
                                            width="50px"
                                        >
                                            <img
                                                src={ArrowLeft}
                                                alt="Click here to go to the previous day"
                                            />
                                        </Flex>
                                    </SmallDeviceButtonContainer>
                                ) : (
                                    <img
                                        src={ArrowLeft}
                                        alt="Click here to go to the previous day"
                                    />
                                )}

                                <Spacer right={0.5} />
                                {!isSmall && (
                                    <StrongText>
                                        <GoBackOneDayText
                                            diffWithToday={diffWithToday}
                                        />
                                    </StrongText>
                                )}
                            </StyledButtonContainer>
                        </Clickable>
                        {isSmall || isMedium ? (
                            <Flex alignItems="center">
                                <Clickable onClick={goBackToToday}>
                                    <img
                                        src={logo}
                                        alt="Wise owl logo"
                                        style={{
                                            width: "auto",
                                            height: "auto",
                                            maxWidth: "24px",
                                            objectFit: "contain",
                                            padding: "8px",
                                            background: TEXT_COLOR,
                                            borderRadius: "8px",
                                        }}
                                    />
                                </Clickable>
                                <Spacer right={1} />
                                <Calendar
                                    state={state}
                                    goToDate={goToDate}
                                    setDiffWithToday={setDiffWithToday}
                                />
                                <Spacer right={1} />
                                <Header>
                                    <FormattedDate value={date} />
                                </Header>
                            </Flex>
                        ) : (
                            <Flex alignItems="center">
                                <Clickable onClick={goBackToToday}>
                                    <img
                                        src={logo}
                                        alt="Wise owl logo"
                                        style={{
                                            width: "auto",
                                            height: "auto",
                                            maxWidth: "24px",
                                            objectFit: "contain",
                                            padding: "8px",
                                            background: TEXT_COLOR,
                                            borderRadius: "8px",
                                        }}
                                    />
                                </Clickable>
                                <Spacer right={1} />
                                <Calendar
                                    state={state}
                                    goToDate={goToDate}
                                    setDiffWithToday={setDiffWithToday}
                                />
                                <Spacer right={1} />
                                <Header>
                                    <FormattedDate
                                        value={date}
                                        weekday="long"
                                        year="numeric"
                                        month="long"
                                        day="numeric"
                                    />
                                </Header>
                            </Flex>
                        )}

                        <Clickable onClick={goForwardOneDayHandler}>
                            <StyledButtonContainer alignItems="center" isSmall={isSmall}>
                                {!isSmall && (
                                    <StrongText>
                                        <GoForwardOneDayText
                                            diffWithToday={diffWithToday}
                                        />
                                    </StrongText>
                                )}
                                <Spacer right={0.5} />
                                {isSmall ? (
                                    <SmallDeviceButtonContainer>
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            height="50px"
                                            width="50px"
                                        >
                                            <img
                                                src={ArrowRight}
                                                alt="Click here to go to the next day"
                                            />
                                        </Flex>
                                    </SmallDeviceButtonContainer>
                                ) : (
                                    <img
                                        src={ArrowRight}
                                        alt="Click here to go to the next day"
                                    />
                                )}

                                {isSmall && <Spacer right={1} />}
                            </StyledButtonContainer>
                        </Clickable>
                    </Flex>
                    <Spacer bottom={isSmall ? 1 : 2} />
                    <DayTasksContainer>
                        <DayTasksInnerContainer>
                            {
                                isSmall && (
                                    <SearchBox 
                                    text={searchText}
                                    setText={setSearchText}
                                />
                                )
                            }
                            <Spacer right={5} left={5} top={isSmall ? 0: 5} bottom={3}>
                                {
                                    !isSmall && (
                                        <SearchBox 
                                        text={searchText}
                                        setText={setSearchText}
                                    />
                                    )
                                }
                                <Spacer top={2} />
                                {/* Also interesting: https://github.com/dvtng/react-loading-skeleton#readme */}
                                {isPremium && isLoadingForPremium ? (
                                    <>
                                        <Spacer top={10} />
                                        <Flex justifyContent="center">
                                            <Spinner size={"70px"} />
                                        </Flex>
                                    </>
                                ) : (
                                    <>
                                        {tasks.map((task, index) => {
                                            // LAZY: I could use filteredTasks here, but then the indexes where we create new tasks are messed up
                                            // Fix it, if it is a performance issue.
                                            const text = state.agenda.tasks && state.agenda.tasks[task.id] ? state.agenda.tasks[task.id].text : "";
                                            if (searchText && !text.toLowerCase().includes(searchText.toLowerCase())) {
                                                return null;
                                            }
                                            return (
                                                <Spacer
                                                    bottom={1}
                                                    key={task.id}
                                                >
                                                    <Task
                                                        id={task.id}
                                                        key={task.id}
                                                        text={text}
                                                        state={task.state}
                                                        changeState={(
                                                            newState: InputState
                                                        ) =>
                                                            updateStateForTask(
                                                                newState,
                                                                index
                                                            )
                                                        }
                                                        onTextChange={(
                                                            newText: string
                                                        ) =>
                                                            updateTextForTask(
                                                                newText,
                                                                index,
                                                                searchText ?? undefined,
                                                            )
                                                        }
                                                        onTextPaste={(
                                                            pastedText: string,
                                                            atIndex?: number
                                                        ) => {
                                                            pasteTextInTask(
                                                                pastedText,
                                                                index,
                                                                atIndex
                                                            );
                                                        }}
                                                        shouldAutofocus={/* searchText === null */ true}
                                                    />
                                                </Spacer>
                                            );
                                        })}
                                        {
                                            filteredTasks.length === 0 && !!searchText && 0 < searchText.length && (
                                                <Spacer top={4}>
                                                <Flex justifyContent="center">
                                                    <FormattedText size="18px" weight="300">
                                                        <FormattedMessage
                                                            id="noTasks"
                                                            defaultMessage="No tasks found"
                                                        />
                                                    </FormattedText>
                                                </Flex>
                                                </Spacer>
                                            )
                                        }
                                    </>
                                )}
                            </Spacer>
                        </DayTasksInnerContainer>
                    </DayTasksContainer>
                </Spacer>
            </Flex>
            {shouldShowCookiesModal && (
                <CookiesModal
                    hideCookiesModal={() => setShouldShowCookiesModal(false)}
                />
            )}
            <Spacer top={2} />
            {!isSmall && !shouldShowCookiesModal && (
                <FloatingMenu
                    currentDate={state.currentDate}
                    sortTasks={sort}
                    bulkAction={bulkAction}
                    searchText={searchText}
                />
            )}
            <Spacer top={2} />
            <Footer
                isSmall={isSmall}
                skipMoreInfoSection={false}
                paddingRight={isSmall ? 1 : 3}
                paddingLeft={isSmall ? 1 : 3}
                paddingTop={isSmall ? 2 : 3}
                paddingBot={isSmall ? 2 : 3}
                isPremium={isPremium}
                showUserConfigurationModal={() =>
                    setShouldShowUserConfigurationModal(true)
                }
            />
            {isPremium && shouldShowUserConfigurationModal && (
                <UserConfigurationModal
                    closeModal={() =>
                        setShouldShowUserConfigurationModal(false)
                    }
                />
            )}
        </UserContext.Provider>
    );
};

export default PremiumDay;
