import { useContext, useEffect } from "react";
import BigHeader from "../components/BigHeader";
import Flex from "../components/Flex";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { useNavigate } from "react-router-dom";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { useString } from "../hooks/useString";

const ContainerWithMaxWidth = styled.div`
    max-width: 800px;
    margin: auto;
`;

const CustomH2 = styled.h2`
    padding-top: 16px;
    padding-bottom: 4px;
`;
const EnglishLegalNotice = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Legal Notice of Wise Owl</CustomH2>
            <p>Effective Date: May 14th, 2024</p>

            <CustomH2>1. Legal Information</CustomH2>
            <p>
                Wise Owl is an online platform for task management. The use of
                this platform is subject to the terms and conditions of this
                legal notice. By accessing and using this platform, you agree to
                be bound by these terms and conditions. If you do not agree with
                any part of this legal notice, do not use the platform.
            </p>

            <CustomH2>2. Intellectual Property</CustomH2>
            <p>
                All intellectual property rights related to this platform,
                including copyrights and trademarks, belong to Wise Owl. You are
                not authorized to use this intellectual property without the
                express consent of Wise Owl.
            </p>

            <CustomH2>3. Disclaimer of Liability</CustomH2>
            <p>
                Wise Owl is not liable for any damage or loss arising from the
                use of this platform. All use of the platform is at your own
                risk.
            </p>

            <CustomH2>4. Changes to the Legal Notice</CustomH2>
            <p>
                Wise Owl reserves the right to modify this legal notice at any
                time. Changes will be communicated to you through a notification
                on this platform. Continuing to use the platform after the
                changes take effect will constitute acceptance of those changes.
            </p>

            <CustomH2>5. Contact</CustomH2>
            <p>
                If you have any questions about this legal notice, you can
                contact us at hola@wiseowl.cat.
            </p>
        </ContainerWithMaxWidth>
    );
};

const SpanishLegalNotice = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Aviso Legal de Wise Owl</CustomH2>
            <p>Fecha de entrada en vigor: 14 de mayo de 2024</p>

            <CustomH2>1. Información Legal</CustomH2>
            <p>
                Wise Owl es una plataforma en línea para la gestión de tareas.
                El uso de esta plataforma está sujeto a los términos y
                condiciones de este aviso legal. Al acceder y utilizar esta
                plataforma, acepta quedar vinculado por estos términos y
                condiciones. Si no está de acuerdo con cualquier parte de este
                aviso legal, no utilice la plataforma.
            </p>

            <CustomH2>2. Propiedad Intelectual</CustomH2>
            <p>
                Todos los derechos de propiedad intelectual relacionados con
                esta plataforma, incluidos los derechos de autor y las marcas
                registradas, pertenecen a Wise Owl. No está autorizado a
                utilizar esta propiedad intelectual sin el consentimiento
                expreso de Wise Owl.
            </p>

            <CustomH2>3. Exclusión de Responsabilidad</CustomH2>
            <p>
                Wise Owl no se hace responsable de ningún daño o perjuicio
                derivado del uso de esta plataforma. Todo el uso de la
                plataforma se realiza bajo su propio riesgo.
            </p>

            <CustomH2>4. Cambios al Aviso Legal</CustomH2>
            <p>
                Wise Owl se reserva el derecho de modificar este aviso legal en
                cualquier momento. Se le comunicarán los cambios mediante una
                notificación en esta plataforma. La continuación del uso de la
                plataforma después de la entrada en vigor de los cambios
                constituirá la aceptación de dichos cambios.
            </p>

            <CustomH2>5. Contacto</CustomH2>
            <p>
                Si tiene alguna pregunta sobre este aviso legal, puede contactar
                con nosotros en hola@wiseowl.cat.
            </p>
        </ContainerWithMaxWidth>
    );
};

const CatalanLegalNotice = () => {
    return (
        <ContainerWithMaxWidth>
            <CustomH2>Avís Legal de Wise Owl</CustomH2>
            <p>Data d'entrada en vigor: 14 de maig del 2024</p>

            <CustomH2>1. Informació Legal</CustomH2>
            <p>
                Wise Owl és una plataforma en línia per a la gestió de tasques.
                L'ús d'aquesta plataforma està subjecte als termes i condicions
                d'aquest avís legal. En accedir i utilitzar aquesta plataforma,
                accepteu estar vinculats per aquests termes i condicions. Si no
                esteu d'acord amb qualsevol part d'aquest avís legal, no feu
                servir la plataforma.
            </p>

            <CustomH2>2. Propietat Intel·lectual</CustomH2>
            <p>
                Tots els drets de propietat intel·lectual relacionats amb
                aquesta plataforma, inclosos els drets d'autor i les marques
                registrades, pertanyen a Wise Owl. No esteu autoritzats a
                utilitzar aquesta propietat intel·lectual sense el consentiment
                exprés de Wise Owl.
            </p>

            <CustomH2>3. Exclusió de Responsabilitat</CustomH2>
            <p>
                Wise Owl no es fa responsable de cap dany o perjudici derivat de
                l'ús d'aquesta plataforma. Tot l'ús de la plataforma es fa sota
                el vostre propi risc.
            </p>

            <CustomH2>4. Canvis a l'Avís Legal</CustomH2>
            <p>
                Wise Owl es reserva el dret de modificar aquest avís legal en
                qualsevol moment. S'us comunicaran els canvis mitjançant una
                notificació en aquesta plataforma. La continuació de l'ús de la
                plataforma després de l'entrada en vigor dels canvis constituirà
                l'acceptació d'aquests canvis.
            </p>

            <CustomH2>5. Contacte</CustomH2>
            <p>
                Si teniu alguna pregunta sobre aquest avís legal, podeu
                contactar amb nosaltres a hola@wiseowl.cat.
            </p>
        </ContainerWithMaxWidth>
    );
};

export const LegalNotice = () => {
    const navigate = useNavigate();
    const { isSmall } = useContext(ScreenSizeContext);
    const intl = useIntl();

    const goBack = useString("back2");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let TitleComponent = BigHeader;
    if (isSmall) TitleComponent = Header;
    return (
        <Spacer
            left={isSmall ? 2 : 4}
            right={isSmall ? 2 : 10}
            top={isSmall ? 2 : 4}
            bottom={isSmall ? 2 : 4}
        >
            <Clickable onClick={() => navigate(-1)}>
                <Flex>
                    <img
                        src={ArrowLeft}
                        alt="Click here to go back to the previous page"
                    />
                    <Spacer right={1} />
                    {goBack}
                </Flex>
            </Clickable>
            <Spacer top={4} />
            <Flex direction="column">
                <ContainerWithMaxWidth>
                    <TitleComponent>
                        <FormattedMessage
                            id="legalNotice"
                            defaultMessage="Legal Notice"
                        />
                    </TitleComponent>
                </ContainerWithMaxWidth>
                <Spacer top={3} />
                {intl.locale.substring(0, 2) === "ca" ? (
                    <CatalanLegalNotice />
                ) : intl.locale.substring(0, 2) === "es" ? (
                    <SpanishLegalNotice />
                ) : (
                    <EnglishLegalNotice />
                )}
            </Flex>
        </Spacer>
    );
};
