import { InputState } from "../models/constants";
import {
    DayFromAPI,
    TaskFromAPI,
    TaskInDayFromAPI,
    TypeWithStatus,
} from "./adapterTypes";
import { dateToYYYYMMDDString } from "../utils";
import { Plan, User } from "../models/types";
import { loadAppData } from "../models/persistanceAPI";
import { createEmptyAppData } from "../models/useDayReducer";
import throttle from "lodash.throttle";
import { invalidateTokenSingleton } from "./invalidateTokenSingleton";

export const isDevelopmentMode = process.env.NODE_ENV === "development";
const API_URL = isDevelopmentMode
    ? "http://localhost:8000"
    : "https://api.wiseowl.cat";
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";

// this method handles the fetch request and if the token is expired it
// will refresh the token and then make the request again

export const fetchWithRefresh = async (
    ...params: Parameters<typeof fetch>
): Promise<TypeWithStatus<any>> => {
    const paramsWithIncludeCookiesHeader = params;
    if (paramsWithIncludeCookiesHeader[1]) {
        paramsWithIncludeCookiesHeader[1] = {
            ...paramsWithIncludeCookiesHeader[1],
            credentials: "include",
            mode: "cors",
        };
    } else {
        paramsWithIncludeCookiesHeader[1] = {
            credentials: "include",
            mode: "cors",
        };
    }
    const response = await fetch(...paramsWithIncludeCookiesHeader);
    if (response.ok) {
        if (response.status === 204) {
            // No content
            return { data: response, status: 204 };
        }
        return { data: await response.json(), status: response.status };
    }
    if (response.status === 400) {
        return { data: undefined, status: 400 };
    }
    const processedResponse = await response.json();
    if (
        processedResponse.code === "token_not_valid" ||
        response.status === 401
    ) {
        await AuthorizationAPI.refreshToken();
        const newParams = params;
        if (newParams[1]) {
            newParams[1] = {
                ...params[1],
                credentials: "include",
                mode: "cors",
            };
        } else {
            newParams[1] = {
                credentials: "include",
                mode: "cors",
            };
        }
        const responseInRetry = await fetch(...newParams);
        if (responseInRetry.status === 401) {
            AuthorizationAPI.logOut();
            invalidateTokenSingleton();
        }
        return {
            data: await responseInRetry.json(),
            status: responseInRetry.status,
        };
    }
    return { data: processedResponse, status: response.status };
};

export class AuthorizationAPI {
    static logIn = async (
        email: string,
        password: string
    ): Promise<{ access: string; refresh: string; status: number }> => {
        const response = await fetch(`${API_URL}/api/login/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({ username: email, password }),
            credentials: "include",
            mode: "cors",
        });
        const { access, refresh } = await response.json();
        if (response.ok) {
            AuthorizationAPI.storeValidTokens(access, refresh);
        }
        return { access, refresh, status: response.status };
    };

    static refreshToken = async (): Promise<void> => {
        await fetch(`${API_URL}/api/token/refresh/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({}),
            credentials: "include",
        });
    };

    static logOut = async (): Promise<void> => {
        await fetch(`${API_URL}/api/logout/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
            credentials: "include",
        });

        AuthorizationAPI.invalidateTokens();
    };

    private static invalidateTokens = () => {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
    };
    private static storeValidTokens = (
        accessToken: string,
        refreshToken: string
    ) => {
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
    };
}

export class UserAPI {
    static getUserDetails = async (): Promise<TypeWithStatus<User>> => {
        return await fetchWithRefresh(`${API_URL}/app/user/`);
    };

    static signUp = async (
        email: string,
        password: string,
        locale: string
    ): Promise<TypeWithStatus<User>> => {
        const response = await fetch(`${API_URL}/app/user/create/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password, locale }),
        });
        const data = await response.json();
        return { data, status: response.status };
    };

    static verifyUser = async (user_id: string, hash: string) => {
        return await fetch(`${API_URL}/app/verify-email/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_id, token: hash }),
        });
    };

    static resendVerificationEmail = async () => {
        return await fetchWithRefresh(`${API_URL}/app/resend-email/`, {
            method: "POST",
        });
    };

    static updateUserPassword = async (newPassword: string) => {
        const response = await fetchWithRefresh(`${API_URL}/app/user/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ password: newPassword }),
        });
        return response;
    };

    static deleteUser = async () => {
        return await fetchWithRefresh(`${API_URL}/app/user/`, {
            method: "DELETE",
        });
    };

    static importAgenda = async () => {
        let appData = loadAppData();
        if (!appData) {
            appData = createEmptyAppData();
        }
        return await fetchWithRefresh(`${API_URL}/app/import-agenda/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(appData.agenda),
        });
    };

    static resetPasswordPetition = async (email: string) => {
        return await fetchWithRefresh(`${API_URL}/app/reset-password/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
        });
    };

    static resetPassword = async (
        user_id: string,
        hash: string,
        password: string
    ) => {
        return await fetchWithRefresh(`${API_URL}/app/reset-password/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_id, token: hash, password }),
        });
    };
}

export class PlanAPI {
    static getUserPlan = async (): Promise<TypeWithStatus<Plan>> => {
        return await fetchWithRefresh(`${API_URL}/app/plan/`);
    };

    static updateUserPlan = async (
        plan: string
    ): Promise<TypeWithStatus<Plan>> => {
        return await fetchWithRefresh(`${API_URL}/app/plan/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ plan }),
        });
    };
}

export class TaskAPI {
    static getTask = async (
        id: string
    ): Promise<TypeWithStatus<TaskFromAPI> | undefined> => {
        return await fetchWithRefresh(`${API_URL}/app/task/${id}/`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    };

    static createTask = async (
        text: string
    ): Promise<TypeWithStatus<TaskFromAPI>> => {
        return await fetchWithRefresh(`${API_URL}/app/task/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ text }),
        });
    };

    static updateTaskUnthorttled = async (
        id: string | undefined,
        text: string
    ): Promise<TypeWithStatus<TaskFromAPI> | undefined> => {
        if (!id) {
            return undefined;
        }
        return await fetchWithRefresh(`${API_URL}/app/task/${id}/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ text }),
        });
    };

    static updateTask = throttle(TaskAPI.updateTaskUnthorttled, 500);

    static deleteTask = async (id: string) => {
        return await fetchWithRefresh(`${API_URL}/app/task/${id}/`, {
            method: "DELETE",
        });
    };

    static getTasks = async (): Promise<TypeWithStatus<TaskFromAPI[]>> => {
        return await fetchWithRefresh(`${API_URL}/app/tasks/`);
    };
}

export class TaskInDayAPI {
    static getTaskInDay = async (
        task_id: string,
        day_date: Date
    ): Promise<TypeWithStatus<TaskInDayFromAPI> | undefined> => {
        const date = dateToYYYYMMDDString(day_date);
        return await fetchWithRefresh(
            `${API_URL}/app/task-in-day/${task_id}/${date}/`
        );
    };

    static createTaskInDay = async (
        task_id: string,
        day_date: Date,
        index?: number
    ): Promise<TypeWithStatus<TaskInDayFromAPI>> => {
        const date = dateToYYYYMMDDString(day_date);
        return await fetchWithRefresh(
            `${API_URL}/app/task-in-day/${task_id}/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ day_date: date, index }),
            }
        );
    };

    static updateTaskInDay = async (
        task_id: string,
        day_date: Date,
        new_status: InputState
    ): Promise<TypeWithStatus<TaskInDayFromAPI>> => {
        const date = dateToYYYYMMDDString(day_date);
        return await fetchWithRefresh(
            `${API_URL}/app/task-in-day/${task_id}/`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ day_date: date, new_status }),
            }
        );
    };

    static deleteTaskInDay = async (task_id: string, day_date: Date) => {
        const date = dateToYYYYMMDDString(day_date);
        return await fetchWithRefresh(
            `${API_URL}/app/task-in-day/${task_id}/`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ day_date: date }),
            }
        );
    };

    static getTasksInDay = async (
        day_date: Date
    ): Promise<TypeWithStatus<TaskInDayFromAPI[]>> => {
        const date = dateToYYYYMMDDString(day_date);
        return await fetchWithRefresh(`${API_URL}/app/tasks-in-day/${date}/`);
    };

    static updateTasksInDay = async (
        day_date: Date,
        task_ids: string[],
        new_status: InputState
    ): Promise<TypeWithStatus<TaskInDayFromAPI[]>> => {
        const date = dateToYYYYMMDDString(day_date);
        return await fetchWithRefresh(`${API_URL}/app/tasks-in-day/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ day_date: date, task_ids, new_status }),
        });
    };
}

export class DayAPI {
    static getDay = async (
        day_date: Date
    ): Promise<undefined | TypeWithStatus<DayFromAPI>> => {
        const date = dateToYYYYMMDDString(day_date);
        return await fetchWithRefresh(`${API_URL}/app/day/${date}/`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    };

    static createDay = async (
        day_date: Date
    ): Promise<TypeWithStatus<DayFromAPI>> => {
        const date = dateToYYYYMMDDString(day_date);
        return await fetchWithRefresh(`${API_URL}/app/day/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                day_date: date,
                pending_tasks_passed: false,
            }),
        });
    };

    static updateDay = async (
        day_date: Date,
        pending_tasks_passed: boolean,
        should_sort_day?: boolean
    ): Promise<TypeWithStatus<DayFromAPI>> => {
        const date = dateToYYYYMMDDString(day_date);
        return await fetchWithRefresh(`${API_URL}/app/day/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                day_date: date,
                pending_tasks_passed,
                should_sort_day,
            }),
        });
    };

    static getDays = async (): Promise<TypeWithStatus<DayFromAPI[]>> => {
        return await fetchWithRefresh(`${API_URL}/app/days/`);
    };
}

export class PaymentAPI {
    static createPayment = async () => {
        return await fetchWithRefresh(
            `${API_URL}/app/create-checkout-session/`,
            {
                method: "POST",
            }
        );
    };

    static cancelSubscription = async () => {
        return await fetchWithRefresh(`${API_URL}/app/cancel-subscription/`, {
            method: "POST",
        });
    };
}
