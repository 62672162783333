import { InputState } from "./constants";
import { v4 as uuidv4 } from "uuid";
import { Task, AppData, TaskInDay } from "./types";
import { clearAppData, shouldSkipWeekends } from "./persistanceAPI";
import { dateToDDMMYYString } from "../utils/utils";
import { Actions, ReducerMethods, newEmptyTask } from "./reducer";
import {
    TaskInDayAPIAdapter,
    TaskAPIAdapter,
    DayAPIAdapter,
} from "../api/apiAdapter";

export const loadFirstDayFromServer = async (state: AppData) => {
    // remove all the data stored on local
    // TODO: premium upsell
    clearAppData();

    // load all the tasks
    const tasks = await TaskAPIAdapter.getTasks();
    const tasksDict: { [key: string]: Task } = {};
    tasks.forEach((task) => {
        tasksDict[task.id] = task;
    });
    state.agenda.tasks = tasksDict;
    state.currentDate = new Date();
    // load the first day
    const date = state.currentDate;
    const days = await DayAPIAdapter.getDays();
    if (days.length === 0) {
        DayAPIAdapter.createDay(date);
        state.agenda.days[dateToDDMMYYString(date)] = {
            date,
            assignedTasks: [],
            pendingTasksPassed: false,
        };
    } else {
        for (const day of days) {
            state.agenda.days[dateToDDMMYYString(day.date)] = day;
        }
    }
    if (!(dateToDDMMYYString(state.currentDate) in state.agenda.days)) {
        DayAPIAdapter.createDay(date);
        state.agenda.days[dateToDDMMYYString(date)] = {
            date,
            assignedTasks: [],
            pendingTasksPassed: false,
        };
    } else {
        const tasksInDay = await TaskInDayAPIAdapter.getTasksInDay(
            state.currentDate
        );
        state.agenda.days[dateToDDMMYYString(state.currentDate)].assignedTasks =
            tasksInDay;
    }
    return { ...state };
};

const createTaskForPreviouslyUnexistingDayInLocal = (
    state: AppData,
    newState: AppData,
    pushToRemoteStack: (id: string) => void
) => {
    const wasDayEmpty =
        state.agenda.days[dateToDDMMYYString(newState.currentDate)] ===
        undefined;
    if (wasDayEmpty) {
        const newTask = newEmptyTask(Object.keys(newState.agenda.tasks));
        pushToRemoteStack(newTask.id);
        newState.agenda.days[
            dateToDDMMYYString(newState.currentDate)
        ].assignedTasks.push({
            id: newTask.id,
            state: InputState.EMPTY,
        });
        newState.agenda.tasks[newTask.id] = newTask;
    }
    return newState;
};

class PremiumReducerMethods {
    static createTask = async (
        state: AppData,
        pushToRemoteStack: (id: string) => void,
        findWaitingValueAndSubstitute: (
            waitingValue: string,
            newId: string
        ) => void,
        index?: number,
        date?: Date,
        text?: string,
        callback?: (newTaskInDay: TaskInDay) => void
    ) => {
        if (date === undefined) {
            return state;
        }
        const newQueueID = "remote" + uuidv4();
        pushToRemoteStack(newQueueID);
        TaskAPIAdapter.createTask(text ?? "")
            .then((task) =>
                TaskInDayAPIAdapter.createTaskInDay(task.id, date, index)
            )
            .then((taskInDay) => {
                findWaitingValueAndSubstitute(newQueueID, taskInDay.id);
                if (callback !== undefined) {
                    callback(taskInDay);
                }
            });
    };

    static deleteTask = async (
        state: AppData,
        pushToRemoteStack: (id: string) => void,
        findWaitingValueAndSubstitute: (
            waitingValue: string,
            newId: string
        ) => void,
        getRemoteIDFromLocalID: (localId: string) => string | undefined,
        taskId?: string
    ) => {
        if (taskId === undefined) {
            return state;
        }
        let finalTaskId = taskId;
        // @ts-ignore if we dont try this we won't get a good result of out isNaN
        if (isNaN(finalTaskId)) {
            finalTaskId = getRemoteIDFromLocalID(finalTaskId) ?? taskId;
        }
        TaskAPIAdapter.deleteTask(finalTaskId).then(() => {
            if (
                state.agenda.days[dateToDDMMYYString(state.currentDate)]
                    .assignedTasks.length === 1
            ) {
                PremiumReducerMethods.createTask(
                    state,
                    pushToRemoteStack,
                    findWaitingValueAndSubstitute,
                    0,
                    state.currentDate
                );
            }
        });
    };

    static updateTextForTask = async (
        state: AppData,
        getRemoteIDFromLocalID: (localId: string) => string | undefined,
        pushToRemoteStack: (id: string) => void,
        findWaitingValueAndSubstitute: (
            waitingValue: string,
            newId: string
        ) => void,
        text?: string,
        index?: number,
        currentFilteredText?: string,
    ) => {
        if (text === undefined || index === undefined) {
            return state;
        }
        if (text[text.length - 1] === "\n") {
            PremiumReducerMethods.createTask(
                state,
                pushToRemoteStack,
                findWaitingValueAndSubstitute,
                index + 1,
                state.currentDate,
                currentFilteredText ?? "",
            );
            return;
        }
        const localTaskId =
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks[index].id;
        // @ts-ignore if we dont try this we won't get a good result of out isNaN
        if (!isNaN(localTaskId)) {
            // this is a remote task that we already know the ID of
            TaskAPIAdapter.updateTask(localTaskId, text);
        } else {
            const remoteTaskId = getRemoteIDFromLocalID(localTaskId);
            if (remoteTaskId === undefined || localTaskId === undefined) {
                return state;
            }
            TaskAPIAdapter.updateTask(remoteTaskId, text);
        }
    };

    static pasteTextInTask = async (
        state: AppData,
        getRemoteIDFromLocalID: (localId: string) => string | undefined,
        pushToRemoteStack: (id: string) => void,
        findWaitingValueAndSubstitute: (
            waitingValue: string,
            newId: string
        ) => void,
        updatedState: AppData,
        tasksToDo: string[],
        tasksDone: string[],
        index?: number
    ) => {
        let newTaskIndex = 1;

        for (const taskText of tasksToDo) {
            PremiumReducerMethods.createTask(
                state,
                pushToRemoteStack,
                findWaitingValueAndSubstitute,
                index,
                state.currentDate,
                taskText
            );
            newTaskIndex = newTaskIndex + 1;
        }
        for (const taskText of tasksDone) {
            PremiumReducerMethods.createTask(
                state,
                pushToRemoteStack,
                findWaitingValueAndSubstitute,
                index,
                state.currentDate,
                taskText,
                (taskInDay) =>
                    TaskInDayAPIAdapter.updateTaskInDay(
                        taskInDay.id,
                        state.currentDate,
                        InputState.DONE - 1
                    )
            );
            newTaskIndex = newTaskIndex + 1;
        }

        // finally, update the text of the original task, if needed
        if (index === undefined) {
            return updatedState;
        }
        const taskId =
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks[index].id;
        if (taskId === undefined) {
            return updatedState;
        }
        const updatedText = updatedState.agenda.tasks[taskId].text;
        if (updatedText === undefined) {
            return updatedState;
        }
        PremiumReducerMethods.updateTextForTask(
            state,
            getRemoteIDFromLocalID,
            pushToRemoteStack,
            findWaitingValueAndSubstitute,
            updatedText,
            index
        );
        return updatedState;
    };

    static passTaskToNextDay = async (
        state: AppData,
        getRemoteIDFromLocalID: (localId: string) => string | undefined,
        index?: number
    ) => {
        if (index === undefined) {
            return state;
        }
        const date = new Date(state.currentDate);
        if (date.getDay() === 5 && shouldSkipWeekends()) {
            date.setDate(date.getDate() + 3);
        } else if (date.getDay() === 6 && shouldSkipWeekends()) {
            date.setDate(date.getDate() + 2);
        } else {
            date.setDate(date.getDate() + 1);
        }
        const localTaskId =
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks[index].id;
        let finalTaskId = localTaskId;

        // @ts-ignore if we dont try this we won't get a good result of out isNaN
        if (!isNaN(localTaskId)) {
            TaskInDayAPIAdapter.updateTaskInDay(
                localTaskId,
                state.currentDate,
                InputState.NEXT_DAY - 1
            );
        } else {
            const remoteTaskId = getRemoteIDFromLocalID(localTaskId);
            if (remoteTaskId === undefined) {
                return state;
            }
            TaskInDayAPIAdapter.updateTaskInDay(
                remoteTaskId,
                state.currentDate,
                InputState.NEXT_DAY - 1
            );
            finalTaskId = remoteTaskId;
        }

        if (!state.agenda.days[dateToDDMMYYString(date)]) {
            DayAPIAdapter.createDay(date).then(() =>
                TaskInDayAPIAdapter.createTaskInDay(finalTaskId, date)
            );
        } else {
            TaskInDayAPIAdapter.createTaskInDay(finalTaskId, date);
        }
    };

    static deleteTaskInNextDay = async (
        state: AppData,
        pushToRemoteStack: (id: string) => void,
        getRemoteIDFromLocalID: (localId: string) => string | undefined,
        findWaitingValueAndSubstitute: (
            waitingValue: string,
            newId: string
        ) => void,
        index?: number
    ) => {
        if (index === undefined) {
            return state;
        }
        const localTaskId =
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks[index].id;
        const date = new Date(state.currentDate);
        date.setDate(date.getDate() + 1);
        // @ts-ignore if we dont try this we won't get a good result of out isNaN
        if (!isNaN(localTaskId)) {
            TaskInDayAPIAdapter.deleteTaskInDay(localTaskId, date);
        } else {
            const remoteTaskId = getRemoteIDFromLocalID(localTaskId);
            if (remoteTaskId === undefined) {
                return state;
            }
            TaskInDayAPIAdapter.deleteTaskInDay(remoteTaskId, date);
        }

        if (
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks.length === 1
        ) {
            PremiumReducerMethods.createTask(
                state,
                pushToRemoteStack,
                findWaitingValueAndSubstitute,
                0,
                date
            );
        }
    };

    static updateStateForTask = async (
        state: AppData,
        getRemoteIDFromLocalID: (localId: string) => string | undefined,
        inputState?: InputState,
        index?: number
    ) => {
        if (inputState === undefined || index === undefined) {
            return state;
        }
        const localTaskId =
            state.agenda.days[dateToDDMMYYString(state.currentDate)]
                .assignedTasks[index].id;
        // @ts-ignore if we dont try this we won't get a good result of out isNaN
        if (!isNaN(localTaskId)) {
            TaskInDayAPIAdapter.updateTaskInDay(
                localTaskId,
                state.currentDate,
                inputState
            );
        } else {
            const remoteTaskId = getRemoteIDFromLocalID(localTaskId);
            if (remoteTaskId === undefined) {
                return state;
            }
            TaskInDayAPIAdapter.updateTaskInDay(
                remoteTaskId,
                state.currentDate,
                inputState
            );
        }
    };

    static goForwardOneDay = async (
        state: AppData,
        pushToRemoteStack: (id: string) => void
    ) => {
        const date = new Date(state.currentDate);
        date.setDate(date.getDate() + 1);

        const day = await DayAPIAdapter.getDay(date);
        if (day === undefined) {
            const newDay = await DayAPIAdapter.createDay(date);
            state.agenda.days[dateToDDMMYYString(date)] = newDay;
            const newTaskId = newDay.assignedTasks[0].id;
            state.agenda.tasks[newTaskId] = {
                id: newTaskId,
                text: "",
            };
            pushToRemoteStack(newTaskId);
        } else {
            state.agenda.days[dateToDDMMYYString(date)] = day;
        }
    };

    static goBackOneDay = async (
        state: AppData,
        pushToRemoteStack: (id: string) => void
    ) => {
        const date = new Date(state.currentDate);
        date.setDate(date.getDate() - 1);
        const day = await DayAPIAdapter.getDay(date);
        if (day === undefined) {
            const newDay = await DayAPIAdapter.createDay(date);
            state.agenda.days[dateToDDMMYYString(date)] = newDay;
            const newTaskId = newDay.assignedTasks[0].id;
            state.agenda.tasks[newTaskId] = {
                id: newTaskId,
                text: "",
            };
            pushToRemoteStack(newTaskId);
        } else {
            state.agenda.days[dateToDDMMYYString(date)] = day;
        }
    };

    static goToDate = async (
        state: AppData,
        date: Date,
        pushToRemoteStack: (id: string) => void
    ) => {
        const day = await DayAPIAdapter.getDay(date);
        if (day === undefined) {
            const newDay = await DayAPIAdapter.createDay(date);
            state.agenda.days[dateToDDMMYYString(date)] = newDay;
            const newTaskId = newDay.assignedTasks[0].id;
            state.agenda.tasks[newTaskId] = {
                id: newTaskId,
                text: "",
            };
            pushToRemoteStack(newTaskId);
        } else {
            state.agenda.days[dateToDDMMYYString(date)] = day;
        }
    };

    static goToToday = async (
        state: AppData,
        pushToRemoteStack: (id: string) => void
    ) => {
        const date = new Date();
        if (!state.agenda.days[dateToDDMMYYString(date)]) {
            const newDay = await DayAPIAdapter.createDay(date);
            state.agenda.days[dateToDDMMYYString(date)] = newDay;
            const newTaskId = newDay.assignedTasks[0].id;
            state.agenda.tasks[newTaskId] = {
                id: newTaskId,
                text: "",
            };
            pushToRemoteStack(newTaskId);
        } else {
            const tasks = await TaskInDayAPIAdapter.getTasksInDay(date);
            state.agenda.days[dateToDDMMYYString(date)].assignedTasks = tasks;
        }
    };

    static sort = async (state: AppData) => {
        const day = state.agenda.days[dateToDDMMYYString(state.currentDate)];
        DayAPIAdapter.updateDay(
            state.currentDate,
            day.pendingTasksPassed,
            true
        );
    };
}

const premiumReducer = (
    state: AppData,
    action: {
        index?: number;
        pasteIndex?: number;
        id?: string;
        bulkIds?: string[];
        text?: string;
        inputState?: InputState;
        date?: Date;
        isPremium?: boolean;
        type: Actions;
        idSync: {
            pushToRemoteStack: (id: string) => void;
            findWaitingValueAndSubstitute: (
                waitingValue: string,
                newId: string
            ) => void;
            pushToLocalStack: (id: string) => void;
            getRemoteIDFromLocalID: (localId: string) => string | undefined;
        };
        overrideState?: AppData;
        currentFilteredText?: string;
    }
): AppData => {
    let newState: AppData | undefined = undefined;

    // these are needed for some actions
    const pushToRemoteStack = action.idSync.pushToRemoteStack;
    const findWaitingValueAndSubstitute =
        action.idSync.findWaitingValueAndSubstitute;
    const pushToLocalStack = action.idSync.pushToLocalStack;
    const getRemoteIDFromLocalID = action.idSync.getRemoteIDFromLocalID;

    switch (action.type) {
        case Actions.DeleteTask:
            PremiumReducerMethods.deleteTask(
                state,
                pushToRemoteStack,
                findWaitingValueAndSubstitute,
                getRemoteIDFromLocalID,
                action.id
            );
            newState = ReducerMethods.deleteTask(
                state,
                action.id,
                pushToLocalStack
            );
            break;
        case Actions.UpdateTextForTask:
            let currentFilteredText = action.currentFilteredText;
            if (currentFilteredText) {
                currentFilteredText = currentFilteredText + " -> ";
            }
            PremiumReducerMethods.updateTextForTask(
                state,
                getRemoteIDFromLocalID,
                pushToRemoteStack,
                findWaitingValueAndSubstitute,
                action.text,
                action.index,
                currentFilteredText,
            );
            newState = ReducerMethods.updateTextForTask(
                state,
                action.text,
                action.index,
                pushToLocalStack,
                currentFilteredText,
            );
            break;
        case Actions.PasteTextInTask:
            const { updatedState, tasksToDo, tasksDone } =
                ReducerMethods.pasteTextInTask(
                    state,
                    action.text,
                    action.index,
                    action.pasteIndex
                );
            newState = updatedState;
            PremiumReducerMethods.pasteTextInTask(
                state,
                getRemoteIDFromLocalID,
                pushToRemoteStack,
                findWaitingValueAndSubstitute,
                updatedState,
                tasksToDo,
                tasksDone,
                action.index
            );
            break;
        case Actions.PassTaskToNextDay:
            PremiumReducerMethods.passTaskToNextDay(
                state,
                getRemoteIDFromLocalID,
                action.index
            );
            newState = ReducerMethods.passTaskToNextDay(state, action.index);
            break;
        case Actions.DeleteTaskInNextDay:
            PremiumReducerMethods.deleteTaskInNextDay(
                state,
                pushToRemoteStack,
                getRemoteIDFromLocalID,
                findWaitingValueAndSubstitute,
                action.index
            );
            newState = ReducerMethods.deleteTaskInNextDay(state, action.index);
            break;
        case Actions.UpdateStateForTask:
            PremiumReducerMethods.updateStateForTask(
                state,
                getRemoteIDFromLocalID,
                action.inputState ? action.inputState - 1 : InputState.EMPTY,
                action.index
            );
            newState = ReducerMethods.updateStateForTask(
                state,
                action.inputState,
                action.index
            );
            break;
        case Actions.GoForwardOneDay:
            PremiumReducerMethods.goForwardOneDay(state, pushToRemoteStack);
            newState = ReducerMethods.goForwardOneDay(state);
            newState = createTaskForPreviouslyUnexistingDayInLocal(
                state,
                newState,
                pushToRemoteStack
            );

            break;
        case Actions.GoBackOneDay:
            PremiumReducerMethods.goBackOneDay(state, pushToRemoteStack);
            newState = ReducerMethods.goBackOneDay(state);
            newState = createTaskForPreviouslyUnexistingDayInLocal(
                state,
                newState,
                pushToRemoteStack
            );
            break;
        case Actions.LoadFirstDay:
            // force a re-render, since we didn't
            // modify the state via the reducer
            // because we needed to load data ASYNCHRONOUSLY
            // from the server with loadFirstDayFromServer
            if (action.overrideState) {
                newState = action.overrideState;
            }

            break;
        case Actions.GoToToday:
            PremiumReducerMethods.goToToday(state, pushToRemoteStack);
            newState = ReducerMethods.goToToday(state);
            newState = createTaskForPreviouslyUnexistingDayInLocal(
                state,
                newState,
                pushToRemoteStack
            );
            break;
        case Actions.GoToDate:
            const date = action.date;
            if (date) {
                PremiumReducerMethods.goToDate(state, date, pushToRemoteStack);
                newState = ReducerMethods.goToDate(state, date);
                newState = createTaskForPreviouslyUnexistingDayInLocal(
                    state,
                    newState,
                    pushToRemoteStack
                );
            }
            break;
        case Actions.Sort:
            PremiumReducerMethods.sort(state);
            newState = ReducerMethods.sort(state);
            break;
        case Actions.BulkAction:
            // TODO: implement if it´s too slow the way it is now
            newState = state;
            break;
        default:
            throw Error("no valid action specified!");
    }
    if (newState === undefined) {
        newState = state;
    }

    return newState;
};

export default premiumReducer;
