import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const { token } = useContext(AuthContext);
    if (!token || token === "undefined") {
        return <Navigate to="/login" />;
    }
    return <>{children}</>;
};
