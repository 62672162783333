import styled from "styled-components";

const ColoredText = styled.p<{
    color: string;
}>`
    font-family: "Inter", Helvetica;
    color: ${(props) => props.color};
`;

export default ColoredText;
