import React, { PropsWithChildren } from "react";
import Clickable from "./Clickable";
import FormattedText from "./FormattedText";

export const ButtonLink = ({
    children,
    onClick,
}: PropsWithChildren<{}> & {
    onClick: () => void;
}) => {
    return (
        <Clickable onClick={onClick}>
            <FormattedText underline>{children}</FormattedText>
        </Clickable>
    );
};
