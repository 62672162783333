import styled from "styled-components";
import { PropsWithChildren, useContext, useRef } from "react";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { Shadow } from "./Shadow";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { FIRST_LAYER_COLOR } from "../utils";

const ModalContainer = styled.div<{ width: string; isSmall: boolean }>`
    background-color: ${FIRST_LAYER_COLOR};
    margin: 15% auto; /* 15% from the top and centered */
    width: ${(props) => props.width};
    border-radius: ${(props) => (props.isSmall ? "8px" : "16px")};
    display: flex;
    padding: ${(props) => (props.isSmall ? "20px" : "40px")};
    justify-content: center;
    align-items: center;
`;

export const Modal = (
    props: { onClickOutside: () => void } & PropsWithChildren<any>
) => {
    const modalRef = useRef(null);
    useOnClickOutside(modalRef, props.onClickOutside);
    const { isSmall, isMedium } = useContext(ScreenSizeContext);
    let modalContainerWidth = "60%";
    if (isSmall) {
        modalContainerWidth = "90%";
    } else if (isMedium) {
        modalContainerWidth = "80%";
    }
    return (
        <Shadow>
            <ModalContainer
                ref={modalRef}
                width={modalContainerWidth}
                isSmall={isSmall}
            >
                {props.children}
            </ModalContainer>
        </Shadow>
    );
};
