export enum InputState {
    EMPTY = 1,
    DONE = 2,
    NEXT_DAY = 3,
    WONT_DO = 4,
}

const calculateNextInputState = (inputState: InputState) => {
    switch (inputState) {
        case InputState.EMPTY:
            return InputState.DONE;
        case InputState.DONE:
            return InputState.NEXT_DAY;
        case InputState.NEXT_DAY:
            return InputState.WONT_DO;
        case InputState.WONT_DO:
            return InputState.EMPTY;
        default:
            return InputState.EMPTY;
    }
};

const KEYS = {
    UP_ARROW: 38,
    DOWN_ARROW: 40,
    BACKSPACE: 8,
    DELETE: 46,
};

export { calculateNextInputState, KEYS };
