import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
    cursor: pointer;
    height: inherit;
    width: inherit;

    &:hover {
        filter: brightness(98%);
    }

    &:active {
        filter: brightness(96%);
    }
`;

const Clickable = ({
    onClick,
    children,
}: {
    onClick: (value: any) => void;
    children: any;
}) => <StyledDiv onClick={onClick}>{children}</StyledDiv>;

export default Clickable;
