import styled from "styled-components";

export const Shadow = styled.div<{
    customZIndex?: string;
}>`
    display: block;
    position: fixed; /* Stay in place */
    z-index: ${(props) => props.customZIndex ?? "999"};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;
