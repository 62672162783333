import { InputState } from "../models/constants";
import { AppData, Day, FullTask, Task } from "../models/types";

export const CLOUDFRONT_DOMAIN = "https://d2zrts48n4dp7h.cloudfront.net"

export const checkDatesAreEqualInDDMMYY = (
    date1: Date,
    date2: Date
): boolean => {
    const d1 = dateToDDMMYYString(date1);
    const d2 = dateToDDMMYYString(date2);
    return d1 === d2;
};

export const dateToDDMMYYString = (date: Date, correction?: number): string => {
    
    //if (correction) {
    //    date.setDate(date.getDate() + correction);
    //}
    if (!date.getUTCMonth) {
        date = new Date(date);
    }
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate() + (correction ? correction : 0);
    const year = date.getUTCFullYear();
    return day + "/" + month + "/" + year;
};

export const dateToYYYYMMDDString = (date: Date): string => {
    if (!date.getUTCMonth) {
        date = new Date(date);
    }
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return year + "-" + month + "-" + day;
}

export const differenceBetweenDatesInDays = (date1: Date, date2: Date): number => {
    const diffTime = date2.getTime() - date1.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export const getFullTasksForCurrentDay = (state: AppData): FullTask[] => {
    const currentDate = dateToDDMMYYString(state.currentDate);
    const day: Day | undefined = state.agenda.days[currentDate];
    if (!day) {
        return [];
    }

    let fullTasks: FullTask[] = [];
    for (const task of day.assignedTasks) {
        let taskToAppend: Task | undefined = state.agenda.tasks[task.id];
        if (!taskToAppend) {
            continue;
        }
        const newFullTask: FullTask = { ...taskToAppend, state: task.state };
        fullTasks = fullTasks.concat([newFullTask]);
    }
    return fullTasks;
};

export const getInputStateFromPair = (pair:string ) : InputState => {
    const firstPosition = pair.indexOf("'", 0);
    if (firstPosition === -1) {
        return InputState.EMPTY;
    }
    const secondPosition = pair.indexOf("'", firstPosition + 1);
    if (secondPosition === -1) {
        return InputState.EMPTY;
    }
    const state = pair.substring(firstPosition + 1, secondPosition);
    switch (state) {
        case "EMPTY":
            return InputState.EMPTY; 
        case "DONE":
            return InputState.DONE;
        case "NEXT_DAY":
            return InputState.NEXT_DAY;
        case "WONT_DO":
            return InputState.WONT_DO;
    }
    return InputState.EMPTY;
} 