import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import Flex from "../components/Flex";
import Header from "../components/Header";
import { InputText } from "../components/InputText";
import Spacer from "../components/Spacer";
import { Navigate, useNavigate } from "react-router-dom";
import Clickable from "../components/Clickable";
import ArrowLeft from "../images/arrowLeft.svg";
import { FormattedMessage, useIntl } from "react-intl";
import CompleteArrowRight from "../images/completeArrowRight.svg";
import { ButtonLink } from "../components/ButtonLink";
import { StyledRoundButton } from "../components/StyledRoundButton";
import FormattedText from "../components/FormattedText";
import { useString } from "../hooks/useString";
import { ErrorText } from "../components/ErrorText";
import { AuthorizationAPI, UserAPI } from "../api/api";
import { AuthContext } from "../contexts/AuthContext";
import { Divider } from "../components/Divider";
import { clearAppData, isUserLoggedIn } from "../models/persistanceAPI";
import CustomInput from "../components/CustomInput";
import { InputState } from "../models/constants";
import { StyledLogoImage } from "../components/StyledLogoImage";
import { CLOUDFRONT_DOMAIN } from "../utils";

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const SignUp = () => {
    const navigate = useNavigate();
    const { isSmall, width } = useContext(ScreenSizeContext);
    const { setToken } = useContext(AuthContext);
    const intl = useIntl();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [randomImage, setRandomImage] = useState<number>(
        Math.floor(Math.random() * 4)
    );

    const splash1 = `${CLOUDFRONT_DOMAIN}/wiseOwl1.jpeg`;
    const splash2 = `${CLOUDFRONT_DOMAIN}/wiseOwl2.jpeg`;
    const splash3 = `${CLOUDFRONT_DOMAIN}/wiseOwl3.jpeg`;
    const splash4 = `${CLOUDFRONT_DOMAIN}/wiseOwl4.jpeg`;
    const randomSplashImage = [splash1, splash2, splash3, splash4][randomImage];

    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [shouldNavigateToApp, setShouldNavigateToApp] = useState<
        boolean | string
    >(false);

    const [doesAcceptTerms, setDoesAcceptTerms] = useState<boolean>(false);

    const passwordsMustMatchError = useString("passwordsMustMatch");
    const passwordTooShortError = useString("passwordTooShort");
    const placeholderEmail = useString("placeholderEmail");
    const placeholderPassword = useString("placeholderPassword");
    const placeholderRepeatPassword = useString("placeholderRepeatPassword");
    const errorEmailAlreadyInUse = useString("emailAlreadyInUse");

    const doPasswordsNotMatch =
        password !== password2 && !!password && !!password2;
    const isPasswordTooShort = !!password && password.length < 8;

    useEffect(() => {
        if (isUserLoggedIn()) {
            setShouldNavigateToApp("premium");
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (shouldNavigateToApp === "premium") {
        return <Navigate to="/premium" />;
    }

    return (
        <Flex grow={1} direction="row">
            <Flex grow={1} direction="column" style={{ height: "inherit" }}>
                <Spacer
                    left={isSmall ? 2 : 4}
                    right={isSmall ? 2 : 10}
                    top={isSmall ? 2 : 4}
                    bottom={isSmall ? 2 : 4}
                >
                    <Flex direction="column">
                        <Clickable onClick={() => navigate(-1)}>
                            <Flex>
                                <img
                                    src={ArrowLeft}
                                    alt="Click here to go back to the previous page"
                                />
                                <Spacer right={1} />
                                <FormattedMessage
                                    id="back2"
                                    defaultMessage="Go back"
                                />
                            </Flex>
                        </Clickable>
                        <Spacer top={4} />
                        <Header fontWeight="300" fontSize="48px">
                            <FormattedMessage
                                id="welcome"
                                defaultMessage="Welcome to Wise Owl"
                            />
                            ,
                        </Header>
                        <Header fontWeight="300" fontSize="48px">
                            <FormattedMessage
                                id="registerForFree"
                                defaultMessage="register for free"
                            />
                        </Header>
                    </Flex>
                    <Spacer top={4} />
                    <FormattedText weight="300" size="18px">
                        <FormattedMessage
                            id="trialTime"
                            defaultMessage="We hope you will enjoy your 7 days of free trial time. After that, you can choose to continue using Wise Owl for just 1,49€/month."
                        />
                    </FormattedText>
                    <Spacer top={2} />
                    <Flex grow={1} direction="column" justifyContent="center">
                        <InputText
                            placeholder={placeholderEmail}
                            id="email"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            width={isSmall ? "300px" : undefined}
                        />
                        <Spacer top={2} />
                        <InputText
                            placeholder={placeholderPassword}
                            id="password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            width={isSmall ? "300px" : undefined}
                        />
                        {isPasswordTooShort && (
                            <Spacer top={1}>
                                <ErrorText>{passwordTooShortError}</ErrorText>
                            </Spacer>
                        )}
                        <Spacer top={2} />
                        <InputText
                            placeholder={placeholderRepeatPassword}
                            id="password"
                            type="password"
                            onChange={(e) => setPassword2(e.target.value)}
                            width={isSmall ? "300px" : undefined}
                        />
                        {doPasswordsNotMatch && (
                            <Spacer top={1}>
                                <ErrorText>{passwordsMustMatchError}</ErrorText>
                            </Spacer>
                        )}

                        <Spacer top={3} />

                        <Clickable
                            onClick={() => setDoesAcceptTerms(!doesAcceptTerms)}
                        >
                            <Flex alignItems="center">
                                <CustomInput
                                    state={
                                        doesAcceptTerms
                                            ? InputState.DONE
                                            : InputState.EMPTY
                                    }
                                    changeState={() =>
                                        setDoesAcceptTerms(!doesAcceptTerms)
                                    }
                                />
                                <Spacer right={1} />
                                <FormattedMessage
                                    id="agreeToTerms"
                                    defaultMessage="By ticking this checkbox you agree to our Terms and Conditions"
                                />
                            </Flex>
                        </Clickable>

                        <Spacer top={2} />
                        <ButtonLink onClick={() => navigate("/privacy-policy")}>
                            <FormattedMessage
                                id="checkPrivacyPolicy"
                                defaultMessage="You can check the privacy policy here"
                            />
                        </ButtonLink>
                        <Spacer top={2} />
                        <ButtonLink
                            onClick={() => navigate("/terms-and-conditions")}
                        >
                            <FormattedMessage
                                id="checkTermsAndConditions"
                                defaultMessage="You can check the terms & conditions here"
                            />
                        </ButtonLink>

                        <Spacer top={4} />
                        <StyledRoundButton
                            disabled={
                                !email ||
                                !password ||
                                !password2 ||
                                doPasswordsNotMatch ||
                                isPasswordTooShort ||
                                !doesAcceptTerms
                            }
                            isLoading={isLoading}
                            onClick={async () => {
                                setIsLoading(true);
                                setError(undefined);
                                await UserAPI.signUp(
                                    email,
                                    password,
                                    intl.locale.substring(0, 2)
                                )
                                    .then(({ status }) => {
                                        if (status === 400) {
                                            setError(errorEmailAlreadyInUse);
                                            return;
                                        }
                                        AuthorizationAPI.logIn(email, password)
                                            .catch((e) => {
                                                setError(e.detail);
                                            })
                                            .then((response) => {
                                                UserAPI.importAgenda().then(
                                                    () => {
                                                        clearAppData();
                                                        const access =
                                                            response?.access;
                                                        if (access) {
                                                            setToken(access);
                                                            navigate(
                                                                "/premium"
                                                            );
                                                        }
                                                    }
                                                );
                                            });
                                    })
                                    .catch((e) => {
                                        setError(e.detail);
                                    });
                                setIsLoading(false);
                            }}
                        >
                            <Spacer top={2} bottom={2} left={2} right={2}>
                                <Flex>
                                    <FormattedText size="24px" weight="300">
                                        <FormattedMessage
                                            id="signUp"
                                            defaultMessage="Sign up"
                                        />
                                    </FormattedText>
                                    <Spacer left={2} />
                                    {!isLoading && (
                                        <StyledLogoImage
                                            src={CompleteArrowRight}
                                            alt="Click here to sign up"
                                        />
                                    )}
                                </Flex>
                            </Spacer>
                        </StyledRoundButton>
                        {error && (
                            <Spacer top={2}>
                                <ErrorText>{error}</ErrorText>
                            </Spacer>
                        )}
                    </Flex>
                    <Spacer top={4} />
                    <Divider />
                    <Spacer top={4} />
                    <Flex>
                        <FormattedText>
                            <FormattedMessage
                                id="alreadyHaveAccount"
                                defaultMessage="Or, if you already have an account, {logInAgain}"
                                values={{
                                    logInAgain: (
                                        <ButtonLink
                                            onClick={() => navigate("/login")}
                                        >
                                            <FormattedText underline>
                                                <FormattedMessage
                                                    id="logInAgain"
                                                    defaultMessage="log in again"
                                                />
                                            </FormattedText>
                                        </ButtonLink>
                                    ),
                                }}
                            />
                        </FormattedText>
                    </Flex>
                </Spacer>
            </Flex>

            {!isSmall && (
                <Flex
                    grow={1}
                    direction="column"
                    style={{ height: width > 1225 ? "100vh" : "inherit" }}
                >
                    <StyledImage src={randomSplashImage} />
                </Flex>
            )}
        </Flex>
    );
};
