import React from "react";
import { User } from "../models/types";

type UserContextType = {
    user: User | null;
    setUser: (user: User | null) => void;
};

const UserContext = React.createContext<UserContextType>({
    user: null,
    setUser: () => {},
});

export default UserContext;
