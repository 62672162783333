export const TOP_LAYER_COLOR = "white";
export const FIRST_LAYER_COLOR = "#FCF3E5";
export const MIDDLE_FIRST_SECOND_LAYER_COLOR = "#E7DFD2";
export const SECOND_LAYER_COLOR = "#F8E1CD";
export const CONTRAST_COLOR = "#56a1fa";
export const THIRD_LAYER_COLOR = "#ebcfb7";
export const SELECTION_COLOR = "#E8BE99";
export const DARK_TEXT_COLOR = "#6C3B0F";
export const DARK_BACKGROUND_COLOR = "#EAD7C7"; 
export const LIGHT_TEXT_COLOR = "#b6b6b6";
export const TEXT_COLOR = "#000000";
export const PLACEHOLDER_TEXT_COLOR = "#7E7E7E";
export const ERROR_TEXT_COLOR = "#df2929";

export const INPUT_DONE = "#218559";
export const INPUT_NEXT_DAY = "#06a2cb";
export const INPUT_WONT_DO = "#dd1e2f";

/*

https://colors.dopely.top/color-pedia/f8e1cd#TintAndShades

original colors:

export const TOP_LAYER = "#fefdfc";
export const FIRST_LAYER = "#fef3ea";
export const SECOND_LAYER = "f8e1cd";
export const DARK_TEXT = "#705040";
export const LIGHT_TEXT = "";
export const TEXT = "";

export const INPUT_DONE = "#218559";
export const INPUT_NEXT_DAY = "#06a2cb";
export const INPUT_WONT_DO = "#dd1e2f";
*/
