// eslint-disable-file react-hooks/exhaustive-deps
import { useReducer, useEffect, useState } from "react";
import { InputState, KEYS } from "./constants";
import { AppData } from "./types";
import useActiveElement from "./useActiveElement";
import reducer, { Actions, newEmptyTask } from "./reducer";
import { dateToDDMMYYString, getFullTasksForCurrentDay } from "../utils/utils";
import { loadAppData } from "./persistanceAPI";
import premiumReducer, { loadFirstDayFromServer } from "./premiumReducer";
import { useIdSync } from "./useIdSync";

export const createEmptyAppData = (): AppData => {
    const date = new Date();
    date.setHours(1, 0, 0, 0);
    const newTask = newEmptyTask([]);
    const emptyAppData: AppData = {
        currentDate: date,
        agenda: {
            tasks: {},
            days: {},
        },
    };
    emptyAppData.agenda.days[dateToDDMMYYString(date)] = {
        date,
        assignedTasks: [{ id: newTask.id, state: InputState.EMPTY }],
        pendingTasksPassed: true,
    };
    emptyAppData.agenda.tasks[newTask.id] = newTask;
    return emptyAppData;
};

const loadData = (isPremium: boolean): AppData => {
    if (isPremium) {
        return createEmptyAppData();
    }
    const appData: undefined | AppData = loadAppData();
    if (appData) {
        const date = new Date();
        appData["currentDate"] = date;
        date.setHours(1, 0, 0, 0);
        if (!(dateToDDMMYYString(date) in appData.agenda.days)) {
            const newTask = newEmptyTask([]);
            appData.agenda.tasks[newTask.id] = newTask;
            appData.agenda.days[dateToDDMMYYString(date)] = {
                date,
                assignedTasks: [{ id: newTask.id, state: InputState.EMPTY }],
                pendingTasksPassed: false,
            };
        }
        return appData;
    }
    return createEmptyAppData();
};

const useDay = (isPremium: boolean) => {
    let reducerToUse = reducer;
    if (isPremium) {
        reducerToUse = premiumReducer;
    }
    const [state, dispatch] = useReducer(reducerToUse, loadData(isPremium));
    const [isLoadingForPremium, setIsLoadingForPremium] = useState(false);
    const activeID = useActiveElement();
    const idSync = useIdSync();

    const handleOnKeyDown = (e: KeyboardEvent) => {
        var key = e.keyCode || e.charCode;
        if (
            activeID &&
            (key === KEYS.DELETE || key === KEYS.BACKSPACE) &&
            state.agenda.tasks[activeID] &&
            state.agenda.tasks[activeID].text === ""
        ) {
            deleteTask(activeID);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleOnKeyDown);
        return () => {
            document.removeEventListener("keydown", handleOnKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeID]);

    useEffect(() => {
        const fetchData = async () => {
            return await loadFirstDayFromServer(state);
        };

        const fetchDataAndDispatch = async () => {
            if (isPremium) {
                setIsLoadingForPremium(true);
                const fetchedState = await fetchData();
                dispatch({
                    type: Actions.LoadFirstDay,
                    overrideState: fetchedState,
                    idSync,
                });
                setIsLoadingForPremium(false);
            } else {
                dispatch({ type: Actions.LoadFirstDay, idSync });
            }
        };

        fetchDataAndDispatch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPremium]);

    const deleteTask = (id: string) =>
        dispatch({ id, type: Actions.DeleteTask, isPremium, idSync });

    const updateTextForTask = (text: string, index: number, currentFilteredText?: string) =>
        dispatch({
            index,
            text,
            type: Actions.UpdateTextForTask,
            isPremium,
            idSync,
            currentFilteredText,
        });

    const pasteTextInTask = (text: string, index: number, atIndex?: number) =>
        dispatch({
            index,
            text,
            pasteIndex: atIndex,
            type: Actions.PasteTextInTask,
            isPremium,
            idSync,
        });

    const updateStateForTask = (state: InputState, index: number) => {
        if (state === InputState.NEXT_DAY) {
            dispatch({
                index,
                inputState: state,
                type: Actions.PassTaskToNextDay,
                isPremium,
                idSync,
            });
        }
        if (state === InputState.WONT_DO) {
            dispatch({
                index,
                inputState: state,
                type: Actions.DeleteTaskInNextDay,
                isPremium,
                idSync,
            });
        }
        dispatch({
            index,
            inputState: state,
            type: Actions.UpdateStateForTask,
            isPremium,
            idSync,
        });
    };

    const goForwardOneDay = () =>
        dispatch({ type: Actions.GoForwardOneDay, isPremium, idSync });

    const goBackOneDay = () =>
        dispatch({ type: Actions.GoBackOneDay, isPremium, idSync });

    const goToToday = () =>
        dispatch({ type: Actions.GoToToday, isPremium, idSync });

    const goToDate = (date: Date) => {
        dispatch({ type: Actions.GoToDate, date, isPremium, idSync });
    };

    const sort = () => dispatch({ type: Actions.Sort, isPremium, idSync });

    const bulkAction = (targetedState: InputState, finalState: InputState, searchText: string | null) => {
        const tasks = getFullTasksForCurrentDay(state);
        
        const filteredTasksIds = tasks.filter((task) => {
            const text = state.agenda.tasks && state.agenda.tasks[task.id] ? state.agenda.tasks[task.id].text : ""
            if (searchText && !text.toLowerCase().includes(searchText.toLowerCase())) {
                return false;
            }
            return true;
        }).map((task) => task.id);

        const bulkIds = state.agenda.days[
            dateToDDMMYYString(state.currentDate)
        ].assignedTasks
            .map((task, index) => {
                return { index, state: task.state, id: task.id };
            })
            .filter((task) => task.state === targetedState && filteredTasksIds.includes(task.id));
            
        for (const task of bulkIds) {
            updateStateForTask(finalState, task.index);
        }
    };

    return {
        state,
        updateTextForTask,
        updateStateForTask,
        pasteTextInTask,
        goForwardOneDay,
        goBackOneDay,
        goToToday,
        sort,
        bulkAction,
        isLoadingForPremium,
        goToDate,
    };
};

export default useDay;
