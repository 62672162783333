import React, { useRef, useState, useEffect, useContext } from "react";
import { InputState } from "../models/constants";
import styled from "styled-components";
import ScreenSizeContext from "../contexts/ScreenSizeContext";
import { FIRST_LAYER_COLOR, LIGHT_TEXT_COLOR } from "../utils";
import { useString } from "../hooks/useString";

const CustomTextArea = styled.textarea<{
    inputState?: InputState;
    height?: number;
    id: any;
}>`
    width: 100%;
    height: ${(props) => (props.height ? props.height + "px" : "24px")};
    border: none;
    outline: none;
    resize: none;
    font-size: 18px;
    line-height: 20px;
    text-decoration: ${(props) =>
        props.inputState === InputState.WONT_DO ? "line-through" : "none"};
    background: ${FIRST_LAYER_COLOR};
    line-height: 1.5;
    overflow: hidden;

    ::placeholder {
        color: ${LIGHT_TEXT_COLOR};
    }
`;

const TextInput = ({
    text,
    state,
    onChange,
    onPaste,
    id,
    hidePlaceholder,
    shouldAutofocus,
}: {
    text: string;
    state?: InputState;
    onChange: (newText: string) => void;
    onPaste: (pastedText: string, atIndex?: number) => void;
    id: number | string;
    hidePlaceholder?: boolean;
    shouldAutofocus?: boolean;
}) => {
    const textAreaRef = useRef<null | HTMLTextAreaElement>(null);
    const { width, isSmall } = useContext(ScreenSizeContext);
    const [height, setHeight] = useState(24);
    const [randomNumber, setRandomNumber] = useState(
        Math.floor(Math.random() * 8)
    );

    useEffect(() => {
        setRandomNumber(Math.floor(Math.random() * 8));
    }, [id]);

    const placeholder = useString(`placeholder_${randomNumber}`);

    const recalculateHeight = () => {
        // TODO: aquí hi ha el bug visual següent
        // 1. Amb varies tasques, fer la pantalla petita. Els text inputs s'adaptaran.
        // 2. Torna a la mida original
        // 3. Observa com encara tenen la scrollHeight d'abans!!
        if (
            textAreaRef.current &&
            Math.abs(textAreaRef.current.scrollHeight - height) > 10
        ) {
            setHeight(textAreaRef.current.scrollHeight);
        }
    };

    useEffect(() => {
        if (textAreaRef !== null && textAreaRef.current) {
            setHeight(textAreaRef.current.scrollHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textAreaRef.current]);

    useEffect(() => {
        recalculateHeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    return (
        <CustomTextArea
            value={text}
            onPaste={(event) => {
                event.preventDefault();
                const pastedText = event.clipboardData.getData("Text");
                // @ts-expect-error yeah selectionStart does indeed exist :)
                onPaste(pastedText, event.target.selectionStart);
                recalculateHeight();
            }}
            onChange={(event) => {
                const newText = event.target.value;
                onChange(newText);
                if (newText[newText.length - 1] === "\n") {
                    return;
                }
                recalculateHeight();
            }}
            inputState={state}
            autoFocus={shouldAutofocus ?? true}
            id={id}
            ref={textAreaRef}
            height={height}
            placeholder={isSmall || hidePlaceholder ? undefined : placeholder}
        />
    );
};

export default TextInput;
