import React, { useContext } from "react";
import Flex from "../components/Flex";
import { FormattedMessage } from "react-intl";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import { clearAllData, setCookiesAccepted } from "../models/persistanceAPI";
import { useNavigate } from "react-router-dom";
import BottomModal from "../components/BottomModal";
import { StyledRoundButton } from "../components/StyledRoundButton";
import { ButtonLink } from "../components/ButtonLink";
import { useString } from "../hooks/useString";
import FormattedText from "../components/FormattedText";
import { CONTRAST_COLOR } from "../utils";
import ScreenSizeContext from "../contexts/ScreenSizeContext";

const CookiesModal = ({
    hideCookiesModal,
}: {
    hideCookiesModal: () => void;
}) => {
    const navigate = useNavigate();
    const cookiePolicy = useString("cookiePolicy");
    const { isSmall } = useContext(ScreenSizeContext);
    return (
        <BottomModal>
            <Header>
                <FormattedMessage
                    id="cookies_header"
                    defaultMessage="Please accept the use of cookies"
                />
            </Header>
            <Spacer bottom={1.5} />
            <FormattedMessage
                id="cookies_use"
                defaultMessage="We use cookies to store your tasks and preferences on your web browser. Without them, Wise Owl cannot function properly."
            />
            <ButtonLink onClick={() => navigate("/cookie-policy")}>
                {cookiePolicy}
            </ButtonLink>
            <Spacer bottom={1.5} />
            <Flex
                direction="row"
                justifyContent={isSmall ? undefined : "end"}
                width={isSmall ? "100%" : undefined}
            >
                <StyledRoundButton
                    onClick={() => {
                        clearAllData();
                        navigate("/");
                    }}
                    borderColor="black"
                >
                    <Spacer top={2} bottom={2} left={2} right={2}>
                        <FormattedText weight="300">
                            <FormattedMessage
                                id="refuse_cookies"
                                defaultMessage="Exit & clean all my data"
                            />
                        </FormattedText>
                    </Spacer>
                </StyledRoundButton>
                <Spacer right={3} />
                <StyledRoundButton
                    onClick={() => {
                        setCookiesAccepted();
                        hideCookiesModal();
                    }}
                    borderColor={CONTRAST_COLOR}
                >
                    <Spacer top={2} bottom={2} left={2} right={2}>
                        <FormattedText weight="bold" color={CONTRAST_COLOR}>
                            <FormattedMessage
                                id="accept_cookies"
                                defaultMessage="Accept cookies"
                            />
                        </FormattedText>
                    </Spacer>
                </StyledRoundButton>
            </Flex>
        </BottomModal>
    );
};

export default CookiesModal;
