import styled from "styled-components";
import { PLACEHOLDER_TEXT_COLOR } from "../utils";

export const Spinner = ({ size }: { size?: string }) => (
    <StyledSpinner viewBox="0 0 50 50" size={size}>
        <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="4"
        />
    </StyledSpinner>
);

const StyledSpinner = styled.svg<{ size?: string }>`
    animation: rotate 2s linear infinite;
    margin: 10px 0px 0px 8px;
    width: ${(props) => props.size ?? "30px"};
    height: ${(props) => props.size ?? "30px"};

    & .path {
        stroke: ${PLACEHOLDER_TEXT_COLOR};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`;
