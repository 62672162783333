import styled from "styled-components";
import { FIRST_LAYER_COLOR, TOP_LAYER_COLOR, INPUT_DONE } from "../utils";

export const StyledButton = styled.button<{
    secondary?: boolean;
    bold?: boolean;
    small?: boolean;
    width?: string;
    disabled?: boolean;
    fontWeight?: string;
}>`
    height: 40px;
    font-family: "Inter", Helvetica;
    width: ${(props) => props.width ?? "fit-content"};
    font-weight: ${(props) =>
        props.bold ? "bold" : props.fontWeight ?? "300"};
    font-size: 16px;
    padding: 8px;
    border: ${(props) =>
        props.bold
            ? `2px solid ${INPUT_DONE}`
            : props.disabled
            ? "2px solid grey"
            : "2px solid black"};
    height: auto;
    min-height: ${(props) => (props.small ? "20px" : "50px")};
    background: ${(props) =>
        props.secondary ? FIRST_LAYER_COLOR : TOP_LAYER_COLOR};
    color: ${(props) =>
        props.bold ? INPUT_DONE : props.disabled ? "grey" : "black"};

    &:hover {
        filter: ${(props) => (props.disabled ? undefined : "brightness(98%)")};
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    }

    &:focus {
        filter: brightness(92%);
    }
`;
